
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {observer} from "mobx-react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {ROUTES} from "../../../services/constants";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {useNavigate} from "react-router-dom";

function AccountSettingsPage() {
  const navigate = useNavigate();

  const ACTIONS = [
    {id: 1, title: 'Change Password', route: ROUTES.USER_CHANGE_PASSWORD},
    {id: 2, title: 'Terms & Conditions', route: ROUTES.USER_TERMS_AND_CONDITIONS},
    {id: 3, title: 'Privacy Policy', route: ROUTES.USER_PRIVACY_POLICY}
  ]

  const renderItems = (item) => {
    return (
      <MDBox sx={{display:"flex", width:"600px", justifyContent:"flex-start"}}>
      <MDBox mt={5} key={item.id + ''} bgColor={'light'} p={4} display={'flex'} alignItems={'center'} borderRadius={'10px'} sx={{cursor: 'pointer', width:"800px"}} onClick={() => navigate(item.route)}>
        <MDTypography variant={'h3'}>{item.title}</MDTypography>
        <MDBox ml={'auto'}>
          <ArrowForwardIosIcon sx={{transform: 'scale(1.3)'}} />
        </MDBox>
      </MDBox>
      </MDBox>
      )
  }

  return (
    <DashboardProjectManagerLayout
      title={'Settings'}
      backButton
      backRoute={ROUTES.USER_PROJECT_SELECTOR}
      // marginHorizontal={50}
      alignContent={"center"}
    >
      {ACTIONS.map(action => renderItems(action))}
    </DashboardProjectManagerLayout>
  );
}

export default observer(AccountSettingsPage);
