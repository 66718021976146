import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {observer} from "mobx-react";
import DataTable from "../../../components/DataTable";
import {useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import {dataTableModel, renderTableRow} from "./utils";
import SearchBar from "../../../components/SearchBar";

const NotificationsScreen = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({ ...dataTableModel });
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const getNotifications = (searchData = "", page = 1, ordering = paginationData.ordering,page_size = 25) => {
    setLoading(true);
    api.getNotifications({ search: searchData, page, ordering, page_size }).then((result) => {
        if (result.kind === "ok") {
          const { count, results } = result.data;
          const tmp = { ...dataTableModel };
          tmp.rows = results.map((e) => renderTableRow(e, markAsRead));
          setDatatable(tmp);
          setPaginationData((value) => ({...value, counts: count, itemsPerPage: results.length,ordering: ordering,}));
        }
      })
      .catch((err) => showMessage())
      .finally(() => setLoading(false));
  };
  const markAsRead = (id) => {
    setLoading(true);
    api
      .markAsRead(id)
      .then((result) => {
        if (result.kind === "ok") {
          setPaginationData((prevData) => {
            const updatedPage = prevData.currentPage;
            getNotifications(searchQuery, updatedPage);
            return prevData;
          });
          showMessage("Notification marked as read successfully", "success");
        } else {
          showMessage("Error marking notification as read");
        }
      })
      .catch((err) => showMessage())
      .finally(() => setLoading(false));
  };
  const handleOrderingChange = (ordering) => {
    setPaginationData((prevData) => ({
      ...prevData,
      ordering: ordering,
    }));
    getNotifications(searchQuery, 1, ordering); // Reiniciamos la paginación a la primera página
  };

  return (
    <DashboardProjectManagerLayout
      title={"Notifications"}
      backRoute={"Home"}
      header={
        <SearchBar
          loading={loading}
          search={getNotifications}
          setSearchQuery={setSearchQuery}
          handleOrderingChange={handleOrderingChange} // Pasamos la función handleOrderingChange al SearchBar
        />
      }
    >
      <DataTable
        loading={loading}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getNotifications}
        searchQuery={searchQuery}
        onPageChange={(page) => {
          getNotifications(searchQuery, page);
          setPaginationData((value) => ({
            ...value,
            currentPage: page,
          }));
        }}
      />
    </DashboardProjectManagerLayout>
  );
};
export default observer(NotificationsScreen);
