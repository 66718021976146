import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import {Fab, Icon} from "@mui/material";
import {InitialPackageCategoryProduct} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import FormikInput from "../../../../../../components/FormikInput";
import CloseIcon from "@mui/icons-material/Close";
import {AddButton, RemoveButton} from "../styles";
import {getIn} from "formik";


export const PackageCategoryProductForm = (
  {
    packageCategoryProductsProps,
    values,
    setFieldValue,
    products,
    debugBorders
  }) => {
  const parentPath = packageCategoryProductsProps.name
  const valuesArray = getIn(values, parentPath)

  return (

    <MDBox
      sx={{px: 3}}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'start'}
    >
      {valuesArray.length < 1 &&
        <MDTypography variant={"caption"} component={"caption"} color={"error"} mt={2}>
          At least one product need to be added
        </MDTypography>
      }
      <MDBox sx={{display: 'flex', flexDirection: "column", px: 3, width: "680px", ...debugBorders}}>
        {valuesArray.map((pkg_category_product, index) => {
            const mapPath = `${parentPath}.${index}`
            return (

              <MDBox
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                alignItems='center'
              >
                <MDBox sx={{width: "250px", mt: 2}}>
                  <FormikInput
                    type={"autocomplete"}
                    value={pkg_category_product.product}
                    fieldName={`${mapPath}.product`}
                    label={"Product"}
                    options={products}
                    accessKey={"name"}
                    onInputChange={(value) => {
                      // getCompanies({search:value})
                    }}
                    onChange={(value, fieldName) => {
                      setFieldValue(fieldName, value)
                    }}/>
                </MDBox>
                <FormikInput
                  type={"number"}
                  sx={{width: "100px", ml: 2, mt: 2}}
                  name={`${mapPath}.quantity`}
                  fieldName={`${mapPath}.quantity`}
                  label="Quantity"
                  overrideError={'Invalid Qty'}
                />
                <MDBox sx={{display: "flex", flexDirection: "row", ml: 2}}>
                  <FormikInput
                    label={'Include Installation'}
                    checked={pkg_category_product.include_installation}
                    type={"checkbox"}
                    sx={{mx: 1}}
                    name={`${mapPath}.include_installation`}
                    fieldName={`${mapPath}.include_installation`}

                    // variant={'contained'}
                  />
                </MDBox>

                <MDBox sx={{display: 'flex', alignItems: "center", flexDirection: "row", justifyContent: "start", ml: 2}}>
                  <Icon sx={RemoveButton} fontSize='medium' onClick={() => packageCategoryProductsProps.remove(index)}>
                    remove_circle
                  </Icon>
                </MDBox>


              </MDBox>
            )
          }
        )}

      </MDBox>

      <MDBox sx={{display: 'flex', alignItems: "center", flexDirection: "row", justifyContent: "space-between", mx: 3}}>
        <MDTypography variant={"h4"} component={"h4"}>Add Product</MDTypography>
        <Icon sx={AddButton} fontSize='medium'
              onClick={() => packageCategoryProductsProps.push({...InitialPackageCategoryProduct})}>
          add_circle
        </Icon>
      </MDBox>

    </MDBox>
  )
}
