/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";

// MUI Components
import {Grid, Icon, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';


// Components
import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Utils
import {convertStringToList, money_fmt, openInNewTab, showMessage, useApi} from "services/helpers";

// Styles
import {
  ButtonsContainer,
  EditButton,
  DeleteButton,
  BrandText,
  PrimaryImageContainer,
  OtherImagesContainer,
  OtherImageContainer,
  OtherImage,
  GeneralInfoContainer,
  GeneralInfoItem,
  GeneralInfoText,
  Divider,
  AccordionBox,
  ListItem,
  AccordionDivider,
} from "./styles";

// Images and Theme functions
import ExpandMore from 'assets/images/expand_more.svg';
import AdaLogo from 'assets/images/ada-logo.png';
import pxToRem from "assets/theme/functions/pxToRem";
import {ROUTES} from "../../../../services/constants";
import ConfirmDialogModal from "../../../../components/ConfirmDialogModal";
import {FilecardContainer, FilenameText, RemoveButton} from "../manage_product/styles";
import {wrapApiCall} from "../../../../services/helpers_ts";
import {useApiMessages} from "../../../../services/api/api-messages";

function ProductDetailPage() {
  const api = useApi()
  const navigate = useNavigate();
  const [Product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const messages = useApiMessages('product', 'products')
  const [openDuplicateConfirmModal, setOpenDuplicateConfirmModal] = useState(false);

  const getProduct = () => {
    wrapApiCall(api.getProduct(id), {
      onSuccess: (result) => {
          setProduct(result.data)
          setSelectedImage(result.data?.images[0]?.image)
      },
      errorMessage: (result) =>  result.kind === 'not-found' ? 'Product requested not found' : 'Error fetching product',
      onError: () => navigate(ROUTES.ADMIN_PRODUCTS),
      onFinally: () => setLoading(false),
    })
  }

  const onDelete = () => {
    setLoading(true)
    wrapApiCall(api.deleteProduct(Product?.id), {
      successMessage: messages.delete.success,
      onSuccess: (result) => { setOpenDeleteModal(false) ; navigate(ROUTES.ADMIN_PRODUCTS) },
      errorMessage: messages.delete.error,
      onFinally: () => setLoading(false),
    })
  }

  const duplicate = () => {
    setLoading(true)
    api.duplicateProduct(Product?.id).then((result) => {
      if (result.kind === 'ok') {
        const { data } = result
        showMessage('Product duplicated successfully', 'success')
        setOpenDuplicateConfirmModal(false)
        navigate(ROUTES.ADMIN_UPDATE_PRODUCT(data.id))
      } else if (result.kind === 'bad-data') {
        showMessage(result.errors[0])
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getProduct();
  }, [])

  return (
    <AdminLayout
      title={'Manage Products'}
      hasNavigationBack={true}
      header={<MDBox sx={ButtonsContainer}>
        <MDButton
          variant="outlined"
          color="primary"
          type='button'
          sx={EditButton}
          disabled={loading}
          onClick={() => setOpenDuplicateConfirmModal(true)}
        >
          Copy
          <Icon fontSize='large'>copy_outlined</Icon>
        </MDButton>
        <MDButton
          variant="outlined"
          color="secondary"
          type='button'
          sx={EditButton}
          disabled={loading}
          onClick={() => navigate(ROUTES.ADMIN_UPDATE_PRODUCT(id))}
        >
          Edit
          <Icon fontSize='large'>edit_outlined</Icon>
        </MDButton>
        <MDButton
          variant="outlined"
          color="tertiary"
          type='button'
          disabled={loading}
          sx={DeleteButton}
          onClick={() => setOpenDeleteModal(true)}
        >
          Delete
        </MDButton>
      </MDBox>}
    >
      <Grid container spacing={pxToRem(28)}>
        {/*Product Main Info and Images Section*/}
        <Grid item xs={12} md={6} lg={5.5}>
          <MDTypography variant='h3' sx={BrandText}>{Product?.manufacturer?.name}</MDTypography>
          <MDTypography variant='h2' mt={pxToRem(6)} width={'70%'}>{Product?.name}</MDTypography>
          <MDBox sx={PrimaryImageContainer}>
            <MDBox component='img' src={selectedImage} sx={{objectFit: 'contain', maxWidth: '90%', maxHeight: '90%'}}/>
          </MDBox>
          <Grid container sx={OtherImagesContainer} justifyContent={{xs: 'space-around', sm: 'flex-start'}}>
            {Product?.images.map((img, i) => (
              <Grid item sx={OtherImageContainer} key={`img-${i}}`} onClick={() => setSelectedImage(img.image)}>
                <MDBox component='img' src={img.image} sx={OtherImage} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/*Product Main Info and Images Section*/}

        {/*Product Information Section*/}
        <Grid item xs={12} md={6} lg={6.5}>
          {/*Product Information Summary*/}
          <MDBox display='flex' justifyContent={{xs: 'center', md: 'flex-start'}}>
            <MDBox>
              <MDTypography variant='h2' mt={pxToRem(5)}>
                {money_fmt(Product?.third_party_default_pricing?.part_price)}
                <MDTypography variant='button'> /unit</MDTypography>
              </MDTypography>
              <MDTypography variant='button'>Model {Product?.model}</MDTypography>
              <MDBox>
                <MDTypography variant='button'>{Product?.summary}</MDTypography>
              </MDBox>
            </MDBox>
            {Product?.ada_compliant && <MDBox
              ml={'auto'}
              component='img'
              src={AdaLogo}
              height={pxToRem(39)}
            />}
          </MDBox>
          {/*Product Information Summary*/}

          {/*Product General Information Paper*/}
          <MDBox sx={GeneralInfoContainer}>
            <MDTypography variant='h2'>Product Pricing</MDTypography>
            <MDBox mt={pxToRem(10)} display='flex' flexDirection='column' gap={pxToRem(15)}>
              <MDBox sx={GeneralInfoItem}>
                <MDTypography variant='button' sx={GeneralInfoText}>Product Price</MDTypography>
                <MDTypography variant='button' fontWeight='bold'>
                  {money_fmt(Product?.default_pricing?.part_price)}
                </MDTypography>
              </MDBox>
              <MDBox sx={GeneralInfoItem}>
                <MDTypography variant='button' sx={GeneralInfoText}>{Product?.default_pricing?.includes_shipping ? "Shipping is included" : "Shipping Price"}</MDTypography>
                {!Product?.default_pricing?.includes_shipping && <MDTypography variant='button' fontWeight='bold'>
                  {money_fmt(Product?.default_pricing?.shipping_price)}
                </MDTypography>}
              </MDBox>
              {Product?.default_pricing?.includes_installation?
                <>
                  {!Product?.default_pricing?.installation_is_optional &&
                    <MDBox sx={GeneralInfoItem}>
                      <MDTypography variant='button' sx={GeneralInfoText}>
                        Includes installation
                      </MDTypography>
                      <MDTypography variant='button' fontWeight='bold'>
                        {money_fmt(Product?.default_pricing?.installation_price)}
                      </MDTypography>
                    </MDBox>
                  }
                  {Product?.default_pricing?.includes_installation && Product?.default_pricing?.installation_is_optional &&
                    <MDBox display='flex' alignItems='center' gap={pxToRem(5)}>
                      <BuildOutlinedIcon
                        fontSize={'medium'}
                        sx={({palette: {text}}) => ({color: text.bold})}
                      />
                      <MDTypography variant='button' sx={GeneralInfoText}>
                        Includes installation at {' '}
                        {money_fmt(Product?.default_pricing?.installation_price)} /unit
                        ({Product?.default_pricing?.installation_is_optional ? 'optional' : 'not optional'})
                      </MDTypography>
                    </MDBox>
                  }
                </>:
                <MDBox sx={GeneralInfoItem}>
                  <MDTypography variant='button' sx={GeneralInfoText}>
                    Does not include installation
                  </MDTypography>
                </MDBox>
              }
              <MDTypography variant='h2'>Product Pricing</MDTypography>
              <MDBox sx={GeneralInfoItem}>
                <MDTypography variant='button' sx={GeneralInfoText}>Third party pricing</MDTypography>
                <MDTypography variant='button' fontWeight='bold'>
                  {money_fmt(Product?.third_party_default_pricing?.part_price)}
                </MDTypography>
              </MDBox>
              <MDBox sx={GeneralInfoItem}>
                <MDTypography variant='button' sx={GeneralInfoText}>{Product?.third_party_default_pricing?.includes_shipping ? "Shipping is included" : "Shipping Price"}</MDTypography>
                {!Product?.third_party_default_pricing?.includes_shipping && <MDTypography variant='button' fontWeight='bold'>
                  {money_fmt(Product?.third_party_default_pricing?.shipping_price)}
                </MDTypography>}
              </MDBox>
              {Product?.third_party_default_pricing?.includes_installation?
                <>
                  {!Product?.third_party_default_pricing?.installation_is_optional &&
                    <MDBox sx={GeneralInfoItem}>
                      <MDTypography variant='button' sx={GeneralInfoText}>
                        Includes installation
                      </MDTypography>
                      <MDTypography variant='button' fontWeight='bold'>
                        {money_fmt(Product?.third_party_default_pricing?.installation_price)}
                      </MDTypography>
                    </MDBox>
                  }
                  {Product?.third_party_default_pricing?.includes_installation && Product?.third_party_default_pricing?.installation_is_optional &&
                    <MDBox display='flex' alignItems='center' gap={pxToRem(5)}>
                      <BuildOutlinedIcon
                        fontSize={'medium'}
                        sx={({palette: {text}}) => ({color: text.bold})}
                      />
                      <MDTypography variant='button' sx={GeneralInfoText}>
                        Includes installation at {' '}
                        {money_fmt(Product?.third_party_default_pricing?.installation_price)} /unit
                        ({Product?.third_party_default_pricing?.installation_is_optional ? 'optional' : 'not optional'})
                      </MDTypography>
                    </MDBox>
                  }
                </>:
                <MDBox sx={GeneralInfoItem}>
                  <MDTypography variant='button' sx={GeneralInfoText}>
                    Does not include installation
                  </MDTypography>
                </MDBox>
              }
              <MDBox sx={Divider}/>
            </MDBox>
            <MDTypography variant='h2' mt={pxToRem(14)}>Company Region Pricing</MDTypography>
            {Product?.custom_pricings.map((product, index) => {
              return (
                <MDBox mt={pxToRem(10)} display='flex' flexDirection='column' gap={pxToRem(12)} key={index}>
                  <MDBox sx={GeneralInfoItem}>
                    <MDTypography variant='button' sx={GeneralInfoText}>Company</MDTypography>
                    <MDTypography variant='button' fontWeight='bold'>
                      {product?.company?.name}
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={GeneralInfoItem}>
                    <MDTypography variant='button' sx={GeneralInfoText}>Region</MDTypography>
                    <MDTypography variant='button' fontWeight='bold'>
                      {product?.region?.name}
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={GeneralInfoItem}>
                    <MDTypography variant='button' sx={GeneralInfoText}>Product Price</MDTypography>
                    <MDTypography variant='button' fontWeight='bold'>
                      {money_fmt(product?.part_price)}
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={GeneralInfoItem}>
                    <MDTypography variant='button' sx={GeneralInfoText}>{product.includes_shipping ? "Shipping is included" : "Shipping Price"}</MDTypography>
                    {!product.includes_shipping && <MDTypography variant='button' fontWeight='bold'>
                      {money_fmt(product.shipping_price)}
                    </MDTypography>}
                  </MDBox>
                  {product?.includes_installation?
                    <>
                      {!product.installation_is_optional &&
                        <MDBox sx={GeneralInfoItem}>
                          <MDTypography variant='button' sx={GeneralInfoText}>
                            Includes installation
                          </MDTypography>
                          <MDTypography variant='button' fontWeight='bold'>
                            {money_fmt(product.installation_price)}
                          </MDTypography>
                        </MDBox>
                      }
                      {product.includes_installation && product.installation_is_optional &&
                        <MDBox display='flex' alignItems='center' gap={pxToRem(5)}>
                          <BuildOutlinedIcon
                            fontSize={'medium'}
                            sx={({palette: {text}}) => ({color: text.bold})}
                          />
                          <MDTypography variant='button' sx={GeneralInfoText}>
                            Includes installation at {' '}
                            {money_fmt(product.installation_price)} /unit
                            ({product.installation_is_optional ? 'optional' : 'not optional'})
                          </MDTypography>
                        </MDBox>
                      }
                    </>:
                    <MDBox sx={GeneralInfoItem}>
                      <MDTypography variant='button' sx={GeneralInfoText}>
                        Does not include installation
                      </MDTypography>
                    </MDBox>
                  }
                </MDBox>
              )
            })}
          </MDBox>
          {/*Product General Information Paper*/}

          {/*Accordion Product Details*/}
          <MDBox mt={pxToRem(24)}>
            <Accordion sx={AccordionBox}>
              <AccordionSummary
                expandIcon={<img src={ExpandMore} alt='expand_more' style={{marginRight: pxToRem(10)}}/>}
              >
                <MDTypography variant='h2'>Product Details</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDTypography
                  variant='button'
                  sx={GeneralInfoText}
                  dangerouslySetInnerHTML={{ __html: convertStringToList(Product?.details) }}
                ></MDTypography>
                <MDBox mt={pxToRem(20)}>
                  <MDTypography variant='button' fontWeight='bold' sx={{textDecoration: 'underline'}}>
                    Features
                  </MDTypography>
                  <ul>
                    <MDTypography
                      variant='button'
                      fontWeight='regular'
                      dangerouslySetInnerHTML={{ __html: convertStringToList(Product?.features) }}
                    />
                  </ul>
                </MDBox>
                <MDBox mt={pxToRem(20)}>
                  <MDTypography variant='button' fontWeight='bold' sx={{textDecoration: 'underline'}}>
                    Summary
                  </MDTypography>
                  <ul>
                    {Product?.summary}
                  </ul>
                </MDBox>
              </AccordionDetails>
            </Accordion>
          </MDBox>
          {/*Accordion Product Details*/}

          <MDBox sx={AccordionDivider}/>
          {/*Accordion Cut Sheet*/}
          <MDBox mt={pxToRem(18)}>
            <Accordion sx={AccordionBox}>
              <AccordionSummary
                expandIcon={<img src={ExpandMore} alt='expand_more' style={{marginRight: pxToRem(10)}}/>}
              >
                <MDTypography variant='h2'>Cut Sheet</MDTypography>
              </AccordionSummary>
              <AccordionDetails>Cut Sheet</AccordionDetails>
              {Product?.cut_sheets?.map((cutSheet, index) => (
                  <MDBox sx={[FilecardContainer, {cursor: 'pointer'}]}
                         onClick={() => openInNewTab(cutSheet.cut_sheet_file)}>
                    <Icon
                      sx={(th) => ({ml: 2, mr: 2})}
                      fontSize='medium'>
                      attachment
                    </Icon>
                    <MDTypography
                      sx={FilenameText}>{cutSheet.cut_sheet_file?.name || cutSheet?.cut_sheet_file_name}</MDTypography>

                  </MDBox>
                )
              )}
            </Accordion>
          </MDBox>
          {/*Accordion Cut Sheet*/}

          <MDBox sx={AccordionDivider}/>
          {/*Accordion Specifications*/}
          <MDBox mt={pxToRem(18)}>
            <Accordion sx={AccordionBox}>
              <AccordionSummary
                expandIcon={<img src={ExpandMore} alt='expand_more' style={{marginRight: pxToRem(10)}}/>}
              >
                <MDTypography variant='h2'>Specifications</MDTypography>
              </AccordionSummary>

              <MDTypography variant='button' fontWeight='regular' dangerouslySetInnerHTML={{ __html: convertStringToList(Product?.specs) }}/>
            </Accordion>
          </MDBox>
          {/*Accordion Specifications*/}
        </Grid>
        {/*Product Information Section*/}
      </Grid>
      <ConfirmDialogModal
        title={'Do you want to delete this product?'}
        description={`${Product?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleConfirm={() => onDelete()}
      />
      <ConfirmDialogModal
        title={'Do you want to duplicate this product?'}
        description={`${Product?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openDuplicateConfirmModal}
        handleClose={() => setOpenDuplicateConfirmModal(false)}
        handleConfirm={() => duplicate()}
      />
    </AdminLayout>
  );
}

export default observer(ProductDetailPage);
