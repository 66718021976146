import pxToRem from "assets/theme/functions/pxToRem";

export const BrandText = ({palette: {icons}}) => ({
  color: icons.arrow_icon,
})

export const PrimaryImageContainer = ({palette: {white, table}}) => ({
  alignItems: 'center',
  background: white.main,
  border: `1px solid ${table.border}`,
  borderRadius: '8px',
  display: 'flex',
  flex: {xs: 0, md: 0.5, xl: 1},
  minHeight: pxToRem(416),
  maxHeight: pxToRem(479),
  justifyContent: 'center',
  order: {xs: 0, xl: 1},
  position: 'relative',
})

export const PrimaryImage = () => ({
  borderRadius: '8px',
  cursor: 'pointer',
  maxWidth: '95%',
  maxHeight: pxToRem(414),
  objectFit: 'contain',
})

export const OtherImageContainer = ({palette: {white, table}}) => ({
  background: white.main,
  border: `1px solid ${table.border}`,
  borderRadius: '8px',
  width: pxToRem(145),
  height: pxToRem(145),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
})

export const OtherImagesContainer = () => ({
  display: 'flex',
  gap: pxToRem(27),
  mt: {xs: pxToRem(30), lg: 0},
  width: '100%',
  flex: 0,
})

export const OtherImage = () => ({
  maxHeight: '100%',
  objectFit: 'contain',
  maxWidth: pxToRem(143)
})

export const GeneralInfoContainer = ({palette: {white}}) => ({
  background: white.main,
  borderRadius: '16px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  minHeight: pxToRem(364),
  mt: pxToRem(10),
  paddingBlock: `${pxToRem(24)} ${pxToRem(35)}`,
  px: pxToRem(24),
})

export const GeneralInfoItem = () => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  padding: `0 ${pxToRem(9)}`,
})

export const GeneralInfoItemHeader = ({palette: {table}}) => ({
  ...GeneralInfoItem(),
  background: table.product_detail_header,
  borderRadius: '8px',
  padding: pxToRem(9),
})
export const GeneralInfoText = () => ({
  fontWeight: 400,
})

export const InputQuantity = () => ({
  '.MuiInputBase-root': {
    height: pxToRem(34),
    width: pxToRem(100),
    marginBottom: 0,
  },
  '&.MuiFormControl-root': {
    marginBottom: '0 !important'
  }
})

export const Divider = ({palette: {table}}) => ({
  borderTop: `1px solid ${table.border}`,
  mt: pxToRem(5),
})

export const AccordionBox = () => ({
  background: 'transparent',
  boxShadow: 'none',
  '.MuiAccordionSummary-root': {padding: '0'},
  '.MuiAccordionDetails-root': {padding: '0'},
  '.Mui-expanded': {margin: '0'},
})

export const AccordionDivider = (theme) => ({
  ...Divider(theme),
  mt: pxToRem(21),
})
