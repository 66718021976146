import { Box } from "@mui/material";
import cart from "../../../../../assets/icons/cart-green.svg";
import MDTypography from "../../../../../components/MDTypography";
import flecha from "../../../../../assets/icons/flecha.svg";
import checked from "../../../../../assets/icons/checked-grey.svg";
import * as React from "react";
import MDBox from "../../../../../components/MDBox";
import { Done } from "@mui/icons-material";
import document from "../../../../../assets/icons/document-green.svg";
import truck from "../../../../../assets/icons/truck-green.svg";
import MDButton from "components/MDButton";
import pxToRem from "assets/theme/functions/pxToRem";
import { useTheme } from "@emotion/react";
import DeliveryModal from "./DeliveryModal";

export const ApproveOrderSection = ({ order, onOrderUpdated }) => {
  const { breakpoints } = useTheme();
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenDeliveryModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <DeliveryModal
        data={order}
        buyer={order.buyer}
        openModal={openModal}
        onCloseModal={handleOpenDeliveryModal}
        onOrderUpdated={onOrderUpdated}
      />

      <MDBox
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"xl"}
        p={2}
        border={"2px solid #81D61E"}
        sx={{ height: "58px" }}
      >
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box component={"img"} src={cart} alt={"cart"} sx={{ width: 24, height: 24 }} />
          <MDTypography variant={"h6"}>Collaboration</MDTypography>
        </MDBox>
        <Done sx={{ color: "#81D61E", width: 25, height: 25 }} />
      </MDBox>
      <MDBox display={"flex"} justifyContent={"center"}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }} />
      </MDBox>
      <MDBox
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"xl"}
        p={2}
        border={"2px solid #81D61E"}
        sx={{ height: "58px" }}
      >
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box component={"img"} src={document} alt={"document"} sx={{ width: 24, height: 24 }} />
          <MDTypography variant={"h6"}>Contract</MDTypography>
        </MDBox>
        <Done sx={{ color: "#81D61E", width: 25, height: 25 }} />
      </MDBox>
      <MDBox display={"flex"} justifyContent={"center"}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }} />
      </MDBox>
      <MDBox
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"xl"}
        p={2}
        border={"2px solid #81D61E"}
        sx={{ height: "58px", paddingRight: 0.7 }}
      >
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box component={"img"} src={truck} alt={"truck"} />
          <MDTypography variant={"h6"}>Fulfillment</MDTypography>
        </MDBox>
        <MDBox display={"flex"} alignItems={"center"} gap={1} sx={{ flexShrink: 0 }}>
          <MDButton
            color="primary"
            type="button"
            styles={{
              width: pxToRem(164),
              cursor: "pointer",
            }}
            sx={{
              [breakpoints.down("xxl")]: {
                fontSize: "12px",
              },
            }}
            onClick={handleOpenDeliveryModal}
          >
            Shipment Tracking
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox display={"flex"} justifyContent={"center"}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }} />
      </MDBox>
      <MDBox
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"xl"}
        p={2}
        border={"2px solid #DBDBDB"}
        sx={{ height: "58px" }}
      >
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box component={"img"} src={checked} alt={"checked"} sx={{ width: 24, height: 24 }} />
          <MDTypography variant={"h6"}>Complete</MDTypography>
        </MDBox>
      </MDBox>
      {/*<MDBox display={'flex'} justifyContent={'space-between'} py={2} my={2} borderTop={'1px solid #DBDBDB'} borderBottom={'1px solid #DBDBDB'}>*/}
      {/*  <MDBox display={'flex'} justifyContent={'center'} gap={1}>*/}
      {/*    <MDTypography variant={'h5'}>*/}
      {/*      Activity*/}
      {/*    </MDTypography>*/}
      {/*    <Box component={"img"} src={dot} alt={"dot"} sx={{ width: 10, height: 10, }}/>*/}
      {/*  </MDBox>*/}

      {/*  <KeyboardArrowDown sx={{ width: 30, height: 30, cursor: 'pointer'}}/>*/}
      {/*</MDBox>*/}
    </>
  );
};
