import { useState} from "react";
import {Box, Drawer} from '@mui/material';
import pxToRem from "../../assets/theme/functions/pxToRem";
import MDBox from "../MDBox";
import { ArrowForwardIos } from "@mui/icons-material";
import {FixedDrawer} from "../../pages/project-manager/orders-and-quotes/fixed-drawer";

export default function DrawerRight({ order, onOrderUpdated, projectId }) {
  const [openDrawer, setOpenDrawer] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDrawer({ ...openDrawer, [anchor]: open });
  };
  {}
  const list = (anchor) => (
    <Box
      role="presentation"
    >
      <FixedDrawer order={order} onOrderUpdated={onOrderUpdated} projectID={projectId}/>
    </Box>
  );

  return (
    <MDBox >
      <ArrowForwardIos
        width={pxToRem(24)}
        height={pxToRem(24)}
        cursor={"pointer"}
        onClick={toggleDrawer('right', true)}
      />
      <Drawer
        anchor={'right'}
        open={openDrawer['right']}
        onClose={toggleDrawer('right', false)}
        sx={{
          '.MuiDrawer-paper': {
            borderRadius: "unset",
            marginTop: pxToRem(50),
            marginRight: 0,
            width: pxToRem(475),
          },
          '.MuiBackdrop-root': {
            backgroundColor: "unset"
          }
        }}
      >
        {list('right')}
      </Drawer>
    </MDBox>
  );
}
