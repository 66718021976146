import {ROUTES} from "../../../services/constants";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {useEffect, useState} from "react";
import {useApi} from "../../../services/helpers";

export const PrivacyPolicy = () => {
  const api = useApi()
  const [body, setBody] = useState('');

  const getPrivacyPolicy = () => {
    api.getPrivacyPolicy().handle( {
        onSuccess: (result) => {
          const {body} = result.data
          setBody(body)
        },
        errorMessage: "Couldn't load privacy policy"
      }
    )
  }

    useEffect(() => {
      getPrivacyPolicy();
    }, [])

    return (
      <DashboardProjectManagerLayout
        title={'Privacy Policy'}
        backButton
        backButtonText={'Home / Settings'}
        backRoute={ROUTES.USER_ACCOUNT_SETTINGS}
        marginHorizontal={30}
      >
        <MDBox>
          <MDTypography variant={"text"} >
            {body}
          </MDTypography>
        </MDBox>
      </DashboardProjectManagerLayout>
    )
  }


