import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {useStores} from "../../../models";
import {ROUTES} from "../../../services/constants";


const Logout = () => {
  const rootStore = useStores()
  const {loginStore, projectStore} = rootStore

  useEffect(() => {
    loginStore.reset()
    projectStore.reset()
  } , [])

  return <Navigate to={ROUTES.LOGIN}/>
};

export default Logout;
