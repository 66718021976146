import AddBaseModal from "components/add-base-modal/AddBaseModal";
import MDBox from "components/MDBox";
import { FieldArray, FormikProvider, useFormik } from "formik";
import FormikInput from "components/FormikInput";
import pxToRem from "assets/theme/functions/pxToRem";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "services/helpers";
import InputDragAndDrop from "components/InputDragAndDrop";
import { Icon } from "@mui/material";
import { FileFieldsContainer } from "pages/admin/manage-products/manage_product/styles";
import { ImagecardContainer } from "pages/admin/manage-products/manage_product/styles";
import MDTypography from "components/MDTypography";
import { ImagecardDeleteIcon } from "pages/admin/manage-products/manage_product/styles";
import { openInNewTab } from "services/helpers";
import { ImagecardWrapContainer } from "pages/admin/manage-products/manage_product/styles";
import { ImageCardImage } from "pages/admin/manage-products/manage_product/styles";

const PodForm = ({ selectedShipment, openModal, handleClose, handleChangedData }) => {
  const params = useParams();
  const { id: projectId, ido: orderId } = params;
  const { id: shipmentId, tracking: trackingId } = selectedShipment;
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);

  const validationSchema = Yup.object().shape(
    {
      images: Yup.mixed().when("docs", {
        is: (docs) => !docs,
        then: Yup.mixed().required("At least one image or document is required"),
      }),
      docs: Yup.mixed().when("images", {
        is: (images) => !images,
        then: Yup.mixed().required("At least one image or document is required"),
      }),
      pod_note: Yup.string().required("Required"),
    },
    ["images", "docs"]
  );

  const onSubmit = (values) => {
    console.log({ values });
    setLoading(true);
    api.addPod(projectId, orderId, trackingId, shipmentId, values).handle({
      onSuccess: () => {
        handleChangedData();
        handleClose();
      },
      errorMessage: "Error saving POD",
      onFinally: () => setLoading(false),
    });
  };

  const handleDropImages = (e, imagesProps) => {
    e.preventDefault();
    const newFiles = [...e.dataTransfer.files].filter((item) => item.type.includes("image"));
    setImageFiles([...imageFiles, ...newFiles]);
    newFiles.forEach((file) => {
      imagesProps.push({
        image: file,
        url: URL.createObjectURL(file),
      });
    });
  };

  const handleDropDocuments = (e, docsProp) => {
    e.preventDefault();
    const newFiles = [...e.dataTransfer.files].filter((item) => !item.type.includes("image"));
    setDocumentFiles([...documentFiles, ...newFiles]);
    newFiles.forEach((file) => {
      docsProp.push({
        document_file: file,
      });
    });
  };

  const handleDropImagesAndDocs = (e, imagesProps, docsProps) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    files?.forEach((file) => {
      if (file.type.startsWith("image/")) {
        imagesProps.push({
          image: file,
          url: URL.createObjectURL(file),
        });
      } else if (
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        docsProps.push({
          document_file: file,
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      images: null,
      docs: null,
      pod_note: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <AddBaseModal
      title={"New POD"}
      buttonText="Save"
      open={openModal}
      loading={loading}
      handleClose={handleClose}
      onSubmit={formik.handleSubmit}
    >
      <MDBox
        display="flex"
        flexDirection="column"
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
        sx={{ overflowY: "auto", overflowX: "hidden", maxHeight: "80vh" }}
      >
        <FormikProvider value={formik}>
          <FieldArray name={"images"}>
            {(imagesProps) => {
              return (
                <MDBox sx={FileFieldsContainer}>
                  <FieldArray name={"docs"}>
                    {(docsProps) => {
                      return (
                        <MDBox
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => handleDropImagesAndDocs(e, imagesProps, docsProps)}
                        >
                          <input
                            type="file"
                            multiple
                            id="docs"
                            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            style={{ display: "none" }}
                            onInput={(event) => {
                              if (event.target.files?.length) {
                                [...event.target.files].forEach((file) => {
                                  if (file.type.startsWith("image/")) {
                                    imagesProps.push({
                                      image: file,
                                      url: URL.createObjectURL(file),
                                    });
                                  } else if (
                                    file.type === "application/pdf" ||
                                    file.type === "application/msword" ||
                                    file.type ===
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  ) {
                                    docsProps.push({
                                      document_file: file,
                                    });
                                  }
                                });
                              }
                            }}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                          />
                          <InputDragAndDrop
                            htmlFor="docs"
                            label="Images or Documents"
                            errors={formik.errors?.documents}
                            name={"docs"}
                          />

                          {/* Images list */}
                          <MDBox sx={[ImagecardWrapContainer, { justifyContent: "space-between" }]}>
                            {formik.values.images?.map((image, index) => {
                              return (
                                <MDBox sx={ImagecardContainer} key={`key-${index}`}>
                                  <img
                                    style={ImageCardImage}
                                    alt={image.image?.name}
                                    src={image?.url || image.image}
                                    onClick={() => {
                                      openInNewTab(
                                        image.image instanceof File
                                          ? URL.createObjectURL(image.image)
                                          : image.image
                                      );
                                    }}
                                  />

                                  <Icon
                                    sx={ImagecardDeleteIcon}
                                    fontSize="medium"
                                    onClick={() => imagesProps.remove(index)}
                                  >
                                    remove_circle
                                  </Icon>
                                </MDBox>
                              );
                            })}
                          </MDBox>

                          {/* Docs list */}
                          {formik.values.docs?.map((file, index) => {
                            return (
                              <MDBox key={`document-${index}`}>
                                <MDBox sx={ImagecardContainer}>
                                  <Icon
                                    sx={(th) => ({ ml: pxToRem(11), mr: 2, mt: 2 })}
                                    fontSize="medium"
                                    onClick={() => {
                                      openInNewTab(
                                        file?.document_file instanceof File
                                          ? URL.createObjectURL(file?.document_file)
                                          : file?.document_file
                                      );
                                    }}
                                  >
                                    attachment
                                  </Icon>
                                  <MDTypography
                                    variant={"subtitle2"}
                                    onClick={() => {
                                      openInNewTab(
                                        file?.document_file instanceof File
                                          ? URL.createObjectURL(file?.document_file)
                                          : file?.document_file
                                      );
                                    }}
                                    p={2}
                                    sx={{
                                      width: "100%",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {file?.document_file.name || file?.document_file}
                                  </MDTypography>
                                  <Icon
                                    sx={ImagecardDeleteIcon}
                                    fontSize="medium"
                                    onClick={() => docsProps.remove(index)}
                                  >
                                    remove_circle
                                  </Icon>
                                </MDBox>
                              </MDBox>
                            );
                          })}
                        </MDBox>
                      );
                    }}
                  </FieldArray>
                </MDBox>
              );
            }}
          </FieldArray>

          <FormikInput type="textarea" rows={4} name="pod_note" label="Note" />
        </FormikProvider>
      </MDBox>
    </AddBaseModal>
  );
};
export default PodForm;
