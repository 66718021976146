import {Form, Formik} from "formik";
import {Autocomplete, Box, Card, Checkbox, Grid, TextField} from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import FormikInput from "../../../../components/FormikInput";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import * as React from "react";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {money_fmt, useApi} from "../../../../services/helpers";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";
import ConfirmDialogModal from "../../../../components/ConfirmDialogModal";
import {
    BooleanBox,
    BooleanFieldsContainer,
    BooleanTitle,
    CheckboxItem, CheckboxValue
} from "../../manage-products/manage_product/styles";
import {useStores} from "../../../../models";
import {FilterItem} from "../../../../components/FiltersBar/styles";
export const MiscellaneousCard = observer((
  {
      order,
      onOrderUpdated,
  }
) => {
    const api = useApi();
    const { id: orderId} = useParams()
    const [loading, setLoading] = useState(false);
    const [miscTaxes, setMiscTaxes] = useState([])

    const addMiscCost = (values, formik) => {
        setLoading(true)
        api.saveMiscellaneousCost(order.project.id, orderId, {...values, tax_category: values?.tax_category}).handle({
          successMessage: 'Miscellaneous cost added to order',
          onSuccess: (result) => { formik.resetForm() ; onOrderUpdated?.() },
          errorMessage: 'Error adding cost to order',
          onError: (result) => { formik.setErrors(result.errors) },
          onFinally: () => setLoading(false)
        })
    }

    const delMiscCost = (idMisc) => {
        api.deleteMiscellaneousCost(order.project.id, orderId, idMisc).handle({
            successMessage: 'Cost removed from order',
            onSuccess: (result) => { onOrderUpdated?.() },
            errorMessage: 'Error removing cost from order',
        })
    }

    const getMisCostTaxes = () => {
        setLoading(true);
        api.getMisCostTaxes().handle({
            onSuccess: (result) => {
                setMiscTaxes?.(result?.data?.results);
            },
            errorMessage: "Error getting cost taxes",
            onFinally: () => setLoading(false),
        });
    };

    useEffect(() => {
        getMisCostTaxes()
    }, []);

    return order?.is_editable || order?.miscellaneous_costs?.length > 0 ? (
      <Card style={{marginTop: pxToRem(20)}}>
          <MDBox display={'flex'} justifyContent={'space-between'} pb={2} m={3} mb={1} borderBottom={'1px solid #DBDBDB'}>
              <MDTypography variant={'h6'}>Additional Costs</MDTypography>
          </MDBox>
          <MDBox display={'flex'} flexDirection={'column'} justifyContent={'space-between'} pb={2} m={1} ml={5} mr={3} mb={1}>
              {order?.miscellaneous_costs?.map(item => <MiscCostCard misc_cost={item} deleteMiscCost={delMiscCost} order={order} />)}
          </MDBox>
          {order?.is_editable && <Formik
            initialValues={{
                amount: 0,
                description: '',
                tax_category: null,
            }}
            onSubmit={addMiscCost}
          >
              {(formik) => (
                <Form>
                    <Grid container px={3} pt={2}>
                        <Grid item xs={12} lg={6}>
                            <FormikInput
                              name={'amount'}
                              label={"Cost"}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <FormikInput
                              name={'description'}
                              type={"textarea"}
                              label={"Description"}
                              rows={3}/>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormikInput
                              type={"autocomplete"}
                              value={formik.values.tax_category}
                              fieldName={"tax_category"}
                              label={"Misc. Cost - Tax Category"}
                              options={miscTaxes}
                              accessKey={"name"}
                              onInputChange={(value) => {
                                  getMisCostTaxes({search: value})
                              }}
                              onChange={(value) => {
                                  formik.setFieldValue('tax_category', value)
                              }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <MDBox p={2} gap={2} display={'flex'} justifyContent={'flex-end'} width={'100%'}>
                                <MDButton
                                  variant="outlined"
                                  color="secondary"
                                  type={'submit'}
                                  disabled={loading || !formik.isValid}
                                >
                                    Add to Cost
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Form>
              )}
          </Formik>}
      </Card>

    ) : null
})

export const MiscCostCard = ({ misc_cost, deleteMiscCost, order, isForPrint }) => {
    const { id, description, amount, tax_category } = misc_cost;
    const [deleteId, setDeleteId] = useState(null)

    return (
      <>
          <ConfirmDialogModal
            open={deleteId !== null}
            title={`Delete Miscellaneous Cost`}
            handleClose={() => setDeleteId(null)}
            handleConfirm={() => {
                deleteMiscCost(deleteId)
                setDeleteId(null)
            }}
            description={'Are you sure you want to confirm this action?'}
            confirmText={'Confirm'}
            cancelText={'Cancel'}
          />
          <MDBox sx={MiscCostCard_styles.container} key={`misccost-${id}`} borderBottom={'1px solid #DBDBDB'}>
              <MDBox display={"flex"} width={"60%"}>
                  <MDBox sx={MiscCostCard_styles.icon_container}>
                      <Icon  color={'primary'}>
                          paid
                      </Icon>
                  </MDBox>

                  <MDBox sx={MiscCostCard_styles.desc_container} ml={1}>
                      <MDTypography sx={MiscCostCard_styles.boldText}>{description}</MDTypography>
                  </MDBox>
              </MDBox>
              <MDBox width={"40%"} display={"flex"} justifyContent={"space-between"} aling>
                  <MDBox>
                      <MDTypography sx={{...MiscCostCard_styles.boldText, width: "100%"}}>{money_fmt(amount)}</MDTypography>
                  </MDBox>
                  <MDBox>
                      <MDTypography sx={{...MiscCostCard_styles.boldText, width: "100%"}}>{`${tax_category?.code}`}</MDTypography>
                  </MDBox>
                  <MDBox>
                      { order?.is_editable && !isForPrint && <MDTypography
                        variant={'h6'}
                        color={'primary'}
                        sx={{cursor: 'pointer'}}
                        onClick={() => {
                            if (deleteMiscCost) setDeleteId(id)
                        }}>
                          Remove
                      </MDTypography>}
                  </MDBox>
              </MDBox>
          </MDBox>
      </>
    )
}
const MiscCostCard_styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        p: pxToRem(13)
    },
    icon_container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    desc_container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        whiteSpace: 'pre-line',
    },
    image: {
        mr: pxToRem(17)
    },
    boldText: {
        fontSize: pxToRem(14),
        fontWeight: 600,
        lineHeight: pxToRem(17),
        width: pxToRem(330),
        pb: pxToRem(4),
    },
    models: {
        fontSize: pxToRem(12),
        fontWeight: 400,
        lineHeight: pxToRem(14),
        pb: pxToRem(4),
    },
    prices: {
        fontSize: pxToRem(14),
        fontWeight: 400,
        lineHeight: pxToRem(17),
    },
    removeText: {
        pt: pxToRem(20),
        fontSize: pxToRem(14),
        fontWeight: 600,
        lineHeight: pxToRem(17),
        color: "#0B7340",
    },
}
