import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {memo, useCallback, useEffect, useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import MDTypography from "../../../components/MDTypography";
import {DoneText, NewPMButton} from "./styles";
import {Icon} from "@mui/material";
import CustomerCell from "../../../layouts/ecommerce/orders/order-list/components/CustomerCell";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";
import debounce from "lodash.debounce";
import AddBaseModal from "../../../components/add-base-modal/AddBaseModal";
import {ROLES} from "../../../services/constants";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";


export const AddSupplyManagerModal = memo(({open, item, onClose, loading, onUpdate}) => {
  const api = useApi()
  const [managers, setManagers] = useState([])
  const [searchValue, setSearchValue] = useState(null)
  const [selectedManager, setSelectedManager] = useState(item?.supply_managers)
  const [openModal, setOpenModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)


  useEffect(() => {
    getManagers({search: ""})
  }, [])

  const getManagers = ({search}) => {
    api.getAllUsers({
      search: search,
      page: 1,
      ordering: '',
      page_size: 25,
      role: ROLES.SUPPLY_MANAGER.name,
      manufacturer_id: item?.id
    }).then((result) => {
      if (result.kind === 'ok') {
        setManagers(result.data.results)
      }
    }).catch(reason => console.log(reason))
  }

  const searchUser = (value) => {
    getManagers({search: value})
  }

  const debouncedInputChange = useCallback(
    debounce(searchUser, 300)
    , [])

  const addProjectManger = () => {
    api.addDeleteSupplyManager({id: item.id, supply_manager: selectedUser.id}).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Supply Manager updated successfully', 'success')
        setOpenModal(false)
        if (selectedManager.includes(selectedUser.id)) {
          const newArray = selectedManager.filter(function(item) {
            return item !== selectedUser.id
          })
          setSelectedManager(newArray)
        } else {
          setSelectedManager([...selectedManager, selectedUser.id])
        }
        onUpdate()
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
  }

  return (
    <AddBaseModal
      open={open}
      loading={loading}
      handleClose={() => {
        onClose()
      }}
      title={'Update Supply Managers'}
      headerContent={
        <MDTypography
          variant='h6'
          sx={DoneText}
          onClick={() => onClose()}
        >
          Done
        </MDTypography>
      }
      showActions={false}
      modalContainer={{height: pxToRem(820)}}
    >
      <ConfirmDialogModal
        title={'Do you want to change the current supply manager?'}
        description={``}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={() => addProjectManger()}
      />

      <MDBox padding={`${pxToRem(20)} ${pxToRem(24)}`} overflow={'auto'}>
        <MDInput
          sx={{width: '100%'}}
          placeholder={'Search for users'}
          value={searchValue}
          onChange={(e) => {
            debouncedInputChange(e.target.value)
            setSearchValue(e.target.value);
          }}
          InputProps={{
            endAdornment:
              <Icon fontSize='medium' sx={({palette: {icons}}) => ({color: icons.search_icon})}>
                search
              </Icon>
          }}
        />
        <MDBox display='flex' flexDirection='column' gap={pxToRem(28)}>
          {!managers.length
            ? <MDBox height={'200px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <MDTypography variant='subtitle2'>No managers found</MDTypography>
              </MDBox>
            : managers.map(user => (
            <MDBox key={user.id} border='none' display='flex' justifyContent='space-between' alignItems='center'>
              <CustomerCell
                name={user.name}
                image={user.profile_picture}
                color={user.color || "dark"}
                styles={{fontSize: '1rem'}}
              />
              <MDButton
                variant={"outlined"}
                color={selectedManager.includes(user.id) ? "error" : "secondary"}
                onClick={() => {
                  if (selectedManager?.id !== user.id) {
                    setOpenModal(true)
                    setSelectedUser(user)
                  }
                }}
                sx={NewPMButton}
              >
                {selectedManager.includes(user.id)  ? 'Remove' : 'Add'}
              </MDButton>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </AddBaseModal>
  )
})
