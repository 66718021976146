import pxToRem from "assets/theme/functions/pxToRem";

export const ButtonsContainer = () => ({
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(18),
})

export const EditButton = () => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: pxToRem(14),
  gap: pxToRem(12),
  justifyContent: 'center',
  minHeight: pxToRem(35),
  p: 0,
  width: pxToRem(99),
})

export const DeleteButton = () => ({
  fontSize: pxToRem(14),
  minHeight: pxToRem(35),
  p: 0,
  width: pxToRem(99),
})

export const BrandText = ({palette: {icons}}) => ({
  color: icons.arrow_icon
})

export const PrimaryImageContainer = ({palette: {white, table}}) => ({
  alignItems: 'center',
  background: white.main,
  border: `1px solid ${table.border}`,
  borderRadius: '8px',
  display: 'flex',
  height: pxToRem(416),
  justifyContent: 'center',
  mt: pxToRem(18),
})

export const OtherImageContainer = ({palette: {white, table}}) => ({
  background: white.main,
  border: `1px solid ${table.border}`,
  borderRadius: '8px',
  width: pxToRem(145),
  height: pxToRem(145),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mt: pxToRem(30),
  cursor: 'pointer'
})

export const OtherImagesContainer = () => ({
  display: 'flex',
  gap: pxToRem(27),
  mt: pxToRem(30),
  width: '100%',
})

export const OtherImage = () => ({
  // maxHeight: pxToRem(143),
  maxHeight: '90%',
  objectFit: 'contain',
  maxWidth: pxToRem(143)
})

export const GeneralInfoContainer = ({palette: {white}}) => ({
  background: white.main,
  borderRadius: '16px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  minHeight: pxToRem(457),
  mt: pxToRem(10),
  paddingBlock: `${pxToRem(24)} ${pxToRem(35)}`,
  px: pxToRem(24),
})

export const GeneralInfoItem = () => ({
  display: 'flex',
  justifyContent: 'space-between'
})
export const GeneralInfoText = () => ({
  fontWeight: 400,
})

export const Divider = ({palette: {table}}) => ({
  borderTop: `1px solid ${table.border}`,
  mt: pxToRem(5),
})

export const AccordionBox = () => ({
  background: 'transparent',
  boxShadow: 'none',
  '.MuiAccordionSummary-root': {padding: '0'},
  '.MuiAccordionDetails-root': {padding: '0'},
  '.Mui-expanded': {margin: '0'},
})

export const ListItem = () => ({
  fontWeight: 400,
  marginLeft: pxToRem(25),
})

export const AccordionDivider = (theme) => ({
  ...Divider(theme),
  mt: pxToRem(21),
})
