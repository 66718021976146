/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";

// Components
import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Utils
import {formatNumberToCurrency, showMessage, useApi, useIsMobile} from "services/helpers";
import {apiMessages, useApiMessages} from "../../../services/api/api-messages";


// Styles
import {
  ProductImage,
  ProductBrandText,
  ProductDetailBox,
  ProductPriceText, ProductImageContainer
} from "./styles";
import {ROUTES} from "../../../services/constants";
import {
  Container,
  FilterItem,
  FilterItemModified,
  Input,
  InputContainer
} from "../../../components/FiltersBar/styles";
import {Autocomplete, Grid, InputAdornment, TextField} from "@mui/material";
import search from "../../../assets/icons/search.svg";
import Pagination from "../../../components/DataTable/Pagination/Pagination";
import {wrapApiCall} from "../../../services/helpers_ts";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import AddBaseModal from "../../../components/add-base-modal/AddBaseModal";
import menu from 'assets/icons/DotsThreeVertical.svg';
import {PopoverActions, PopoverItem} from "../../../components/PopoverActions";
import MDInput from "../../../components/MDInput";

function ManageProductsPage({showTotalEntries = true}) {
  const api = useApi()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderingRight, setOrderingRight] = useState(false);
  const [orderingLeft, setOrderingLeft] = useState(false);
  const [orderingModal, setOrderingModal] = useState(false);
  const [orderingValue, setOrderingValue] = useState(1);
  const [productId, setProductId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState(false);
  const [isOrdered, setIsOrdered] = useState(false);
  const [Products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const messages = useApiMessages('product', 'products')
  const isMobile = useIsMobile()

  const getManufacturers = ({search}) => {
    setLoading(true)
    wrapApiCall(api.getManufacturers({search, page_size: 'all'}), {
      onSuccess: (result) => {
        setManufacturers(result.data.results)
      },
      errorMessage: apiMessages.list.error('manufacturers')
    })
  }

  const getCategories = ({search}) => {
    setLoading(true)
    wrapApiCall(api.getCategories({search, page_size: 'all'}), {
      onSuccess: (result) => {
        setCategories(result.data.results)
      },
      errorMessage: apiMessages.list.error('categories')
    })
  }

  const getProducts = (search) => {
    setLoading(true)
    wrapApiCall(api.getProducts({...search, page_size: 12}), {
      onSuccess: (result) => {
        const {count, results} = result.data
        setProducts(results)
        setPaginationData(value => ({
          ...value,
          counts: count,
          itemsPerPage: results.length
        }))
      },
      errorMessage: messages.list.error
    })
  }
  const ReOrderProduct = (values) => {
    setLoading(true)
    wrapApiCall(api.reorderProduct(productId, values), {
      onSuccess: (result) => {
        showMessage('Product reordered successfully', 'success')
        getProducts(getFilters());
      },
      errorMessage: messages.list.error
    })
  }

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setProductId(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleCloseOrderModal = () => {
    setFilter(false);
    if(isOrdered) setIsOrdered(false);
    getProducts({search: searchQuery});
  }
  const handleOrderingButton = () => {
    if(isOrdered){
      setIsOrdered(false);
      getProducts({search: searchQuery})
      setSelectedCategory(null)
    } else {
      setFilter(true)
    }
  }

  const handleSubmitOrderLeftRightModal = () => {
    ReOrderProduct(orderingRight ? { right: orderingValue } : {left: orderingValue })
    setOrderingModal(false)
  }

  useEffect(() => {
    getProducts({search: searchQuery});
    getManufacturers({search: ''})
    getCategories({search: ''});
  }, [])


  const getFilters = () => {
    let filters = {search: searchQuery, page: 1}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    if (selectedManufacturer) {
      filters.manufacturer_id = selectedManufacturer
    }
    if (selectedCategory) {
      filters.category_id = selectedCategory
    }
    return filters
  }

  useEffect(() => {
    getProducts(getFilters())
  }, [searchQuery, selectedManufacturer, selectedCategory])

  return (
    <AdminLayout
      title={'Manage Products'}
      header={
        <MDBox display={"flex"} alignItems={"center"} gap={pxToRem(12)}>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            onClick={handleOrderingButton}
            sx={isMobile ? {
              fontSize: "14px",
              flexWrap: "nowrap",
              width: "178px",
              alignContent: "center",
              pb: "6px"
            } : {}}
          >
            {isOrdered ? 'Finish Ordering' : 'Order Products' }
          </MDButton>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            onClick={() => navigate('new')}
            sx={isMobile ? {
              fontSize: "14px",
              flexWrap: "nowrap",
              width: "178px",
              alignContent: "center",
              pb: "6px"
            } : {}}
          >
            Add New Product
          </MDButton>
        </MDBox>
      }
    >

      <MDBox width='100%'>
        <MDBox sx={Container}>
          <MDBox sx={FilterItemModified}>
          </MDBox>
          <MDBox sx={FilterItem}>
            <Autocomplete
              disablePortal
              options={manufacturers}
              getOptionLabel={(option) => option.name}
              sx={{width: '100%', ".MuiOutlinedInput-notchedOutline": {border: 'none'}}}
              renderInput={(params) =>
                <TextField {...params} label="Manufacturer"
                           sx={{
                             '.MuiSvgIcon-root': {color: '#0B7340'},
                             '.MuiInputLabel-root': {color: '#000', paddingTop: '6px', fontWeight: 500},
                           }}
                />}
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setSelectedManufacturer(newValue?.id)
                } else {
                  setSelectedManufacturer(null)
                }
              }}
            />
          </MDBox>
          <MDBox sx={FilterItem}>
            <Autocomplete
              disablePortal
              options={categories}
              getOptionLabel={(option) => option.name}
              sx={{width: '100%', ".MuiOutlinedInput-notchedOutline": {border: 'none'}}}
              renderInput={(params) => <TextField {...params} label="Category"
                sx={{
                  '.MuiSvgIcon-root': {
                    color: '#0B7340',
                  },
                  '.MuiInputLabel-root': {
                    color: '#000',
                    paddingTop: '6px',
                    fontWeight: 500
                  }
                }}
              />}
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setSelectedCategory(newValue?.id)
                } else {
                  setSelectedCategory(null)
                }
              }}
            />
          </MDBox>


          <MDBox sx={InputContainer}>
            <TextField
              sx={Input}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start" sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={'Search for a product'}
            />
          </MDBox>
        </MDBox>
        <Grid container spacing={5}>
          {Products?.length > 0 ? Products.map((product, index) => (
              <Grid item xs={12} md={6} lg={3} key={`product-key-${index}`}>
                <MDBox bgColor={'#fff'} p={1}>
                  <MDBox display={'flex'} justifyContent={'flex-end'} widht={40} height={40}>
                    {isOrdered && <MDBox component={'img'} src={menu} alt={'Menu'} sx={{ cursor: 'pointer' }} onClick={(event) => handleClick(event, product.id)} />}
                    <PopoverActions open={open} onClose={handleClose} anchorEl={anchorEl} sx={{ boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)' }} horizontal={'left'}>
                      <PopoverItem label={"Move to the Right"} onClick={() => {setOrderingModal(true); setOrderingRight(true); handleClose() }}/>
                      <PopoverItem label={"Move to the Left"} onClick={() => {setOrderingModal(true); setOrderingRight(false); setOrderingLeft(true); handleClose()}}/>
                      <PopoverItem label={"Move to the Start"} onClick={() => {ReOrderProduct({'start': true}); handleClose()}}/>
                      <PopoverItem label={"Move to the End"}  onClick={() => {ReOrderProduct({'end': true}); handleClose()}}/>
                    </PopoverActions>
                  </MDBox>
                  <MDBox onClick={() => navigate(ROUTES.ADMIN_PRODUCT_DETAIL(product.id))}>
                    <MDBox sx={ProductImageContainer}>
                      <MDBox
                        component={'img'}
                        src={product.main_img}
                        sx={ProductImage}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox sx={ProductDetailBox}>
                  <MDTypography variant='button' sx={ProductBrandText}>{product.manufacturer}</MDTypography>
                  <MDTypography variant='h3' sx={{width: '100%', wordWrap: 'break-word'}}>{product.name}</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Model {product.model}</MDTypography>
                  <MDTypography variant='h3' sx={ProductPriceText}>
                    {formatNumberToCurrency(product.price)}
                    <MDTypography variant='button' fontWeight='regular'>/unit</MDTypography>
                  </MDTypography>
                </MDBox>
              </Grid>
            )) :
            <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No items found</MDTypography>
            </MDBox>

          }
        </Grid>
        {showTotalEntries && <Grid container mt={5}>
          <Grid item>
            <MDBox m={2} sx={{color: '#666666', fontSize: 17, width: 300}}>Showing <span
              style={{color: '#000000'}}>{paginationData.itemsPerPage}</span> from <span
              style={{color: '#000000'}}>{paginationData.counts}</span> data</MDBox>
          </Grid>
          <Grid item ml={'auto'}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={12}
              onPageChange={page => {
                getProducts({...getFilters(), page: page})
                setPaginationData(value => ({...value, currentPage: page}))
              }}
            />
          </Grid>
        </Grid>}
      </MDBox>
      <AddBaseModal open={orderingModal} disabled={!orderingValue} handleClose={() => setOrderingModal(false)} buttonText={'Confirm'} onSubmit={handleSubmitOrderLeftRightModal}>
        <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} p={2} gap={1}>
          <MDTypography variant={'subtitle2'}>Set the number to move: </MDTypography>
          <MDInput
            type={'number'}
            onChange={(evt) => setOrderingValue(parseInt(evt?.target?.value))}
            value={orderingValue}
          />
        </MDBox>
      </AddBaseModal>
      <AddBaseModal open={filter} handleClose={handleCloseOrderModal} buttonText={'Confirm'} onSubmit={() => {setFilter(false); setIsOrdered(true)}}>
        <Autocomplete
          disablePortal
          options={categories}
          getOptionLabel={(option) => option?.name}
          sx={{width: '100%', ".MuiOutlinedInput-notchedOutline": {border: 'none'}}}
          renderInput={(params) => <TextField {...params} label="Category"
            sx={{
              '.MuiSvgIcon-root': {
                color: '#0B7340',
              },
              '.MuiInputLabel-root': {
                color: '#000',
                paddingTop: '6px',
                fontWeight: 500
              }
            }}
          />}
          onChange={(event, newValue) => {
            if (newValue?.id) {
              setSelectedCategory(newValue?.id)
            } else {
              setSelectedCategory(null)
            }
          }}
        />
      </AddBaseModal>
    </AdminLayout>
  );
}

export default observer(ManageProductsPage);

