
import AdminLayout from "../../../components/AdminLayout";
import {observer} from "mobx-react";
import DataTable from "../../../components/DataTable";
import MDButton from "../../../components/MDButton";
import {useNavigate} from "react-router-dom";
import {ROLES, ROUTES} from "../../../services/constants";
import {showMessage, useApi} from "../../../services/helpers";
import {useEffect, useState} from "react";
import {dataTableModel, renderTableRow} from "./utils";
import {Popover} from "@mui/material";
import MDBox from "../../../components/MDBox";
import {PopoverDeleteText} from "../manage-projects/styles";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDTypography from "../../../components/MDTypography";
import SearchBar from "../../../components/SearchBar";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";
import {UserModalForm} from "../create-user-account/userModalForm";

function AdminAllUsers() {
  const navigate = useNavigate()
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({...dataTableModel})
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [numberOfItemsPage, setNumberOfItemsPage] = useState(0);
  const [order, setOrder] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);

  const getAllUsers = (search = '', page = 1, ordering = order) => {
    setLoading(true)
    api.getAllUsers({search, page, ordering, page_size: 25}).then((result) => {
      if (result.kind === "ok") {
        const {count, results} = result.data
        const tmp = {...dataTableModel}
        tmp.rows = results.map(e => renderTableRow(e, setAnchorEl, setOpenPopover, setSelectedItem))
        setDatatable(tmp)
        setNumberOfItems(count)
        setNumberOfItemsPage(results.length)
        setOrder(ordering)
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const onDelete = () => {
    if (loading || !selectedItem) return
    setLoading(true)
    api.deleteUser(selectedItem?.id).then((result) => {
      if (result.kind === 'ok') {
        showMessage('User deleted successfully', 'success')
        getAllUsers('')
        onClose()
      } else if (result.kind === 'bad-data') {
        showMessage(result.errors[0])
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const sendPasswordSetEmail = () => {
    if (loading || !selectedItem) return
    setLoading(true)
    api.reSendAdminUserEmail(selectedItem?.id).then((result) => {
      if (result.kind === 'ok') {
        onClose()
        showMessage('User password email sent successfully', 'success')
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const adminResetPassword = () => {
    if (loading || !selectedItem) return
    setLoading(true)
    api.setUserPassword(selectedItem?.id).then((result) => {
      if (result.kind === 'ok') {
        onClose()
        showMessage('User password updated successfully', 'success')
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const onClose = () => {
    setOpenPopover(false)
    setOpenConfirmModal(false)
    setOpenModalForm(false)
    setSelectedItem(null)
  }

  const onEdit = () => {
    if (loading || !selectedItem) return
    setOpenModalForm(true)
  }

  useEffect(() => {
    getAllUsers(searchQuery)
  }, [])

  return (
    <AdminLayout
      title={'All Users'}
      header={
      <MDBox display={'flex'} flex={1} flexDirection={'row'} alignItems={'center'}>
        <SearchBar loading={loading} search={getAllUsers} setSearchQuery={setSearchQuery}/>
        <MDButton
          variant="contained"
          color="secondary"
          type='button'
          onClick={() => navigate(ROUTES.ADMIN_CREATE_NEW_USER)}
        >
          Add New User
        </MDButton>
      </MDBox>
      }
    >
      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={currentPage}
        numberOfItems={numberOfItems}
        numberOfItemsPage={numberOfItemsPage}
        searchFunc={getAllUsers}
        searchQuery={searchQuery}
        onPageChange={page => {
          getAllUsers(searchQuery , page)
          setCurrentPage(page)
        }}
      />

      {openModalForm && (
        <UserModalForm
          item={selectedItem}
          open={openModalForm}
          onClose={onClose}
          setLoading={setLoading}
          loading={loading}
          onSave={() => {
            onClose()
            getAllUsers(searchQuery);
          }}/>
      )}

      <ConfirmDialogModal
        title={'Do you want to delete this user?'}
        description={`${selectedItem?.name_raw} - ${selectedItem?.group}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openConfirmModal}
        handleClose={() => onClose()}
        handleConfirm={() => onDelete()}
      />

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => onClose()}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right',}}
        sx={{'.MuiPopover-paper': {boxShadow: 'none'}}}
      >
        <MDBox sx={{
          background: 'white',
          borderRadius: '10px',
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          gap: pxToRem(18),
          width: pxToRem(210),
        }} py={pxToRem(12)} px={pxToRem(16)}>
          <MDTypography
            variant='subtitle'
            fontSize={pxToRem(14)}
            sx={{cursor: 'pointer'}}
            onClick={() => onEdit()}
          >
            Edit
          </MDTypography>
          <MDTypography
            variant='subtitle'
            fontSize={pxToRem(14)}
            onClick={() => sendPasswordSetEmail()}
            sx={{cursor: 'pointer'}}
          >
            Send Password Set Email
          </MDTypography>
          <MDTypography
            variant='subtitle'
            fontSize={pxToRem(14)}
            onClick={() => adminResetPassword()}
            sx={{cursor: 'pointer'}}
          >
            Admin Reset Password
          </MDTypography>

          {selectedItem?.group !== ROLES.ADMIN.name && <MDTypography
            variant='subtitle'
            fontSize={pxToRem(14)}
            sx={PopoverDeleteText}
            onClick={() => setOpenConfirmModal(true)}
          >
            Delete
          </MDTypography>}
        </MDBox>
      </Popover>
    </AdminLayout>
  );
}

export default observer(AdminAllUsers);
