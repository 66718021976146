import MDBox from "components/MDBox";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import FormikInput from "components/FormikInput";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import { useApi } from "services/helpers";
import moment from "moment";
import {useStores} from "../../../../../models";

const DeliveryForm = ({ initialValues, handleChangedData, setShowForm }) => {
  const api = useApi();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [loading, setLoading] = useState(false);

  const conditionalValidationSM = () => {
    return loginStore.isSupplyManager
      ? Yup.string().required("Required")
      : Yup.string()
  };

  const conditionalValidationPM = () => {
    return loginStore.isProjectManager || loginStore.isSubContractorRep
      ? Yup.string().required("Required")
      : Yup.string()
  };

  const validationSchema = Yup.object().shape({
    po_code: Yup.string()
      .transform((value) => (value ? value : "")),
    from_address: conditionalValidationSM()
      .transform((value) => (value ? value : "")),
    to_address: conditionalValidationPM()
      .transform((value) => (value ? value : "")),
    supplier_name: conditionalValidationSM()
      .transform((value) => (value ? value : "")),
    supplier_phone: conditionalValidationSM()
      .transform((value) => (value ? `+${value.replace(/\D/g, "")}` : "")),
    supplier_email: conditionalValidationSM()
      .email("Invalid email")
      .transform((value) => (value ? value : "")),
    receiver_name: conditionalValidationPM()
      .transform((value) => (value ? value : "")),
    receiver_phone: conditionalValidationPM()
      .transform((value) => (value ? `+${value.replace(/\D/g, "")}` : "")),
    receiver_email: conditionalValidationPM()
      .email("Invalid email")
      .transform((value) => (value ? value : "")),
    delivery_instructions: Yup.string()
      .transform((value) => (value ? value : "")),
  });

  const updateTrackingInfo = (values) => {
    const {
      po_code,
      from_address,
      to_address,
      supplier_name,
      supplier_phone,
      supplier_email,
      receiver_name,
      receiver_phone,
      receiver_email,
      delivery_instructions,
    } = values;
    const newValues = {
      po_code,
      from_address,
      to_address,
      supplier_name,
      supplier_phone: supplier_phone? `+${supplier_phone?.replace(/\D/g, "")}`: null,
      supplier_email,
      receiver_name,
      receiver_phone: receiver_phone? `+${receiver_phone?.replace(/\D/g, "")}`: null,
      receiver_email,
      delivery_instructions,
    };
    setLoading(true);
    api
      .updateTrackingInfo(
        initialValues.projectId,
        initialValues.orderId,
        initialValues.trackingId,
        newValues
      )
      .handle({
        onSuccess: () => {
          handleChangedData();
          setShowForm();
        },
        errorMessage: "Error updating tracking info",
        onFinally: () => setLoading(false),
      });
  };

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          po_code: "",
          from_address: "",
          to_address: "",
          supplier_name: "",
          supplier_phone: "",
          supplier_email: "",
          receiver_name: "",
          receiver_phone: "",
          receiver_email: "",
          delivery_instructions: "",
        },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: updateTrackingInfo,
  });
  return (
    <FormikProvider value={formik}>
      <Grid item xs={2} lg={2}>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alingItems: "center",
            gap: 4,
          }}
        >
          <MDBox
            sx={{
              alignSelf: "center",
              borderRadius: pxToRem(6),
              marginBottom: pxToRem(18),
              paddingY: 1.5,
              flex: 1,
              display: "flex",
              gap: 2,
              justifyContent: "space-around",
              backgroundColor: colors.lightGrey,
            }}
          >
            <MDTypography sx={{ fontSize: pxToRem(12) }}>
              <strong>Last Update - Date:</strong>
              {"  "} {moment(initialValues?.modified).format("MM/DD/YYYY")}
            </MDTypography>

            <MDTypography sx={{ fontSize: pxToRem(12) }}>
              <strong>By:</strong>
              {"  "} {initialValues?.updated_by?.name}
            </MDTypography>

            <MDTypography sx={{ fontSize: pxToRem(12) }}>
              <strong>Supplier:</strong>
              {"  "} {initialValues?.manufacturer_name}
            </MDTypography>
          </MDBox>
          <FormikInput name="po_code" label="PO# (if applicable)" disabled={loginStore.isProjectManager || loginStore.isSubContractorRep}/>
        </MDBox>
      </Grid>

      <Grid item xs={2} lg={1}>
        <FormikInput
          name="from_address"
          label="Ship From Address"
          type="textarea"
          rows={4}
          disabled={loginStore.isProjectManager || loginStore.isSubContractorRep}
        />
      </Grid>

      <Grid item xs={2} lg={1}>
        <FormikInput name="to_address" label="Ship To Address" disabled={loginStore.isSupplyManager}/>
      </Grid>

      <Grid item xs={2} lg={1}>
        <MDTypography variant={"h6"} sx={{ marginY: 2 }}>
          Supplier's Logistics Contact Information
        </MDTypography>
        <MDBox sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormikInput name="supplier_name" label="Name" disabled={loginStore.isProjectManager || loginStore.isSubContractorRep} />
          <FormikInput name="supplier_phone" label="Phone" type="phone_input" disabled={loginStore.isProjectManager || loginStore.isSubContractorRep}/>
          <FormikInput name="supplier_email" label="Email" type="email" disabled={loginStore.isProjectManager || loginStore.isSubContractorRep}/>
        </MDBox>
      </Grid>

      <Grid item xs={2} lg={1}>
        <MDTypography variant={"h6"} sx={{ marginY: 2 }}>
          Receiver's Contact Info (site contact)
        </MDTypography>
        <MDBox sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormikInput name="receiver_name" label="Name" disabled={loginStore.isSupplyManager} />
          <FormikInput name="receiver_phone" label="Phone" type="phone_input" disabled={loginStore.isSupplyManager}/>
          <FormikInput name="receiver_email" label="Email" type="email" disabled={loginStore.isSupplyManager}/>
          <FormikInput
            name="delivery_instructions"
            label="Delivery Instructions"
            type="textarea"
            rows={4}
            disabled={loginStore.isSupplyManager}
          />
        </MDBox>
      </Grid>

      <Grid
        item
        xs={2}
        lg={2}
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          paddingBottom: 2,
          borderBottom: "1px solid #DBDBDB",
          marginLeft: "32px",
          gap: 2,
        }}
      >
        <MDButton
          variant={"contained"}
          color="primary"
          loading={loading}
          disabled={loading}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save
        </MDButton>
        <MDButton variant="outlined" color="secondary" onClick={setShowForm} type="button">
          Cancel
        </MDButton>
      </Grid>
    </FormikProvider>
  );
};

export default DeliveryForm;
