import {observer} from "mobx-react";
import {Client} from '@twilio/conversations';
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {ROUTES} from "../../../services/constants";
import {Box, Button, Card, CircularProgress, InputAdornment, TextField} from "@mui/material";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDBox from "../../../components/MDBox";
import search from "../../../assets/icons/search.svg";
import MDTypography from "../../../components/MDTypography";
import Chats from "./chats";
import link from "../../../assets/icons/link.svg";
import {useEffect, useRef, useState} from "react";
import SendIcon from '@mui/icons-material/Send';
import {UploadImage} from "./uploadImage";
import {openInNewTab, showMessage, useApi} from "../../../services/helpers";
import {useParams} from "react-router-dom";
import logo from "../../../assets/icons/dolovo-D.png";
import {useStores} from "../../../models";
import fileSvg from "../../../assets/icons/file-attached.svg";
import download from "../../../assets/icons/download.svg";
import moment from "moment";
import {create} from "apisauce";
import * as React from "react";


export const ChatBubble = ({message}) => {

  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const mine = loginStore.email === message.author
  const bubbleStyle = mine ? styles.rightBubble : styles.leftBubble

  const apisauce = create({})
  const downloadFile = (url, filename = 'file.jpg') => {
    api.download_file_by_method(url,{}, filename, apisauce.get ).then((result) => {
      if (result.kind === 'ok') {
        result.download_file()
      } else {
        throw new Error()
      }
    }).catch((reason) => {
      showMessage('Error downloading the file')
    })
  }

  return (
    <MDBox sx={{...styles.message, alignItems: mine ? "flex-end" : "flex-start"}}>
      {message.media === null && (<MDBox sx={bubbleStyle}>
        <MDTypography variant={"caption"} style={{display: "inline-block", whiteSpace: "pre-line", width: "90%"}} color={"black"} sx={styles.text}>{message.body}</MDTypography>
      </MDBox>)}
      {message.media && (
        <MDBox display={'flex'} alignItems={'center'} mb={5} sx={{...bubbleStyle, width: 300}} >
          <MDBox component={"img"} src={fileSvg} alt={"file"}/>
          <MDBox width={'90%'}>
            <MDTypography variant={'h6'} sx={{
              color: '#2A272E',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 200
            }}>{message.media.filename}</MDTypography>
            <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'80%'}>
              <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'}
                     onClick={() => downloadFile(message.url_file, message.media.filename)}>
                <Box component={"img"} src={download} alt={"download"} sx={{width: 20, height: 20, mb: 1, mr: 0.5}}/>
                <MDTypography
                  variant={'h6'}
                  fontSize={'12px'}
                  color={'tertiary'}
                  sx={{cursor: 'pointer'}}
                >
                  Download
                </MDTypography>
              </MDBox>
              <MDTypography
                variant={'h6'}
                fontSize={'12px'}
                color={'tertiary'}
                onClick={() => openInNewTab(message.url_file)}
                sx={{cursor: 'pointer'}}
              >
                View
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
      <MDTypography sx={styles.date}>{moment(message.date_created).format("hh:mm a")}</MDTypography>
    </MDBox>
  )
}

const styles = {
  message: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    m: 1,
    mt: 0,
    p: 0
  },
  date: {
    fontSize: 11,
    mt: 0,
    mr: 1,
    color: "black",
    fontWeight: 400
  },
  text: {
    fontWeight: 400,
    fontSize: 11,
    color: "black",
  },
  rightBubble: {
    p: 1,
    width: '90%',
    background: '#BEF183',
    borderRadius: "8px 0px 8px 8px"
  },
  leftBubble: {
    width: '90%',

    p: 1,
    background: '#F5F5F5',
    borderRadius: "0px 8px 8px 8px"
  },
}


const Messages = () => {
  const api = useApi()
  const {id: projectId} = useParams();
  const [loading, setLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [messageToSend, setMessageToSend] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentConversation, setCurrentConversation] = useState({conversation_sid: '', token: '', conversation: []});
  const [messages, setMessages] = useState([]);
  const clientRef = useRef(null);
  const scrollRef = useRef(null);

  const getUsers = (search = '', page = 1, ordering = '') => {
    setLoading(true)
    api.getConversationUsers({search, page, ordering, page_size: 25, project_id: projectId}).then((result) => {
      if (result.kind === "ok") {
        const {results} = result.data
        setUsers(results)
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const getConversation = (counterpart_id) => {
    setLoading(true)
    api.getConversation({counterpart_id}).then((result) => {
      if (result.kind === "ok") {
        const {token, chat} = result.response
        const conversation_sid = chat.conversation_sid
        setCurrentConversation({conversation_sid, token})
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "instant" })
  }


  const handleClick = (user) => {
    if (selectedContact && selectedContact.id === user.id) {
      setSelectedContact(null)
    } else {
      setSelectedContact(user)
    }
  }

  const sendMessage = async () => {
    setSendingMessage(true)
    if (clientRef.current && sendingMessage === false) {
      if (messageToSend) {
        await clientRef.current.sendMessage(messageToSend);
        setMessageToSend('')
        setSendingMessage(false)
      } else if (selectedFile) {
        const mediaStream = new Blob([selectedFile], {type: selectedFile.type});
        const fileName = selectedFile.name;
        const data = {
          contentType: selectedFile.type,
          media: mediaStream,
          filename: fileName,
        }
        await clientRef.current.sendMessage(data);
        setSelectedFile(null)
        setSendingMessage(false)
      }
      setMessageToSend('')
      setSelectedFile(null)
    }
  };

  const onSelectConversation = async (sid, token) => {
    setLoading(true)
    const client = new Client(token);
    let conversation = await client.getConversationBySid(sid);
    clientRef.current = conversation
    const messagesPaginator = await conversation.getMessages()
    const messages = messagesPaginator.items;
    const editedMessages = []
    messages.map(message => {
      if (message.type === 'media') {
        message.media.getContentTemporaryUrl().then(function (url) {
          message['url_file'] = url
        });
      }
      editedMessages.push(message)
    })
    setMessages(editedMessages)
    setLoading(false)

    conversation.on("messageAdded", async (incomingMessage) => {
      if (incomingMessage.type === 'media') {
        incomingMessage.media.getContentTemporaryUrl().then(function (url) {
          incomingMessage['url_file'] = url
        });
      }
      setMessages(value => [...value, incomingMessage]);
      conversation.advanceLastReadMessageIndex(incomingMessage.index).then(() => {
        // console.log('set last read message')
      })
    });

    conversation.advanceLastReadMessageIndex(editedMessages[editedMessages.length-1].index).then(() => {
        // console.log('set last read message')
    })
  }

  useEffect(() => {
    if (currentConversation.conversation_sid && currentConversation.token) {
      onSelectConversation(currentConversation.conversation_sid, currentConversation.token)
    }
  }, [currentConversation]);


  useEffect(() => {
    setMessages([])
    if (selectedContact) {
      getConversation(selectedContact.id)
    }
  }, [selectedContact])

  useEffect(() => {
    getUsers(searchText)
  }, [searchText])

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <DashboardProjectManagerLayout
      title={'Messages'}
      backButton
      backButtonText={'Home / Settings'}
      backRoute={ROUTES.USER_ACCOUNT_SETTINGS}
      marginHorizontal={10}
    >
      <Card sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center'}}>
        <MDBox sx={{borderRight: '1px solid #DBDBDB'}}>
          <MDBox pt={4} pl={4} pr={4}>
            <TextField
              fullWidth
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start">
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              placeholder={'Search contacts '}
            />
          </MDBox>
          <MDBox height={pxToRem(650)} sx={{overflowY: 'auto'}}>
            {users.map(user => (
              <Chats
                key={`id-${user.id}`}
                personName={user.name}
                image={user.profile_picture}
                online={false}
                message={user.message}
                handleClick={() => handleClick(user)}
              />
            ))}
            {users.length === 0 && (
              <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                <MDTypography variant={'body1'}>No contacts found</MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <MDBox width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} pb={4}>
          {selectedContact !== null && <MDBox width={'100%'}>
            <MDBox display={'flex'} width={'100%'} alignItems={'center'} pt={2} pb={3} pl={3}
                   sx={{borderBottom: '1px solid #DBDBDB'}} >
              {selectedContact.profile_picture
                ? <Box
                  component={"img"}
                  src={selectedContact.profile_picture}
                  alt={'chat-person'}
                  height={pxToRem(51)}
                  width={pxToRem(51)}
                  borderRadius={"50%"}
                  sx={{objectFit: 'contain'}}
                />
                : <Box
                  component={"img"}
                  src={logo}
                  alt={'chat-person'}
                  height={pxToRem(51)}
                  width={pxToRem(51)}
                  borderRadius={"50%"}
                />
              }
              <MDTypography variant={'h6'} ml={1}>{selectedContact.name}</MDTypography>
            </MDBox>
            <MDBox sx={{overflowY: 'auto', maxHeight: 500}}>
              {!loading && messages.map((message, index) => <ChatBubble message={message} key={"chatBubble-" + index}/>)}
              {loading && (
                <MDBox sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: '20vh'}}>
                  <CircularProgress color="success" />
                </MDBox>
              )}
              <div ref={scrollRef}></div>
            </MDBox>
          </MDBox>}
          <MDBox pt={4} pl={1} pr={4} display={'flex'} alignItems={'center'} gap={2} mt={'auto'}>
            <UploadImage setFile={setSelectedFile}/>
            <TextField
              fullWidth
              value={messageToSend}
              onChange={(e) => {
                setMessageToSend(e.target.value)
              }}
              sx={{
                '.MuiInputBase-input': {
                  padding: '20px'
                }
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start">
                    {/*TODO: REAHCER ESTE BOTON COMO COMPONENTE*/}
                    <Button
                      disabled={loading || selectedContact === null || (messageToSend === '' && selectedFile === null || sendingMessage)}
                      variant="outlined"
                      onClick={sendMessage}
                      sx={{
                        color: '#004222',
                        border: '2px solid #74BC1F',
                        borderRadius: '50px',
                        textTransform: 'capitalize',
                        width: '100px',
                      }}
                      endIcon={<SendIcon sx={{color: '#004222'}}/>}
                    >
                       {(loading || sendingMessage) ? <CircularProgress color="inherit" size={15} /> : 'Send'}
                    </Button>
                  </InputAdornment>,
              }}
              placeholder={'Write a message'}
            />
          </MDBox>
        </MDBox>
      </Card>
    </DashboardProjectManagerLayout>
  );
}

export default observer(Messages);
