import {Accordion, AccordionDetails, AccordionSummary, Box, Card} from "@mui/material";
import ExpandMore from "../../../assets/images/expand_more.svg";
import MDTypography from "../../../components/MDTypography";
import {Form, Formik, useField} from "formik";
import * as Yup from "yup";
import MDBox from "../../../components/MDBox";
import FormikInput from "../../../components/FormikInput";
import MDButton from "../../../components/MDButton";
import SendIcon from "@mui/icons-material/Send";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import fileSvg from "../../../assets/icons/file-attached.svg";
import download from "../../../assets/icons/download.svg";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import * as React from "react";
import fileIcon from "../../../assets/icons/file.svg";
import {useEffect, useState} from "react";
import {date_fmt, dolovo_date_fmt, openInNewTab, showMessage, useApi} from "../../../services/helpers";
import {ROUTES} from "../../../services/constants";
import logo from "../../../assets/icons/dolovo-D.png";
import {useNavigate} from "react-router-dom";

const InputFile = ({fileName, ...props}) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const {htmlFor, fileType, label, errors} = props;
    return (
        <>
            <label htmlFor={htmlFor}>
                <MDBox display={'flex'} alignItems={'center'} gap={1}>
                    <Box component={'img'} src={fileIcon} alt={'file'} />
                    <MDTypography variant='button' sx={{ width: '100px', whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis' }}>
                        {fileName}
                    </MDTypography>
                </MDBox>
            </label>
            <MDTypography variant='subtitle2' color={'error'} >
                {errorText ? errorText : ''}
            </MDTypography>
        </>
    )
}

export const OrderActivity = ({projectId, order, onOrderUpdated}) => {
    const api = useApi()
    const navigate = useNavigate()
    const [fileName, setFileName] = useState('');
    const [cachedActivity, setCachedActivity] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const loadMoreActivity = (page) => {
        page = page || nextPage
        api.getOrderActivity(projectId, order?.id, {page}).handle({
            errorMessage: 'Error getting order activity',
            onSuccess: (result) => {
                if (result.data.previous === null){ // first page
                    setCachedActivity(result.data.results)
                }else{
                    setCachedActivity([...cachedActivity, ...result.data.results])
                }
                if (result.data.next !== null){ // If there is a next page.
                    setNextPage(page + 1)
                }
                else{
                    setDisabled(true)
                }
            },
            onError: () => {
                setCachedActivity([])
                setNextPage(null)
            }
        })
    }

    useEffect(() => {
        if (!projectId || !order || !order?.id)
            return
        loadMoreActivity(nextPage || 1)
    }, [order])

    const sendMessage = (values, {resetForm}) => {
        if (!projectId || !order || !order?.id)
            return
        const data = {message: values.message, file: values.attached_file?.file}
        api.sendOrderMessage(projectId, order?.id, data).handle({
            successMessage: 'Message sent',
            errorMessage: 'Error sending message',
            onSuccess: () => {
                loadMoreActivity(1)
                resetForm()
                setFileName('')
            }
        })
    }

    const downloadFile = (url) => {
        // setLoading(true)
        api.download_file_get(url).then((result) => {
            // setLoading(false)
            if (result.kind === 'ok') {
                result.download_file()
            }else{
                throw new Error()
            }
        }).catch((reason) => {
            // setLoading(false)
            showMessage('Error downloading cut sheet file')
        })
    }

    const gotoOrder = (versionId) => {
        if (!projectId || !order || !order?.id)
            return
        navigate(ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(projectId, order.id))
    }

    const olderMessagesRuler = (
        //Divider
        <MDBox display={'flex'} justifyContent={'center'}  sx={{ position: 'relative', borderTop: '1px solid #DBDBDB' }}>
            <MDTypography variant={'text'} sx={{ px: 1, color: '#2A272E', backgroundColor: '#fff', position: 'absolute', top: -12 }}>Older messages</MDTypography>
        </MDBox>
        //Divider
    )

    const sendMessageForm = (
        <Formik
            initialValues={{ message: '',  attached_file: {} }}
            validationSchema={Yup.object({
                message: Yup.mixed().required('This field is required').nullable(),
                attached_file: Yup.object({
                    file: Yup.mixed().nullable()
                }),
            })}
            enableReinitialize
            onSubmit={sendMessage}>
            {(formik) => (
                <Form>
                    <MDBox sx={{ position: 'relative' }}>
                        <FormikInput
                            type={'textarea'}
                            name={'message'}
                            label={'Message'}
                            rows={7}
                            errors={formik.errors}
                        />

                        <MDBox
                            sx={{ position: 'absolute', left: 10, bottom: 30 }}
                        >
                            <input type='file' accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' name={`attached_file`} id={`attached_file`}
                                   style={{display: 'none'}}
                                   onInput={(event) => {
                                       if (event.target.files.length === 0){
                                           setFileName('')
                                           formik.setFieldValue("attached_file",  {file:null});
                                       }else{
                                           const file = event.target.files[0]
                                           setFileName(file.name)
                                           formik.setFieldValue("attached_file", {file});
                                       }
                                   }}
                                   onClick={(event) => {
                                       event.target.value = null
                                       setFileName('')
                                       formik.setFieldValue("attached_file",  {file:null});
                                   }}
                            />
                            <InputFile htmlFor={`attached_file`} fileName={fileName} errors={formik.errors} name={`attached_file`}/>
                        </MDBox>
                        <MDButton type={'submit'} sx={{ position: 'absolute', right: 10, bottom: 30 }} variant={'outlined'} color={'secondary'} endIcon={<SendIcon color={'tertiary'} />}>Send</MDButton>
                    </MDBox>

                </Form>
            )}
        </Formik>
    )

    const FileActivity = ({activity}) => (
        <MDBox display={'flex'} alignItems={'center'} mb={5}>
            <Box component={"img"} src={fileSvg} alt={"file"} />
            <MDBox width={'90%'}>
                <MDTypography variant={'h6'} sx={{ color: '#2A272E', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>{activity.filename}</MDTypography>
                <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'80%'} >
                    <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={() => downloadFile(activity.file)}>
                        <Box component={"img"} src={download} alt={"download"} sx={{ width: 20, height: 20, mb: 1, mr: 0.5 }} />
                        <MDTypography
                            variant={'h6'}
                            fontSize={'12px'}
                            color={'tertiary'}
                            sx={{cursor: 'pointer'}}
                        >
                            Download
                        </MDTypography>
                    </MDBox>
                    <MDTypography
                        variant={'h6'}
                        fontSize={'12px'}
                        color={'tertiary'}
                        onClick={() => openInNewTab(activity.file)}
                        sx={{cursor: 'pointer'}}
                    >
                        View
                    </MDTypography>
                </MDBox>
            </MDBox>
        </MDBox>
    )

    const VersionActivity = ({activity}) => (
        <MDBox mt={1} mb={2}>
            <MDBox
                display={'flex'}
                sx={{cursor: 'pointer'}}
                onClick={() => gotoOrder(activity.shared_version.version)}>
                <Card sx={{
                    backgroundColor: "#0B7340",
                    width: pxToRem(50),
                    height: pxToRem(50),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <MDTypography sx={{ fontSize: pxToRem(14), fontWeight: 600, color: "#fff" }}>V{activity.shared_version.version}</MDTypography>
                </Card>
                <MDBox ml={1}>
                    <MDBox>
                        <MDTypography variant={'h6'} sx={{ color: '#2A272E' }}>{`Order #${order?.id} - Version ${activity.shared_version.version}`}</MDTypography>
                        <MDTypography variant={'h6'} sx={{ fontWeight: 400, color: "#6A686D" }} >{dolovo_date_fmt(activity.shared_version.time)}</MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </MDBox>
    )

    const activityImage = (activity) => {
        return activity.from_user?.manufacturer_logo || activity.from_user?.profile_image || logo
    }

    return (<Accordion sx={{ boxShadow: 'none', p: 0, mt: 3, borderTop: '2px solid #DBDBDB', borderBottom: '2px solid #DBDBDB'}}  >
    <AccordionSummary
        expandIcon={<Box component={'img'} src={ExpandMore} alt='expand_more' mr={1} />}
    >
        <MDTypography variant={'h5'}>
            Activity
        </MDTypography>
        {/*<Box component={"img"} src={dot} alt={"dot"} sx={{ width: 10, height: 10, }}/>*/}
    </AccordionSummary>
    <AccordionDetails >
        {(order?.order_phase !== 'Rejected' && order?.order_phase !== 'Completed' )&& sendMessageForm}

        <MDBox>
            {cachedActivity.map((activity, index) => (
                <MDBox key={`id-${index}`} mb={5} sx={{display:"flex", flexDirection:"column"}}>
                    <MDBox  display={'flex'} justifyContent={'space-between'} sx={{gap: "10px", flexDirection:"row"}} >
                        <MDBox  display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={1} sx={{flexDirection:"row"}}>
                            <Box component={"img"} src={activityImage(activity)} alt={"logo"} sx={{ width: 20, height: 20, borderRadius: 10 }}/>
                            <MDBox sx={{display:"flex",flexDirection:"row", minWidth:pxToRem(60)}}>
                                <MDTypography variant={'h6'} sx={{ color: ' #2A272E', whiteSpace:"nowrap",overflow:"hidden", textOverflow:"ellipsis", wordBreak: "break-all" }}>{activity?.from_user?.name}</MDTypography>
                            </MDBox>
                            {/*<MDBox display={'flex'} justifyContent={'center'}  alignItems={'center'} gap={1}  >*/}
                                <MDTypography variant={'h6'} sx={{ textOverflow: "ellipsis",fontWeight: 400, color: "#6A686D" }} >{dolovo_date_fmt(activity.time)}</MDTypography>
                            {/*</MDBox>*/}
                        </MDBox>

                    </MDBox>
                    <MDBox pl={4}>
                        <MDTypography variant={'text'}>{activity.message}</MDTypography>
                        {activity.file && <FileActivity activity={activity} />}
                        {activity.shared_version && <VersionActivity activity={activity} />}
                    </MDBox>
                </MDBox>

            ))}
            <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <MDButton
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => loadMoreActivity(nextPage)}
                    endIcon={<FindReplaceIcon color={'tertiary'} />}
                    disabled={disabled}
                >
                    Load more
                </MDButton>
            </MDBox>
        </MDBox>
        {/*{olderMessagesRuler}*/}
    </AccordionDetails>
</Accordion>)
}
