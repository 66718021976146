import pxToRem from "assets/theme/functions/pxToRem";

export const ProductImageContainer = () => ({
  width: '100%',
  height: pxToRem(312),
  backgroundColor: '#ffffff',
  border: pxToRem(8)
})
export const ProductImage = () => ({
  cursor: 'pointer',
  width: '100%',
  height: pxToRem(312),
  objectFit: 'contain',
  border: pxToRem(8)
})

export const ProductBrandText = ({palette: {icons}}) => ({
  color: icons.arrow_icon,
  fontWeight: 500,
})

export const ProductDetailBox = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(6),
  width: pxToRem(312),
})

export const ProductPriceText = () => ({
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(2),
})
