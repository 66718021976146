/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {FieldArray, FormikProvider, getIn, useFormik} from "formik";
import {observer} from "mobx-react";

// MUI Components
import {Checkbox, Icon} from '@mui/material';

// Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import AdminLayout from "components/AdminLayout";
import FormikInput from "components/FormikInput";
import InputDragAndDrop from "components/InputDragAndDrop";

//Styles
import {
  Container,
  Title,
  FormContainer,
  FormSection,
  FieldsContainer,
  FileFieldsContainer,
  BooleanFieldsContainer,
  BooleanTitle,
  BooleanBox,
  CheckboxItem,
  CheckboxValue,
  ActionsBox,
  ImagecardContainer,
  ImageCardImage,
  ImagecardDeleteIcon, ImagecardWrapContainer,
} from './styles';
import pxToRem from "assets/theme/functions/pxToRem";
import {useEffect, useState} from "react";
import {
  openInNewTab,
  useApi
} from "../../../../services/helpers";
import {ROUTES} from "../../../../services/constants";
import {NEW_PRODUCT, productValidationSchema} from "./data";
import {useNavigate, useParams} from "react-router-dom";
import {CustomPricingDetailForm, CustomPricingForm} from "./CustomPricingForm";
import {useDebouncedValidate} from "../../../../shared/formik_debounce_validate";
import {apiMessages, useApiMessages} from "../../../../services/api/api-messages";


function ManageProductPage() {
  const {id: editProductId} = useParams();
  const api = useApi()
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(null)
  const [categories, setCategories] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [companies, setCompanies] = useState([])
  const [files, setFiles] = useState([]);
  const messages = useApiMessages('product', 'products')
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setLoading(true)
    if (editProductId) {
      api.getProduct(editProductId).handle( {
        onSuccess: (result) => {
          setCurrentProduct(result.data)
          getAllTags(result?.data?.category?.id)
        },
        errorMessage: (result) => result.kind === 'not-found' ? 'Product requested not found' : 'Error fetching product',
        onError: (result) => { navigate(ROUTES.ADMIN_PRODUCTS) },
        onFinally: () => setLoading(false)
      })
    }
    api.getCategories({page_size: 'all'}).handle( {
      onSuccess: (result) => { setCategories(result.data?.results)},
      errorMessage: apiMessages.list.error('categories'),
      onFinally: () => setLoading(false)
    })
  }, [])

  const getManufacturers = (cat_id) => {
    setLoading(true)
    api.getManufacturersByCategory(cat_id,{page_size: 'all'}).handle({
        onSuccess: (result) => { setManufacturers(result.data) },
        errorMessage: apiMessages.list.error('manufacturers'),
        onFinally: () => setLoading(false)
    })
  }

  const getAllTags = (cat_id) => {
    setLoading(true)
    api.getAllTags(cat_id).handle({
        onSuccess: (result) => setTags(result?.data.results),
        errorMessage: 'Error getting tags',
        onFinally: () => setLoading(false)
      }
    )
  }

  const getCompanies = () => {
    setLoading(true)
    api.getCompanies({page_size: 'all'}).handle({
        onSuccess: (result) => setCompanies(result?.data.results),
        errorMessage: 'Error getting companies',
        onFinally: () => setLoading(false)
      }
    )
  }

  const onSave = (values) => {
    setLoading(true)
    if (!currentProduct) {
      api.createProduct(values).handle( {
            successMessage: messages.create.success,
            onSuccess: (result) => { navigate(ROUTES.ADMIN_PRODUCTS) },
            errorMessage: messages.create.error,
            onError: (result) => { formik.setErrors(result.errors) },
            onFinally: () => setLoading(false)
          }
      )
    } else {
      api.updateProduct(values).handle( {
            successMessage: messages.update.success,
            onSuccess: (result) => { navigate(ROUTES.ADMIN_PRODUCTS) },
            errorMessage: messages.update.error,
            onError: (result) => { formik.setErrors(result.errors) },
            onFinally: () => setLoading(false)
          }
      )
    }
  }


  const formik = useFormik({
    initialValues: currentProduct || NEW_PRODUCT,
    validationSchema: productValidationSchema,
    enableReinitialize: true,

    onSubmit: onSave,
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });


  const handleDrop = (e, imagesProps) => {
    e.preventDefault();
    const newFiles = [...e.dataTransfer.files];
    setFiles([...files, ...newFiles]);
    newFiles.forEach(file => {
      imagesProps.push({
        image: file,
        url: URL.createObjectURL(file)
      })
    });
  }


  return (
    <AdminLayout
      title={editProductId !== undefined ? `Update Product [${currentProduct?.name}]` : 'Add New Product'}
      hasNavigationBack={true}
      contentCentered={true}
    >
      <MDBox sx={Container}>
        <MDTypography sx={Title}>Product Information</MDTypography>
        <MDBox sx={FormContainer}>
          <FormikProvider value={formik}>
            {/*Product Information*/}
            <MDBox sx={FormSection}>
              <MDBox sx={FieldsContainer}>
                <FormikInput
                  fullWidth
                  value={formik.values.category}
                  type={"autocomplete"}
                  fieldName={'category'}
                  label={"Category"}
                  options={categories}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    // getCompanies({search:value})
                  }}
                  styleContainer={{mb: 2}}
                  onChange={(value, fieldName) => {
                    formik.setFieldValue(fieldName, value)
                    formik.setFieldValue(`manufacturer`, null)
                    formik.setFieldValue(`tags`, null)
                    setManufacturers([])
                    setTags([])
                    if (value) {
                      getManufacturers(value.id)
                      getAllTags(value.id)
                    }
                  }}/>
                <FormikInput
                  fullWidth
                  value={formik.values.manufacturer}
                  type={"autocomplete"}
                  fieldName={'manufacturer'}
                  label={"Manufacturer"}
                  options={manufacturers}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    // getCompanies({search:value})
                  }}
                  styleContainer={{mb: 2}}
                  onChange={(value, fieldName) => {
                    formik.setFieldValue(fieldName, value)
                  }}/>
                {/*<MDTypography variant={"p"} component={"p"} color={"error"} mt={2}>{JSON.stringify(formik.errors)}</MDTypography>*/}

                <FormikInput
                  label='Product Name'
                  name='name'
                  errors={formik.errors}
                  mb={2}
                />
                <FormikInput
                  label='Model Number'
                  name='model'
                  errors={formik.errors}
                  mb={2}
                />
                <FormikInput
                  label='Brief Summary'
                  name='summary'
                  errors={formik.errors}
                  mb={2}
                />
              </MDBox>
              <FormikInput
                type='textarea'
                rows={8}
                label='Product Details'
                name='details'
                errors={formik.errors}
                mb={2}
              />
              <FormikInput
                type='textarea'
                rows={8}
                label='Features'
                name='features'
                errors={formik.errors}
                mb={2}
              />
              <FormikInput
                type='textarea'
                rows={3}
                label='Specifications'
                name='specs'
                errors={formik.errors}
                mb={2}
              />
              <FormikInput
                label='AvaTax System Tax Code'
                name='avatax_tax_code'
                errors={formik.errors}
                mb={2}
              />
              <MDBox sx={FileFieldsContainer}>
                <FieldArray name={'cut_sheets'}>
                  {(cutSheetsProps) => {
                    const parentPath = cutSheetsProps.name
                    const valueArray = getIn(formik.values, parentPath)
                    return (
                      <MDBox>
                        <input type='file' accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' id='cut_sheet' style={{display: 'none'}}
                               onInput={(event) => {
                                 cutSheetsProps.remove(0)
                                 if (event.target.files?.length) {
                                   cutSheetsProps.push({cut_sheet_file: event.target.files?.[0]})
                                 }
                               }}
                               onClick={(event) => {
                                 event.target.value = null
                               }}
                        />
                        <InputDragAndDrop htmlFor='cut_sheet' label='Cut Sheet' errors={formik.errors?.cut_sheet} name={'cut_sheets'}/>
                        {valueArray.map((file, index) => {
                            return (
                              <MDBox sx={ImagecardContainer} key={`architectural-1${index}`} >
                                <Icon sx={(th) => ({ml: pxToRem(11), mr: 2, mt: 2})} fontSize='medium' onClick={() => {
                                  openInNewTab(file?.cut_sheet_file instanceof File ? URL.createObjectURL(file?.cut_sheet_file) : file?.cut_sheet_file)
                                }}>attachment</Icon>
                                <MDTypography
                                  variant={'subtitle2'}
                                  onClick={() => {openInNewTab(file?.cut_sheet_file instanceof File ? URL.createObjectURL(file?.cut_sheet_file) : file?.cut_sheet_file)}}
                                  p={2}
                                  sx={{
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer'
                                  }}>
                                  {file?.cut_sheet_file.name || file?.cut_sheet_file}
                                </MDTypography>
                                <Icon
                                  sx={ImagecardDeleteIcon}
                                  fontSize='medium'
                                  onClick={() => cutSheetsProps.remove(index)}>
                                  remove_circle
                                </Icon>
                              </MDBox>
                            )
                          }
                        )}
                      </MDBox>
                    )
                  }}
                </FieldArray>
                <FieldArray name={'images'}>
                  {(imagesProps) => {
                    const parentPath = imagesProps.name
                    const valueArray = getIn(formik.values, parentPath)
                    return (
                      <MDBox>
                        <MDBox
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => handleDrop(e, imagesProps)}
                        >
                          <input type='file' accept='image/*' name='images' id='images' multiple style={{display: 'none'}}
                             onInput={(event) => {
                               if (event.target.files?.length) {
                                 const selectedFiles = [...event.target.files];
                                 selectedFiles.forEach(file => {
                                   imagesProps.push({
                                     image: file,
                                     url: URL.createObjectURL(file)
                                   })
                                 });
                               }
                             }}
                             onClick={(event) => {
                               event.target.value = null
                            }}
                          />
                          <InputDragAndDrop htmlFor={'images'} label={'Images'} errors={formik.errors?.images} name={'images'}/>
                        </MDBox>

                        {/*<InputDragAndDrop htmlFor='images' label='Images' fileType='image'/>*/}
                        <MDBox sx={ImagecardWrapContainer}>
                          {valueArray.map((image, index) => {
                              // console.log(index, image, image?.url || image)
                              return (
                                <MDBox sx={ImagecardContainer} key={`key-${index}`}>
                                  <img
                                    style={ImageCardImage}
                                    alt={image.image?.name}
                                    src={image?.url || image.image}
                                    onClick={() => {
                                      openInNewTab(image.image instanceof File ? URL.createObjectURL(image.image) : image.image)
                                    }}
                                  />

                                  <Icon
                                    sx={ImagecardDeleteIcon}
                                    fontSize='medium'
                                    onClick={() => imagesProps.remove(index)}>
                                    remove_circle
                                  </Icon>
                                </MDBox>
                              )
                            }
                          )}
                        </MDBox>
                      </MDBox>
                    )
                  }}
                </FieldArray>

              </MDBox>


              <MDBox sx={BooleanFieldsContainer}>
                <MDTypography sx={BooleanTitle}>Is this product ADA Compliant?</MDTypography>
                <MDBox sx={BooleanBox}>
                  <Checkbox
                    sx={CheckboxItem}
                    checked={formik.values.ada_compliant}
                    onChange={(e) => formik.setFieldValue('ada_compliant', true)}
                  />
                  {/*<Radio sx={CheckboxItem} value={true} />*/}
                  <MDTypography sx={CheckboxValue}>Yes</MDTypography>
                </MDBox>
                <MDBox sx={BooleanBox}>
                  {/*<Radio sx={CheckboxItem} value={false} />*/}
                  <Checkbox sx={CheckboxItem}
                            checked={!formik.values.ada_compliant}
                            onChange={(e) => formik.setFieldValue('ada_compliant', false)}
                  />
                  <MDTypography sx={CheckboxValue}>No</MDTypography>
                </MDBox>
              </MDBox>
              <MDBox sx={[FieldsContainer, { my: 2 }]}>
                <FormikInput
                  fullWidth
                  multiple={true}
                  value={formik?.values?.tags || []} // value should not be null when multiple is true
                  type={"autocomplete"}
                  fieldName={'tags'}
                  label={"Tags"}
                  options={tags}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    // getCompanies({search:value})
                  }}
                  styleContainer={{my: 2}}
                  onChange={(value, fieldName) => {
                    formik.setFieldValue(fieldName, value)
                  }}/>
              </MDBox>
              <MDBox width={pxToRem(350)}>
                {/*<MDTypography sx={Subtitle}>Product Filters</MDTypography>*/}
                {/*<FieldArray name={'filters'}>*/}
                {/*  {(filterProps) => {*/}
                {/*    const valueArray = getIn(formik.values, filterProps.name)*/}

                {/*    return (<>*/}
                {/*        {valueArray.map((value, index) => {*/}
                {/*            return (*/}
                {/*              <MDBox sx={{*/}
                {/*                ml: 3,*/}
                {/*                display: 'flex',*/}
                {/*                alignItems: "center",*/}
                {/*                flexDirection: "row",*/}
                {/*                justifyContent: "start",*/}
                {/*              }}>*/}
                {/*                <FormikInput*/}
                {/*                  label={`Filter #${index + 1}`}*/}
                {/*                  name={`${filterProps.name}.${index}.filter`}*/}
                {/*                  errors={formik.errors}*/}
                {/*                  sx={{width: '100%'}}*/}
                {/*                />*/}
                {/*                <Icon*/}
                {/*                  sx={(th) => ({...RemoveButton(th), ml: 2, mt: -2})}*/}
                {/*                  fontSize='medium'*/}
                {/*                  onClick={() => filterProps.remove(index)}*/}
                {/*                >*/}
                {/*                  remove_circle*/}
                {/*                </Icon>*/}
                {/*              </MDBox>*/}
                {/*            )*/}

                {/*          }*/}
                {/*        )}*/}
                {/*        <MDTypography*/}
                {/*          variant='button'*/}
                {/*          sx={valueArray.length ? AddFilterText : AddFilterTextEmpty}*/}
                {/*          onClick={() => filterProps.push({...NEW_FILTER})}*/}
                {/*        >+ Add Filter</MDTypography>*/}
                {/*      </>*/}
                {/*    )*/}
                {/*  }*/}
                {/*  }*/}
                {/*</FieldArray>*/}
                <MDBox sx={[FieldsContainer, { my: 2 }]}>
                  <MDBox>
                    <FormikInput
                      label={'Shipping Tax Code'}
                      name={`shipping_tax_code`}
                      value={formik?.values?.shipping_tax_code}
                      errors={formik.errors}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            {/*Product Information*/}
            <MDTypography sx={Title}>Product Pricing</MDTypography>
            <MDBox sx={FormSection}>
              <MDBox sx={FieldsContainer}>
                <FormikInput
                  fullWidth
                  value={formik?.values?.company}
                  type={"autocomplete"}
                  fieldName={'company'}
                  label={"Company default pricing"}
                  options={companies}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getCompanies({search:value})
                  }}
                  styleContainer={{my: 2}}
                  onChange={(value, fieldName) => {
                    formik.setFieldValue(fieldName, value)
                  }}/>
              </MDBox>
            </MDBox>
            <MDBox sx={FormSection} mt={pxToRem(5)}>
              <CustomPricingDetailForm
                is_default={true}
                value={formik.values.default_pricing}
                varPath={"default_pricing"}
                formik={formik}
              />
            </MDBox>
            <MDTypography sx={Title}>Third Party Default Pricing</MDTypography>
            <MDBox sx={FormSection} mt={pxToRem(22)}>
              <CustomPricingDetailForm
                is_default={true}
                value={formik.values.third_party_default_pricing}
                varPath={"third_party_default_pricing"}
                formik={formik}
              />
            </MDBox>
            <FieldArray name={`custom_pricings`}>
              {(pricingProps) => (
                <CustomPricingForm
                  pricingProps={pricingProps}
                  values={formik.values}
                  setFieldValue={formik.setFieldValue}
                  formik={formik}
                />
              )}
            </FieldArray>
            {/*Company/Region Pricing*/}
          </FormikProvider>
          <MDBox sx={ActionsBox}>
            <MDButton
              variant='outlined'
              color='secondary'
              sx={{width: pxToRem(120)}}
              onClick={() => navigate(-1)}
            >Cancel</MDButton>
            <MDButton
              variant="contained"
              color="secondary"
              loading={loading}
              disabled={loading}
              type='submit'
              sx={{width: pxToRem(150), height: pxToRem(45)}}
              onClick={() => formik.submitForm()}
            >
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </AdminLayout>
  );
}

export default observer(ManageProductPage);
