import pxToRem from "assets/theme/functions/pxToRem";

export const AddProjectManagerText = ({palette: {info}}) => ({
  color: info.modal,
  cursor: 'pointer',
  display: 'flex',
  fontSize: pxToRem(12),
  fontWeight: 400,
  justifyContent: 'flex-end',
  marginRight: 'auto',
  width: '100%',
})

export const DoneText = ({palette: {tertiary}}) => ({
  color: tertiary.main,
  cursor: 'pointer',
  fontWeight: 700
})

export const PopoverBox = ({palette: {white}}) => ({
  background: white.main,
  borderRadius: '10px',
  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(18),
  height: pxToRem(123),
  width: pxToRem(169),
})

export const NewPMButton = () => ({
  fontSize: pxToRem(14),
  minHeight: `${pxToRem(30)}`,
  padding: `${pxToRem(0)} ${pxToRem(36)}`,
  width: pxToRem(99),
})

export const PopoverDeleteText = ({palette: {popover}}) => ({
  color: popover.delete_text,
  cursor: 'pointer'
})

export const ModalDeleteProjectBox = ({palette: {white, table}}) => ({
  background: white.main,
  borderRadius: "10px",
  border: `1px solid ${table.border}`,
  minHeight: pxToRem(314),
  left: '50%',
  paddingBlock: `${pxToRem(20)} ${pxToRem(40)}`,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: pxToRem(444),
  '&:focus-visible': {outline: 'none !important'}
})

export const ModalDeleteTitle = ({palette: {table}}) => ({
  border: 'none',
  borderBottom: `1px solid ${table.border}`,
  fontSize: pxToRem(16),
  lineHeight: pxToRem(24),
  fontWeight: 400,
  letterSpacing: pxToRem(0.5),
  paddingBottom: pxToRem(20),
  textAlign: 'center',
  width: '100%',
})

export const ModalDeleteDialog = () => ({
  fontSize: pxToRem(14),
  fontWeight: 400,
  marginTop: pxToRem(31),
  textAlign: 'center',
  width: '100%',
})
