import {ROUTES} from "../../../services/constants";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import MDBox from "../../../components/MDBox";
import {observer} from "mobx-react";
import MDTypography from "../../../components/MDTypography";

const TermsConditions = () => {
  return (
    <DashboardProjectManagerLayout
      title={'Terms and Conditions'}
      backButton
      backButtonText={'Home / Settings'}
      backRoute={ROUTES.USER_ACCOUNT_SETTINGS}
      // marginHorizontal={30}
      // alignContent={"center"}
    >
      <MDBox sx={{display:"flex", flexDirection:"column", minWidth: "700px", p:"20px"}}>
        <MDTypography variant={"h6"} pb={2}>
          Ornare donec purus
        </MDTypography>
        <MDTypography variant={"text"} >
          Ornare donec purus
          Dapibus amet pulvinar commodo. Quam cursus nec volutpat urna sed. At odio dui a lorem quam enim purus dictum. Hac ut elit tortor viverra pellentesque augue nisl. Dui at pharetra purus nibh morbi sagittis tristique in. Ullamcorper ornare dignissim sit blandit orci gravida ut tempor lacus. Aenean blandit est risus facilisi nisi iaculis aenean ut mi. Malesuada sagittis gravida neque faucibus vitae lectus tempor amet. Nec rhoncus amet volutpat nisl volutpat. Eget adipiscing ipsum tempor mattis diam. Porta et diam nec maecenas. Sed pellentesque neque dictum dui. Habitasse varius nunc porta turpis amet eros orci.
          Dui nunc a sit mauris etiam diam donec amet libero. Accumsan dictum id nunc in mi neque enim. Netus ipsum sed fermentum at sem in ultrices. Nullam nibh enim mattis urna. Ultrices ultrices enim tincidunt eros tortor nunc neque. Lectus morbi laoreet eget facilisis hendrerit. Nam ipsum quis eleifend nec in sapien. Amet ipsum feugiat ridiculus sed diam magna. Ornare pellentesque eget nam enim. Vel tellus fermentum viverra elementum sed quam metus. Vel cursus et integer mattis mauris adipiscing. Laoreet quisque at ut nulla nam enim mollis sapien. Nisl quam accumsan nulla lobortis risus felis mauris lacus. Amet lacinia sagittis ut lectus tempus ridiculus ultrices. Luctus at suspendisse nibh magna aliquam tortor curabitur.
        </MDTypography>
        <MDTypography variant={"h6"} pt={3} pb={2}>
          Ornare donec purus
        </MDTypography>
        <MDTypography variant={"text"}>
          Ornare donec purus
          Dapibus amet pulvinar commodo. Quam cursus nec volutpat urna sed. At odio dui a lorem quam enim purus dictum. Hac ut elit tortor viverra pellentesque augue nisl. Dui at pharetra purus nibh morbi sagittis tristique in. Ullamcorper ornare dignissim sit blandit orci gravida ut tempor lacus. Aenean blandit est risus facilisi nisi iaculis aenean ut mi. Malesuada sagittis gravida neque faucibus vitae lectus tempor amet. Nec rhoncus amet volutpat nisl volutpat. Eget adipiscing ipsum tempor mattis diam. Porta et diam nec maecenas. Sed pellentesque neque dictum dui. Habitasse varius nunc porta turpis amet eros orci.
          Dui nunc a sit mauris etiam diam donec amet libero. Accumsan dictum id nunc in mi neque enim. Netus ipsum sed fermentum at sem in ultrices. Nullam nibh enim mattis urna. Ultrices ultrices enim tincidunt eros tortor nunc neque. Lectus morbi laoreet eget facilisis hendrerit. Nam ipsum quis eleifend nec in sapien. Amet ipsum feugiat ridiculus sed diam magna. Ornare pellentesque eget nam enim. Vel tellus fermentum viverra elementum sed quam metus. Vel cursus et integer mattis mauris adipiscing. Laoreet quisque at ut nulla nam enim mollis sapien. Nisl quam accumsan nulla lobortis risus felis mauris lacus. Amet lacinia sagittis ut lectus tempus ridiculus ultrices. Luctus at suspendisse nibh magna aliquam tortor curabitur.
        </MDTypography>
        <MDTypography variant={"h6"} pt={3} pb={2}>
          Ornare donec purus
        </MDTypography>
        <MDTypography variant={"text"} >
          Ornare donec purus
          Dapibus amet pulvinar commodo. Quam cursus nec volutpat urna sed. At odio dui a lorem quam enim purus dictum. Hac ut elit tortor viverra pellentesque augue nisl. Dui at pharetra purus nibh morbi sagittis tristique in. Ullamcorper ornare dignissim sit blandit orci gravida ut tempor lacus. Aenean blandit est risus facilisi nisi iaculis aenean ut mi. Malesuada sagittis gravida neque faucibus vitae lectus tempor amet. Nec rhoncus amet volutpat nisl volutpat. Eget adipiscing ipsum tempor mattis diam. Porta et diam nec maecenas. Sed pellentesque neque dictum dui. Habitasse varius nunc porta turpis amet eros orci.
          Dui nunc a sit mauris etiam diam donec amet libero. Accumsan dictum id nunc in mi neque enim. Netus ipsum sed fermentum at sem in ultrices. Nullam nibh enim mattis urna. Ultrices ultrices enim tincidunt eros tortor nunc neque. Lectus morbi laoreet eget facilisis hendrerit. Nam ipsum quis eleifend nec in sapien. Amet ipsum feugiat ridiculus sed diam magna. Ornare pellentesque eget nam enim. Vel tellus fermentum viverra elementum sed quam metus. Vel cursus et integer mattis mauris adipiscing. Laoreet quisque at ut nulla nam enim mollis sapien. Nisl quam accumsan nulla lobortis risus felis mauris lacus. Amet lacinia sagittis ut lectus tempus ridiculus ultrices. Luctus at suspendisse nibh magna aliquam tortor curabitur.
        </MDTypography>
      </MDBox>
    </DashboardProjectManagerLayout>
      )
}
export default observer(TermsConditions);
