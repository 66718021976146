import Icon from "@mui/material/Icon";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import logo from "assets/icons/dolovo-D.png";
import {Box} from "@mui/material";
import {ROLES} from "../../../services/constants";

export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name_table", width: `${pxToRem(150)}`, custom_ordering: "name"},
    {Header: "Email", accessor: "email", width: `${pxToRem(220)}`},
    {Header: "Title", accessor: "title", width: `${pxToRem(120)}`},
    {Header: "Role", accessor: "group", width: `${pxToRem(120)}`, custom_ordering: 'group_name'},
    {Header: "Company", accessor: "company_row_name", width: `${pxToRem(80)}`},
    {Header: "", accessor: "actions", disableOrdering: true, width: `${pxToRem(5)}`}
  ],
  rows: [],
};

const popOver = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  return (<>
      <Icon
        fontSize='medium' sx={{cursor: 'pointer'}}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setOpenPopover(true)
          setSelectedItem(item)
        }}
      >
        more_vert
      </Icon>
    </>
  )
}
const renderProfilePicture = (item) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {/*<Box display={"flex"} alignItems={"center"} sx={{background:"#fff"}} borderRadius={'50%'}>*/}
      {item.profile_picture
        ? <Box component={"img"} src={item.profile_picture} alt={"profile_picture"} width={pxToRem(25)} sx={{objectFit: 'cover'}}
               height={pxToRem(25)} borderRadius={"50%"}/>
        : <Box component={"img"} src={logo} alt={"profile_logo"} width={pxToRem(25)} height={pxToRem(25)}
               borderRadius={"50%"}/>
      }
      {item.name}
      {/*</Box>*/}
    </Box>
  )
}

export const renderTableRow = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  item.name_raw = item.name
  item.name_table = renderProfilePicture(item)
  item.actions = (popOver(item, setAnchorEl, setOpenPopover, setSelectedItem))
  return item
}
