/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import * as Yup from "yup";

// Image
import bgImage from "assets/images/background/background-login.jpg";
import IllustrationLayout from "../../../components/IllustrationLayout";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {ROUTES} from "../../../services/constants";
import {useRef, useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import {Field, Form, Formik} from "formik";
import {observer} from "mobx-react";
import FormikInput from "../../../components/FormikInput";
import {useStores} from "../../../models";

function ForgotPassword() {
  const api = useApi()
  const rootStore = useStores()
  const navigate = useNavigate()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const forgotPassword = (data) => {
    setLoading(true)
    api.forgotPassword(data.email).then((result) => {
      if (result.kind === "ok") {
        showMessage('Password reset e-mail has been sent.', 'success')
        navigate(ROUTES.LOGIN)
      } else if (result.kind === "bad-data") {
        formikRef.current.setErrors(result.errors)
        showMessage(result.errors.details)
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage(err.message))
      .finally(() => setLoading(false))
  }

  const validationSchema =
    Yup.object().shape({
      email: Yup.string().email().required(),
    })

  const initialValues = {
    email: "",
  };

  return (
    <IllustrationLayout
      title="Forgot Password"
      description="Please enter your registered email address and we will send you a link to reset your password."
      illustration={rootStore.backgroundImage ? rootStore.backgroundImage : bgImage}
    >
      <MDBox>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            forgotPassword(values);
          }}
        >
          {({errors, touched, isValid}) => (
            <Form>
              <FormikInput name={'email'} label={'Email address'} type={'email'} errors={errors} mb={2} />
              <MDBox mt={20} textAlign={"center"}>
                <MDButton
                  variant="contained"
                  color="primary"
                  sx={{width: pxToRem(280)}}
                  type='submit'
                  loading={loading}
                  disabled={loading || !isValid}
                >
                  Confirm
                </MDButton>
              </MDBox>
              <MDBox mt={3} textAlign={"center"}>
                <MDButton
                  variant="contained"
                  color="light"
                  sx={{width: pxToRem(280)}}
                  onClick={() => navigate(ROUTES.LOGIN)}
                >
                  Cancel
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </IllustrationLayout>
  );
}

export default observer(ForgotPassword);
