import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import * as React from "react";

export const ContractSectionButtons = ({handleA, loading}) => (
    <MDBox display={'flex'} justifyContent={'center'} gap={2} pt={2}>
        <MDButton loading={loading} color={'secondary'} sx={{ width: '100%' }} disabled={loading} onClick={handleA}>Proceed to Fulfillment</MDButton>
    </MDBox>
)

export const FulfillmentSectionButtons = ({handleA, loading}) => (
    <MDBox display={'flex'} justifyContent={'center'} gap={2} pt={2}>
        <MDButton loading={loading} color={'secondary'} sx={{ width: '100%' }} disabled={loading} onClick={handleA}>Mark Fulfilled</MDButton>
    </MDBox>
)
