import pxToRem from "../../../assets/theme/functions/pxToRem";
import {popoverActionsIcon} from "../../../components/PopoverActionsIcon";
import {Box} from "@mui/material";
import logo from "../../../assets/icons/dolovo-D.png";
import {truncate} from "../../../services/helpers";


export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name"},
    {Header: "Category", accessor: "category__name"},
    {Header: "Logo", accessor: "logo_row", disableOrdering: true},
    {Header: "Address", accessor: "address"},
    {Header: "Sub Contractor reps", accessor: "sub_contractor_rep_names", disableOrdering: true, width: pxToRem(180)},
    {Header: "", accessor: "actions", align: "left", disableOrdering: true, width: pxToRem(5)}
  ],
  rows: [],
};

export const renderBrandLogo = (item) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} alignItems={"center"} sx={{background:"#fff"}} borderRadius={'50%'}>
      { item.logo
        ? <Box component={"img"} src={item.logo} alt={"logo"} width={pxToRem(50)}  height={pxToRem(50)} borderRadius={"50%"} sx={{objectFit: 'contain'}} />
        : <Box component={"img"} src={logo} alt={"logo"} width={pxToRem(50)} height={pxToRem(50)} borderRadius={"50%"}  />
      }
      </Box>
    </Box>
  )
}


export const renderTableRow = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  item.logo_row = renderBrandLogo(item)
  item.sub_contractor_rep_names = truncate(item.sub_contractor_rep_names, 30)
  item.actions = (popoverActionsIcon(item, setAnchorEl, setOpenPopover, setSelectedItem))
  item.category__name = item.category_name
  return item
}
