import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ShipmentForm from "./ShipmentForm";
import { useState } from "react";
import ShipmentItem from "./ShipmentItem";
import PodForm from "./PodForm";
import PodDetails from "./PodDetails";
import ShipmentCompletetedForm from "./ShipmentCompletedForm";

const ShipmentList = ({ data, handleChangedData, showButton }) => {
  const [loading, setLoading] = useState(false);
  const [openShipmentForm, setOpenShipmentForm] = useState(false);
  const [openPodForm, setOpenPodForm] = useState(false);
  const [openPodDetails, setOpenPodDetails] = useState(false);
  const [openShipmentCompleted, setOpenShipmentCompleted] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedPod, setSelectedPod] = useState(null);

  const handleOpenShipmentForm = () => setOpenShipmentForm(!openShipmentForm);
  const handleOpenPodForm = () => setOpenPodForm(!openPodForm);
  const handleOpenPodDetails = () => setOpenPodDetails(!openPodDetails);
  const handleOpenShipmentCompleted = () => setOpenShipmentCompleted(!openShipmentCompleted);
  const handleSelectedShipment = (data) => setSelectedShipment(data);
  const handleSelectedPod = (data) => setSelectedPod(data);

  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingLeft: "32px",
        marginTop: 2,
        gap: 2,
      }}
    >
      {openShipmentForm && (
        <ShipmentForm
          trackingId={data.trackingId}
          initialValues={selectedShipment?
            {
            ...selectedShipment,
            scheduled_ship_date: selectedShipment?.revisions.length > 0?
              selectedShipment.revisions[selectedShipment.revisions.length - 1]?.revised_ship_date
              :selectedShipment?.scheduled_ship_date,
            scheduled_arrival_date: selectedShipment?.revisions.length > 0?
              selectedShipment.revisions[selectedShipment.revisions.length - 1]?.revised_arrival_date
              :selectedShipment?.scheduled_arrival_date,
          }
          :
            selectedShipment
        }
          // initialValues={selectedShipment}
          openModal={openShipmentForm}
          handleChangedData={handleChangedData}
          handleClose={handleOpenShipmentForm}
        />
      )}

      {openPodForm && (
        <PodForm
          selectedShipment={selectedShipment}
          openModal={openPodForm}
          handleChangedData={() => {
            handleChangedData()
            setOpenShipmentCompleted(true)
          }}
          handleClose={handleOpenPodForm}
        />
      )}

      {openPodDetails && (
        <PodDetails
          selectedPod={selectedPod}
          openModal={openPodDetails}
          handleClose={handleOpenPodDetails}
        />
      )}

      {openShipmentCompleted && (
        <ShipmentCompletetedForm
          data={selectedShipment}
          openModal={openShipmentCompleted}
          handleChangedData={handleChangedData}
          handleClose={handleOpenShipmentCompleted}
        />
      )}

      {data.values.map((shipment, index) => (
        <ShipmentItem
          key={index}
          shipment={shipment}
          index={index}
          handleOpenShipmentForm={handleOpenShipmentForm}
          handleOpenPodForm={handleOpenPodForm}
          handleOpenPodDetails={handleOpenPodDetails}
          handleOpenShipmentCompleted={handleOpenShipmentCompleted}
          handleSelectedShipment={handleSelectedShipment}
          handleSelectedPod={handleSelectedPod}
          showButton={showButton}
        />
      ))}

      <MDBox sx={{ width: "100%", display: "flex", justifyContent: "flex-end", marginY: 2 }}>
        {showButton && (
          <MDButton
            color="primary"
            loading={loading}
            disabled={loading}
            onClick={() => {
              setSelectedShipment(null);
              handleOpenShipmentForm();
            }}
          >
            Add Shipments
          </MDButton>
        )}
      </MDBox>
    </MDBox>
  );
};

export default ShipmentList;
