import AdminLayout from "../../../components/AdminLayout";
import MDBox from "../../../components/MDBox";
import {Box, Grid} from "@mui/material";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDTypography from "../../../components/MDTypography";
import filter from "../../../assets/icons/filter.svg";
import FormikInput from "../../../components/FormikInput";
import * as Yup from "yup";
import {Form, Formik, FormikProvider, useFormik} from "formik";
import MDButton from "../../../components/MDButton";
import {useEffect, useRef, useState} from "react";
import calendar from "assets/icons/calendarBlank.svg";
import {showMessage, transformObjectsToId, useApi} from "../../../services/helpers";
import building from "../../../assets/icons/building.svg";
import gear from "../../../assets/icons/gear.svg";
import worker from "../../../assets/icons/worker.svg";
import packageIcon from "../../../assets/icons/package.svg";
import {ROLES} from "../../../services/constants";
import {useDebouncedValidate} from "../../../shared/formik_debounce_validate";
import moment from "moment/moment";
const today = moment().format("YYYY-MM-DD");

export const validationSchema = Yup.object().shape({
  project_name: Yup.mixed(),
  project_address: Yup.mixed(),
  company: Yup.mixed(),
  region: Yup.mixed(),
  brand: Yup.mixed(),
  project_manager: Yup.mixed(),
  from_date: Yup.string(),
  to_date: Yup.string()
})

export const initialValues = {
  company: null,
  region: null,
  brand: null,
  project_manager: null,
  order: null,
  status: null,
  manufacturer: null,
  product: null,
  from_date: '',
  to_date: '',
};

function AdminAnalytics() {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([])
  const [regions, setRegions] = useState([])
  const [managers, setManagers] = useState([])
  const [companies, setCompanies] = useState([])
  const [orders, setOrders] = useState([])
  const [status, setStatus] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [products, setProducts] = useState([])
  const [counters, setCounters] = useState([
    {
      icon: building,
      quantity: 0,
      name: 'Company'
    },
    {
      icon: gear,
      quantity: 0,
      name: 'Projects'
    },
    {
      icon: worker,
      quantity: 0,
      name: 'Project Manager'
    },
    {
      icon: packageIcon,
      quantity: 0,
      name: 'Manufacturers'
    },
  ]);


  const getInitialData = () => {
    setLoading(true)
    api.getAnalyticsInitialData().then((result) => {
      if (result.kind === 'ok') {
        const {data} = result
        setCounters([
          {
            icon: building,
            quantity: data?.companies,
            name: 'Company'
          },
          {
            icon: gear,
            quantity: data?.projects,
            name: 'Projects'
          },
          {
            icon: worker,
            quantity: data?.project_managers,
            name: 'Project Managers'
          },
          {
            icon: packageIcon,
            quantity: data?.manufacturers,
            name: 'Manufacturers'
          },
        ])

      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const getManagers = ({search}) => {
    api.getAllUsers({
      search: search,
      page: 1,
      ordering: '',
      page_size: 25,
      role: ROLES.PROJECT_MANAGER.name
    }).then((result) => {
      if (result.kind === 'ok') {
        setManagers(result.data.results)
      }
    })
  }


  const getBrands = ({search}) => {
    api.getBrands({search}).then((result) => {
      if (result.kind === 'ok') {
        setBrands(result.data.results)
      }
    })
  }


  const getCompanies = ({search}) => {
    api.getCompanies({search}).then((result) => {
      if (result.kind === 'ok') {
        setCompanies(result.data.results)
      }
    })
  }

  const getRegions = ({search}) => {
    api.getRegions({search}).then((result) => {
      if (result.kind === 'ok') {
        setRegions(result.data.results)
      }
    })
  }

  const getManufacturers = ({search}) => {
    api.getManufacturers({search}).then((result) => {
      if (result.kind === 'ok') {
        setManufacturers(result.data.results)
      }
    })
  }

  const getOrders = ({search}) => {
    api.getOrdersAdmin({search}).then((result) => {
      if (result.kind === 'ok') {
        setOrders(result.data.results)
      }
    })
  }

  const getStatus = ({search}) => {
    api.getOrdersStatusAdmin({search}).then((result) => {
      if (result.kind === 'ok') {
        setStatus(result.data)
      }
    })
  }

  const getProducts = ({search}) => {
    api.getProducts({search}).then((result) => {
      if (result.kind === 'ok') {
        setProducts(result.data.results)
      }
    })
  }

  const generateReport = (values) => {
    values.from_date = values.from_date || null
    values.to_date = values.to_date || null

    if (values.from_date && values.to_date && moment(values.from_date,'DD/MM/YYYY') > moment(values.to_date,'DD/MM/YYYY'))

      formik.setErrors({error: "this is an error"})
    // console.log(formik.errors.from_date)
    // console.log(values.to_date)
    else {
      formik.setErrors({})
      setLoading(true)
      api.downloadAnalyticsReport(values).handle({
        onSuccess: (result) => { result?.download_file() },
        errorMessage: 'Error while generating analytics report',
        onFinally: () => setLoading(false),
      })
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = transformObjectsToId(values, ['company', 'region', 'manager', 'brand', 'project_manager', 'manufacturer', 'order', 'status', 'product'])
      generateReport(data)
    },
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });
  console.log(formik.errors)

  useEffect(() => {
    getInitialData()
    getCompanies({search: ''})
    getRegions({search: ''})
    getManagers({search: ''})
    getBrands({search: ''})
    getManufacturers({search: ''})
    getProducts({search: ''})
    getOrders({search: ''})
    getStatus({search: ''})
  }, [])

  // useEffect(() => {
  //   console.log("here")
  //   formik.setErrors({error: "this is an error"})
  //
  // }, [formik.values.from_date, formik.values.to_date])

  return (
    <AdminLayout
      title={'Analytics'}
    >
      <MDBox width={"100%"}>
        <MDBox display={"flex"} justifyContent={"space-between"}>
          {counters.map((item, index) => {
            return (
              <MDBox key={`id-${index}`} display={"flex"} p={"27px 18px 26px 22px"} width={pxToRem(248)} height={pxToRem(120)}
                     gap={1} bgColor={'white'} borderRadius={"xl"} border={"1px solid #DBDBDB"} sx={{alignItems:"center"}}>
                <Box component={"img"} src={item.icon} alt={"icon"} width={pxToRem(57)} height={pxToRem(57)}/>
                <MDBox display={"flex"} flexDirection={"column"}>
                  <MDTypography variant={"d7"} color={'tertiary'}>{item.quantity}</MDTypography>
                  <MDTypography variant={"textBold"} color={'darkGrey'}>{item.name}</MDTypography>
                </MDBox>
              </MDBox>
            )
          })}
        </MDBox>
        <MDTypography variant={"h5"} color={"title"} pt={4} pb={2}>View Total Sales</MDTypography>
        <MDBox width={"100%"} pl={4} pr={4} pt={1} bgColor={"#fff"} borderRadius={'xl'}>
          <MDBox display={"flex"} alignItems={"center"} pt={2} pb={2}>
            <Box component={"img"} src={filter} alt={"filter"} pr={1}/>
            <MDTypography variant={"textBold"} pt={1}>Filters</MDTypography>
          </MDBox>
          <FormikProvider value={formik}>
            <Grid container spacing={8}>
              <Grid item lg={6}>
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.company}
                  fieldName={"company"}
                  label={"Company"}
                  options={companies}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getCompanies({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('company', value)
                  }}
                  styleContainer={{mb: 2}}
                />
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.brand}
                  fieldName={"brand"}
                  label={"Brand"}
                  options={brands}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getBrands({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('brand', value)
                  }}
                  styleContainer={{mb: 2}}
                />
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.project_manager}
                  fieldName={"project_manager"}
                  label={"Project Manager"}
                  options={managers}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getManagers({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('project_manager', value)
                  }}
                  styleContainer={{mb: 2}}
                />
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.order}
                  fieldName={"order"}
                  label={"Orders"}
                  options={orders}
                  accessKey={"id"}
                  getOptionLabel={(option) => `Cart #${option.id}`}
                  filterOptions={(options, state) => options}
                  onInputChange={(value) => {
                    getOrders({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('order', value)
                  }}
                  styleContainer={{mb: 2}}
                />
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.status}
                  fieldName={"name"}
                  label={"Status"}
                  options={status}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getStatus({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('status', value)
                  }}
                  styleContainer={{mb: 2}}
                />
              </Grid>
              <Grid item lg={6}>
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.region}
                  fieldName={"region"}
                  label={"Regions"}
                  options={regions}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getRegions({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('region', value)
                  }}
                  styleContainer={{mb: 2}}
                />
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.manufacturer}
                  fieldName={"manufacturer"}
                  label={"Manufacturers"}
                  options={manufacturers}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getManufacturers({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('manufacturer', value)
                  }}
                  styleContainer={{mb: 2}}
                />
                <FormikInput
                  type={"autocomplete"}
                  value={formik.values.product}
                  fieldName={"product"}
                  label={"Products"}
                  options={products}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getProducts({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('product', value)
                  }}
                  styleContainer={{mb: 2}}
                />

                <Grid item lg={12}>
                  <Box component={"img"} src={calendar} alt={"calendar"} pr={1}/>
                  <MDTypography variant={"textBold"} pt={0.5}>Date Range</MDTypography>
                </Grid>
                <Grid container spacing={1} pt={2}>
                  <Grid item lg={6}>
                    <FormikInput
                      type={"date"}
                      name={"from_date"}
                      label={"From"}
                      errors={formik.errors.from_date}
                      setFieldValue={formik.setFieldValue}
                      extraParams={{
                        maxDate: moment(new Date().getDate(),'DD/MM/YYYY'),
                        clearable: true,
                      }}
                      disabled={true}
                      containerStyle={{mb: 2}}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <FormikInput
                      type={"date"}
                      name={"to_date"}
                      label={"To"}
                      setFieldValue={formik.setFieldValue}
                      extraParams={{
                        minDate: moment(formik?.values?.from_date, 'YYYY-MM-DD'),
                        maxDate: moment(new Date().getDate(),'DD/MM/YYYY'),
                        clearable: true,
                      }}
                      containerStyle={{mb: 2}}
                    />
                  </Grid>
                </Grid>
                <MDBox mt={1} mb={1} pb={4} gap={1.6} display={'flex'} justifyContent={'flex-end'}>
                  <MDButton
                    variant="outlined"
                    color="secondary"
                    type='button'
                    width={pxToRem(150)}
                    height={pxToRem(45)}
                    disabled={loading}
                    onClick={() => formik.resetForm()}
                  >
                    Clear Filters
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    type='submit'
                    disabled={!formik.isValid}
                    loading={loading}
                    height={pxToRem(45)}
                    sx={{width: pxToRem(220)}}
                    onClick={() => formik.handleSubmit()}
                  >
                    Download Total Sales
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </FormikProvider>
        </MDBox>
      </MDBox>
    </AdminLayout>
  );
}

export default AdminAnalytics;
