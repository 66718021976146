import MDBox from "components/MDBox";
import { GeneralModal } from "components/general-modal";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButton from "components/MDButton";
import DeliveryForm from "./DeliveryForm";
import ShipmentList from "./ShipmentList";
import DeliveryDetails from "./DeliveryDetails";
import { useApi } from "services/helpers";
import { VENDOR_TASKS } from "services/constants";
import { useStores } from "models";
import MDTypography from "../../../../../components/MDTypography";

const DeliveryModal = ({ data, buyer, openModal, onCloseModal, onOrderUpdated, readOnly = false }) => {
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const showButton = (loginStore.isSupplyManager || loginStore.isAdmin) && !readOnly;
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [showForm, setShowForm] = useState(!Boolean(data?.tracking?.from_address));
  const handleShowForm = () => setShowForm(!showForm);
  const {
    projectId = data?.project.id,
    orderId = data?.id,
    trackingId = data?.tracking?.id,
  } = data;

  const getTrackingInfo = () => {
    setLoading(true);
    api.getTrackingInfo(projectId, orderId, trackingId).handle({
      onSuccess: (result) => {
        setInitialValues(result.response);
      },
      errorMessage: "Error getting tracking info",
      onFinally: () => setLoading(false),
    });
  };

  const handleOrderFulfillment = () => {
    setLoading(true);
    api.doOrderVendorTask(projectId, orderId, VENDOR_TASKS.MARK_FULFILLED).handle({
      onSuccess: () => {
        onCloseModal();
        onOrderUpdated();
      },
      errorMessage: "Error marking order fulfilled",
      onFinally: () => setLoading(false),
    });
  };

  useEffect(() => {
    getTrackingInfo();
  }, [data]);

  return (
    <GeneralModal
      title={"Logistics Summary"}
      open={openModal}
      handleClose={() => {
        setShowForm(false);
        onCloseModal();
      }}
      fullHeight
    >
      <Grid
        container
        columns={2}
        sx={{
          width: "900px",
          maxHeight: "70vh",
          marginLeft: 0,
          paddingRight: "32px",
          paddingTop: "16px",
          overflowY: "auto",
        }}
        columnSpacing={4}
      >
        {showForm ? (
          <DeliveryForm
            initialValues={{ projectId, orderId, trackingId, ...initialValues }}
            handleChangedData={getTrackingInfo}
            setShowForm={handleShowForm}
          />
        ) : (
          <DeliveryDetails
            values={{ ...initialValues, buyer }}
            loading={loading}
            setShowForm={handleShowForm}
            readOnly={readOnly}
          />
        )}

        {initialValues?.shipments.length === 0 && (loginStore.isProjectManager || loginStore.isSubContractorRep)?
          <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <MDTypography sx={{ fontSize: pxToRem(14), fontWeight: "bold", marginTop: 2 }} >
              Waiting on Supplier to input Shipments
            </MDTypography>
          </MDBox>
          :
          <ShipmentList
            data={{ trackingId: initialValues?.id, values: initialValues?.shipments }}
            handleChangedData={onOrderUpdated}
            showButton={showButton}
          />
        }

      </Grid>
      <MDBox
        mb={1}
        sx={{ paddingY: pxToRem(15), paddingX: 4, gap: 2 }}
        display={"flex"}
        flex={1}
        justifyContent={"flex-end"}
        borderTop="1px solid #DBDBDB"
      >
        <MDButton
          variant="outlined"
          color="secondary"
          disabled={loading}
          onClick={() => {
            setShowForm(false);
            onCloseModal();
          }}
          type="button"
        >
          Go Back
        </MDButton>
        {showButton && (
          <MDButton
            variant="contained"
            color="secondary"
            loading={loading}
            disabled={!data.tracking.is_completed || loading}
            onClick={handleOrderFulfillment}
          >
            Complete Order
          </MDButton>
        )}
      </MDBox>
    </GeneralModal>
  );
};

export default DeliveryModal;
