/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {observer} from "mobx-react";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Utils
import {formatNumberToCurrency, showMessage, useApi} from "services/helpers";

// Styles
import {
  ProductImage,
  ProductBrandText,
  ProductDetailBox,
  ProductPriceText, ProductImageContainer
} from "./styles";
import {ROUTES} from "../../../services/constants";
import {
  Container,
  FilterItem,
  FilterItemModified,
  Input,
  InputContainer
} from "../../../components/FiltersBar/styles";
import {Autocomplete, Grid, InputAdornment, TextField} from "@mui/material";
import search from "../../../assets/icons/search.svg";
import Pagination from "../../../components/DataTable/Pagination/Pagination";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {MainContainer} from "../../admin/manage-products/styles";
import building from "assets/icons/building.svg"
import {useStores} from "../../../models";

export const PackagesPage = observer(({showTotalEntries = true}) => {
  const api = useApi()
  const {id: idProject, categoryId = null} = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams ] = useSearchParams();
  const order_or_quote_id = searchParams.get('oqn');
  const [packages, setPackages] = useState([]);
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});

  const getPackages = (data) => {
    setLoading(true)
    const orderid = order_or_quote_id ? {order_id: order_or_quote_id} : {}
    api.getProjectPackages(idProject, {...data, category_id: categoryId, ...orderid}).then(result => {
      if (result.kind === 'ok') {
        const { count, results } = result.data
        setPackages(results)
        setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length}))
      } else {
        showMessage(result.kind === 'not-found' ? 'Packages requested not found' : 'Error fetching package')
        navigate(ROUTES.USER_PROJECT_SELECTED(idProject))
      }
    }).catch(error => {
      showMessage()
      navigate(ROUTES.USER_PROJECT_SELECTED(idProject))
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    getPackages({search: searchQuery});
  }, [order_or_quote_id])

  const getFilters = () => {
    let filters = {search: searchQuery, page: 1, project_id: idProject, category_id: categoryId }
    setPaginationData(value => ({...value, currentPage: filters.page}))
    return filters
  }

  useEffect(() => {
    getPackages(getFilters())
  }, [searchQuery])

  const gotoPackageDetail = (id) => {
    const q = order_or_quote_id ? `?oqn=${order_or_quote_id}` : ''
    navigate(ROUTES.PACKAGE_DETAIL(idProject, id) + q)
  }

  return (
    <DashboardProjectManagerLayout
    >
      <MDBox sx={MainContainer}>
        <MDBox sx={Container}>
          <MDBox sx={InputContainer}>
            <TextField
              sx={Input}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start" sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={'Search for a package'}
            />
          </MDBox>
        </MDBox>
        <Grid container spacing={5}>
          {packages?.length > 0 ? packages.map((pack, index) => (
              <Grid item xs={12} md={6} lg={3} key={`package-key-${index}`}
                    onClick={() => gotoPackageDetail(pack.id)}>
                <MDBox sx={ProductImageContainer}>
                  <MDBox
                    component={'img'}
                    src={pack.logo}
                    sx={ProductImage}
                  />
                </MDBox>

                <MDBox sx={ProductDetailBox}>
                  <MDTypography variant='h3'>{pack.name}</MDTypography>
                  <MDTypography variant='h3' sx={ProductPriceText}>
                    {formatNumberToCurrency(pack.SM_price)}
                    <MDTypography variant='button' fontWeight='regular'>/unit</MDTypography>
                  </MDTypography>
                </MDBox>
              </Grid>
            )) :
            <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No items found</MDTypography>
            </MDBox>

          }
        </Grid>
        {showTotalEntries && <Grid container mt={5}>
          <Grid item>
            <MDBox m={2} sx={{color: '#666666', fontSize: 17, width: 300}}>Showing <span
              style={{color: '#000000'}}>{paginationData.itemsPerPage}</span> from <span
              style={{color: '#000000'}}>{paginationData.counts}</span> data</MDBox>
          </Grid>
          <Grid item ml={'auto'}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={24}
              onPageChange={page => {
                // page:page is to ensure that we have the current value in the filters
                getPackages({...getFilters(), page: page})
                setPaginationData(value => ({...value, currentPage: page}))
              }}
            />
          </Grid>
        </Grid>}
      </MDBox>
    </DashboardProjectManagerLayout>
  );
})


