import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import {Fab, Icon} from "@mui/material";
import {InitialPackageCategory, InitialPackageCategoryProduct} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import FormikInput from "../../../../../../components/FormikInput";
import {FieldArray, getIn} from "formik";
import CloseIcon from "@mui/icons-material/Close";
import {PackageCategoryProductForm} from "./PackageCategoryProductForm";
import {useApi} from "../../../../../../services/helpers";
import {useEffect, useState} from "react";
import {AddButton, RemoveButton} from "../styles";


const PackageCategoryForm = ({
                               pkg_categories,
                               index2,
                               parentPath,
                               values,
                               setFieldValue,
                               packageCategoryProps,
                               categories
                             }) => {
  const api = useApi()

  const [manufacturers, setManufacturers] = useState([])
  const [products, setProducts] = useState([])

  const getManufacturers = (cat_id) => {
    api.getManufacturersByCategory(cat_id).then((result) => {
      if (result.kind === 'ok') {
        setManufacturers(result.data)
      }
    })
  }

  const getProducts = (manu_id) => {
    api.getProductsByManufacturer(manu_id).then((result) => {
      if (result.kind === 'ok') {
        setProducts(result.data)
      }
    })
  }


  const mapPath = `${parentPath}.${index2}`

  useEffect(() => {
    if (pkg_categories?.category?.id) {
      getManufacturers(pkg_categories?.category?.id)
    }

    if (pkg_categories?.manufacturer?.id) {
      getProducts(pkg_categories?.manufacturer?.id)
    }
  }, [])

  return (

    <MDBox
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'start'}
    >
      <MDTypography variant={"h4"} component={"h4"}>Category #{index2 + 1}</MDTypography>

      <FormikInput
        type={"autocomplete"}
        value={pkg_categories.category}
        fieldName={`${mapPath}.category`}
        label={"Category"}
        options={categories}
        accessKey={"name"}
        onInputChange={(value) => {
          // getCompanies({search:value})

        }}
        styleContainer={{mb: 2}}
        onChange={(value, fieldName) => {
          setFieldValue(fieldName, value)
          setFieldValue(`${mapPath}.manufacturer`, null)
          setFieldValue(`${mapPath}.products`, [])
          setManufacturers([])
          setProducts([])
          if (value) {
            getManufacturers(value.id)
          }
        }}/>

      <FormikInput
        type={"autocomplete"}
        value={pkg_categories.manufacturer}
        fieldName={`${mapPath}.manufacturer`}
        label={"Manufacturer"}
        options={manufacturers}
        accessKey={"name"}
        onInputChange={(value) => {
          // getCompanies({search:value})
        }}
        styleContainer={{mb: 2}}
        onChange={(value, fieldName) => {
          setFieldValue(fieldName, value)
          setFieldValue(`${mapPath}.products`, [])
          setProducts([])
          if (value) {
            getProducts(value.id)
          }
        }}/>


      <FieldArray name={`${mapPath}.products`}>
        {(packageCategoryProductsProps) => (
          <PackageCategoryProductForm
            values={values}
            setFieldValue={setFieldValue}
            packageCategoryProductsProps={packageCategoryProductsProps}
            products={products}
            // debugBorders={debugBorders}
          />
        )}
      </FieldArray>

      <MDBox sx={{display: 'flex', alignItems: "center", flexDirection: "row", justifyContent: "space-between"}}>
        <MDTypography variant={"h4"} component={"h4"}>Delete Category #{index2 + 1}</MDTypography>
        <Icon sx={RemoveButton} fontSize='medium' onClick={() => packageCategoryProps.remove(index2)}>
          remove_circle
        </Icon>
      </MDBox>

    </MDBox>
  )
}

export const PackageCategoriesForm = ({packageCategoryProps, values, setFieldValue, categories, debugBorders}) => {
  const parentPath = packageCategoryProps.name
  const valuesArray = getIn(values, parentPath)

  return (
    <MDBox
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'start'}
    >

      {valuesArray.length > 0 &&
        <MDBox sx={{display: 'flex', flexDirection: "column", px: 3, width: "700px", ...debugBorders}}>
          {valuesArray.map((pkg_categories, index2) => <PackageCategoryForm
            index2={index2}
            pkg_categories={pkg_categories}
            parentPath={parentPath}
            values={values}
            setFieldValue={setFieldValue}
            packageCategoryProps={packageCategoryProps}
            categories={categories}
          />)}
        </MDBox>}

      <MDBox sx={{display: 'flex', alignItems: "center", flexDirection: "row", justifyContent: "space-between"}}>
        <MDTypography variant={"h4"} component={"h4"}>Add Category</MDTypography>
        <Icon sx={AddButton} fontSize='medium' onClick={() => packageCategoryProps.push({...InitialPackageCategory})}>
          add_circle
        </Icon>
      </MDBox>


    </MDBox>
  )
}
