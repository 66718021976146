import MDBox from "../../../../components/MDBox";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import { useCallback, useEffect, useState} from "react";
import {useApi} from "../../../../services/helpers";
import MDTypography from "../../../../components/MDTypography";
import {DoneText, NewPMButton} from "../styles";
import {Icon} from "@mui/material";
import CustomerCell from "../../../../layouts/ecommerce/orders/order-list/components/CustomerCell";
import MDButton from "../../../../components/MDButton";
import MDInput from "../../../../components/MDInput";
import debounce from "lodash.debounce";
import AddBaseModal from "../../../../components/add-base-modal/AddBaseModal";
import {ROLES} from "../../../../services/constants";
import ConfirmDialogModal from "../../../../components/ConfirmDialogModal";
import {observer} from "mobx-react";


export const SupplierModalBlackList = observer(({open, item, onClose, loading, onUpdate}) => {
  const api = useApi()
  const [managers, setManagers] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [currentIds, setCurrentIds] = useState(item?.excluded_supply_managers?.map((item) => item.id) || [])
  const [openModal, setOpenModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    getManagers({search: ""})
  }, [])

  const getManagers = ({search}) => {
    api.getAllUsers({
      search: search,
      page: 1,
      ordering: '',
      page_size: 25,
      role: ROLES.SUPPLY_MANAGER.name,
      project_id_suppliers: item?.id
    }).handle({
        onSuccess: (result) => { setManagers(result.data.results) },
        errorMessage: 'Error getting managers',
    })
  }

  const searchUser = (value) => {
    getManagers({search: value})
  }

  const debouncedInputChange = useCallback(
    debounce(searchUser, 300)
    , [])

  const addSupplyManger = () => {
    if (loading || !item) return
    api.addSupplyManager({id: item.id, supply_manager: selectedUser.id}).handle({
      onSuccess: (result) => {
        console.log(result)
        setCurrentIds(result.response?.map((item) => item.id) || [])
      },
      successMessage: 'Supply Manager updated successfully',
      errorMessage: 'Error updating Supply Manager',
      onFinally: () => { setOpenModal(false) },
    })
  }

  return (
    <AddBaseModal
      open={open}
      loading={loading}
      handleClose={() => {
        onClose()
      }}
      title={'Update Supply Managers'}
      headerContent={
        <MDTypography
          variant='h6'
          sx={DoneText}
          onClick={() => {
            onUpdate?.()
            onClose()
          }}
        >
          Done
        </MDTypography>
      }
      showActions={false}
      modalContainer={{height: pxToRem(820)}}
    >
      <ConfirmDialogModal
        title={'Do you want to change the current supply manager?'}
        description={``}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={() => addSupplyManger()}
      />

      <MDBox padding={`${pxToRem(20)} ${pxToRem(24)}`} overflow={'auto'}>
        <MDInput
          sx={{width: '100%'}}
          placeholder={'Search for users'}
          value={searchValue}
          onChange={(e) => {
            debouncedInputChange(e.target.value)
            setSearchValue(e.target.value);
          }}
          InputProps={{
            endAdornment:
              <Icon fontSize='medium' sx={({palette: {icons}}) => ({color: icons.search_icon})}>
                search
              </Icon>
          }}
        />
        <MDBox display='flex' flexDirection='column' gap={pxToRem(28)}>
          {!managers.length
            ? <MDBox height={'200px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <MDTypography variant='subtitle2'>No managers found</MDTypography>
              </MDBox>
            : managers.map(user => (
            <MDBox key={user.id} border='none' display='flex' justifyContent='space-between' alignItems='center'>
              <CustomerCell
                name={user.name}
                image={user.profile_picture}
                color={user.color || "dark"}
                styles={{fontSize: '1rem'}}
              />
              <MDButton
                variant={"outlined"}
                color={currentIds.includes(user.id) ? "secondary" : "error"}
                // color={currentIds.includes(user.id) ? "error" : "secondary"}
                onClick={() => {
                    setOpenModal(true)
                    setSelectedUser(user)
                }}
                sx={NewPMButton}
              >
                {currentIds.includes(user.id)  ? 'Add' : 'Remove'}
                {/*{currentIds.includes(user.id)  ? 'Remove' : 'Add'}*/}
              </MDButton>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </AddBaseModal>
  )
})
