/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import {observer} from "mobx-react";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {useStores} from "../../models";

function PageLayout({ background, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const rootStore = useStores()
  const {loginStore} = rootStore

  const isNotAdmin = (loginStore.isProjectManager || loginStore.isSupplyManager || loginStore.isSubContractorRep);
  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <MDBox
      width="100%"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden", maxWidth: isNotAdmin ? pxToRem(1920) : '100%'}}
    >
      {children}
    </MDBox>
  );
}

export default observer(PageLayout);
