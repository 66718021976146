import {FormikProvider, useFormik} from "formik";
import FormikInput from "../../../../components/FormikInput";
import MDBox from "../../../../components/MDBox";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import {memo} from "react";
import {useApi} from "../../../../services/helpers";
import {useDebouncedValidate} from "../../../../shared/formik_debounce_validate";
import MDTypography from "../../../../components/MDTypography";
import AddBaseModal from "../../../../components/add-base-modal/AddBaseModal";
import {renderCompanyLogo} from "../utils";
import {wrapApiCall} from "../../../../services/helpers_ts";
import { useApiMessages} from "../../../../services/api/api-messages";

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Company name is required'),
  address: Yup.string().required('Company address is required'),
  logo: Yup.mixed().required('Company logo is required').nullable(),
})

export const CompanyModalForm = memo(({open, item, onClose, onSave, setLoading, loading}) => {
  const api = useApi()
  const messages = useApiMessages('company', 'companies')

  const formik = useFormik({
    initialValues: {
      name: item?.id ? item.name : "",
      address: item?.id ? item.address : "",
      logo: item?.id ? item.logo : null
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    // onSubmit: (values) => save(values),
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  const save = (values) => {
    setLoading(true)
    if (item?.id) {
      const keys = []
      if (item.logo !== values.logo) {
        keys.push(['logo'])
      } else {
        delete values['logo']
      }
      values["id"] = item.id
      editCompany(values, keys)
    } else {
      createCompany(values, ['logo'])
    }
  }

  const editCompany = (data, keys) => {
    api.editCompany(data).handle( {
          successMessage: messages.update.success,
          onSuccess: onSave,
          errorMessage: messages.update.error,
          onError: (result) => { formik.setErrors(result.errors) },
          onFinally: () => setLoading(false)
        }
    )
  }

  const createCompany = (data, keys) => {
    api.createCompany(data).handle({
          successMessage: messages.create.success,
          onSuccess: onSave,
          errorMessage: messages.create.error,
          onError: (result) => { formik.setErrors(result.errors) },
          onFinally: () => setLoading(false)
        }
    )
  }


  return (
    <AddBaseModal
      open={open}
      handleClose={() => {
        formik.resetForm()
        onClose()
      }}
      title={item?.id ? "Edit Company" : "Add Company"}
      buttonText={"Save"}
      onSubmit={() => {save(formik.values)}}
      loading={loading}
    >
      <MDBox
        display='flex'
        flexDirection='column'
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
      >
        <FormikProvider value={formik}>
          <FormikInput name="name" label="Company Name"/>
          <FormikInput name="address" label="Company Address"/>
          <MDBox display={"flex"} flexDirection={"column"}>
            <FormikInput
              fullWidth
              filename={"company_logo"}
              type={"file"}
              name={"logo"}
              label={"Company Logo"}
              onChange={(e) => {
                const fileValue = e.target.files[0]
                formik.setFieldValue("logo", fileValue);
              }}
            />
            {item?.id && (
              <MDBox display={"flex"} flexDirection={"row"} gap={"5px"} alignItems={"center"}>
                <MDTypography variant={"h5"} mt={"-4px"} fontWeight={"bold"} fontSize={12}>Current:</MDTypography>
                {renderCompanyLogo(item)}
              </MDBox>
            )}
          </MDBox>

        </FormikProvider>
      </MDBox>
    </AddBaseModal>
  )
})
