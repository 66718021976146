import MDBox from "../../../components/MDBox";
import {Box} from "@mui/material";
import MDTypography from "../../../components/MDTypography";

import pxToRem from "../../../assets/theme/functions/pxToRem";
import green_dot from "assets/icons/backgroundgreenRounded.svg";
import logo from "../../../assets/icons/dolovo-D.png";

const Chats = ({ personName, image, message, messageTime, online, handleClick }) => {
  return (
    <MDBox display={'flex'} alignItems={'center'} gap={'14px'} m={2} sx={{cursor: 'pointer'}} onClick={handleClick}>
      <MDBox
        sx={{ position: 'relative'}}
      >
        {image
          ? <Box
            component={"img"}
            src={image}
            alt={'chat-person'}
            height={pxToRem(51)}
            width={pxToRem(51)}
            borderRadius={"50%"}
            sx={{objectFit: 'contain'}}
          />
          : <Box
            component={"img"}
            src={logo}
            alt={'chat-person'}
            height={pxToRem(51)}
            width={pxToRem(51)}
            borderRadius={"50%"}
          />
        }
        {online &&
          <Box
            component={'img'}
            src={green_dot}
            alt={'dot'}
            height={pxToRem(14)}
            width={pxToRem(14)}
            sx={{ position: 'absolute', right: 0, bottom: 12}}
          />
        }
      </MDBox>
      <MDBox width={'100%'}>
        <MDBox
          display={'flex'}
          justifyContent={'space-between'}
        >
          <MDTypography variant={'h6'} >{personName}</MDTypography>
          <MDTypography variant={'subtitle2'} >{messageTime}</MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography variant={'text'}>{message}</MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default Chats;
