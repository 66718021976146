/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import * as Yup from "yup";

// Image
import bgImage from "assets/images/background/background-login.jpg";
import IllustrationLayout from "../../../components/IllustrationLayout";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {ROLES, ROUTES} from "../../../services/constants";
import {observer} from "mobx-react";
import {useStores} from "../../../models";
import {showMessage, useApi} from "../../../services/helpers";
import {useEffect, useRef, useState} from "react";
import {runInAction} from "mobx";
import {Field, Form, Formik} from "formik";
import FormikInput from "../../../components/FormikInput";
import {wrapApiCall} from "../../../services/helpers_ts";

function SignIn() {
  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const navigate = useNavigate()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const getCurrentBackgroundImage = () => {
    setLoading(true)
    api.getBackgroundImage().handle({
        onSuccess: (result) => { if (result.data?.image !== null) {
          rootStore.setBackgroundImage(result.data.image)
        }},
        errorMessage: 'Error getting background image',
        onFinally: () => setLoading(false)
      })
  }

  const login = (data) => {
    setLoading(true)
    api.login(data.email, data.password).handle( {
          onSuccess: (result) => {
            const {response} = result
            const {user, access_token} = response
            runInAction(() => {
              loginStore.setUser(user)
              loginStore.setApiToken(access_token)
            })

            if (user.is_superuser || user.group === ROLES.ADMIN.name) {
              navigate(ROUTES.ADMIN_ANALYTICS)
            } else if (!(user.is_superuser || user.group === ROLES.ADMIN.name) && user?.password_changed_by_admin) {
              navigate(ROUTES.USER_CHANGE_PASSWORD)
            } else {
              navigate(ROUTES.USER_PROJECT_SELECTOR)
            }
          },
          errorMessage: 'Error signing in',
          onError: (result) => {
            formikRef.current?.setErrors(result.errors)
          },
          onFinally: () => setLoading(false)
        }
    )
  }

  const validationSchema =
    Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    })

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    if (loginStore.isLoggedIn) {
      if (loginStore.is_superuser || loginStore.group === ROLES.ADMIN.name) {
        navigate(ROUTES.ADMIN_ANALYTICS)
      } else {
        navigate(ROUTES.USER_PROJECT_SELECTOR)
      }
    }

    getCurrentBackgroundImage()
  }, [])

  return (
    <IllustrationLayout
      title="Sign In"
      description=""
      illustration={rootStore.backgroundImage ? rootStore.backgroundImage : bgImage}
    >
      <MDBox>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={values => {
            login(values);
          }}
        >
          {({errors, isValid}) => (
            <Form style={{display: 'flex', flexDirection: 'column', flex: 1}}>
              <FormikInput name={'email'} label={'Email address'} type={'email'} errors={errors} mb={4}/>
              <FormikInput name={'password'} label={'Password'} type={'password'} errors={errors}/>
              <MDBox textAlign="right">
                <MDTypography variant="button" color="text">
                  <MDTypography
                    component={Link}
                    to={ROUTES.FORGOT_PASSWORD}
                    variant="button"
                    color="dark"
                    fontWeight="regular"
                  >
                    Forgot Password?
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mt={10} mb={1} textAlign={"center"}>
                <MDButton
                  variant="contained"
                  color="primary"
                  loading={loading}
                  disabled={loading || !isValid}
                  sx={{width: pxToRem(280)}}
                  type='submit'
                >
                  Sign In
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </IllustrationLayout>
  );
}

export default observer(SignIn);
