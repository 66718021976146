import {observer} from "mobx-react";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {Box, Card, Grid} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../services/constants";
import {useParams} from "react-router-dom";
import DrawerRight from "../../../components/DrawerRight/DrawerRight";
import {money_fmt} from "../../../services/helpers";
import logo from "../../../assets/icons/dolovo-D.png";

export const OrderSummaryCard = observer((props) => {
  const {
    id,
    item,
    manufacturer,
    projectManager,
    project,
    status,
    orderTotal,
    location,
    is_order = false
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const handleViewOrder = (order) => {
    navigate(ROUTES.ADMIN_ORDER_MANAGEMENT_DETAIL(order.id))
  }

  const type = is_order ? 'Order': 'Cart'


  return (

    <Card sx={styles.orderContainer}>
      <MDBox sx={styles.orderHeader}>
        <MDBox sx={{display:"flex", flexDirection:"column"}}>
        <MDTypography sx={styles.orderNumber}>{`${type} #${id}`}</MDTypography>
          <MDTypography sx={styles.orderItemName}>{manufacturer.category_name}</MDTypography>
        </MDBox>
        {!params.id &&
          <MDTypography sx={{...styles.orderNumber, cursor: "pointer"}} onClick={() => handleViewOrder(props)}>View
            Order</MDTypography>}
        {params.id &&
          <MDBox sx={styles.orderStageContainer}>
            <MDTypography sx={styles.orderItemName}>Order Stage</MDTypography>
            <DrawerRight/>
          </MDBox>
        }


      </MDBox>
      <Grid container direction="row" justifyContent="space-between">

      <Grid container direction="row" justifyContent="space-between">
        <Grid item alignItems={"center"} md={2} sx={styles.orderDetails}>
          <Grid>
          <MDBox sx={{display:"flexa", alignItems:"center"}}>
          <MDTypography sx={styles.orderDetailTitle}>Manufacturer</MDTypography>
          <Box sx={styles.orderDetailItem}>
            <Box sx={styles.contLogo}>
              <Box component={"img"} src={manufacturer?.logo ? manufacturer?.logo : logo} alt={"logo"} sx={styles.logo}/></Box>
            <MDTypography sx={styles.orderItemDetail}>{manufacturer?.name}</MDTypography>
          </Box>
          </MDBox>
          </Grid>
        </Grid>
        <Grid item md={2} sx={styles.orderDetails}>
          <MDTypography sx={styles.orderDetailTitle}>Project Manager</MDTypography>
          <Box sx={styles.orderDetailItem}>
            <Box sx={styles.contLogo}>
              {projectManager.profile_picture !== null && <Box component={"img"} src={projectManager.profile_picture} alt={"logo"} sx={styles.logo}/>}

              {projectManager.profile_picture === null &&<Box component={"img"} src={logo} alt={"logo"} sx={styles.logo}/>}</Box>
            <MDTypography sx={styles.orderItemDetail}>{projectManager?.name}</MDTypography>

          </Box>
        </Grid>
        <Grid item md={2} sx={styles.orderDetails}>
          <MDTypography sx={styles.orderDetailTitle}>Project</MDTypography>
          <MDTypography sx={styles.orderItemDetail}>{project.name}</MDTypography>
        </Grid>
        <Grid item md={2} sx={styles.orderDetails}>
          <MDTypography sx={styles.orderDetailTitle}>Location</MDTypography>
          <Box sx={styles.orderDetailItem}>
            <LocationOnOutlinedIcon sx={styles.locationIcon}/>
            <MDTypography sx={styles.orderItemDetail}>{location}</MDTypography>
          </Box>
        </Grid>
        <Grid item md={2} sx={styles.orderDetails}>
          <MDTypography sx={styles.orderDetailTitle}>Status</MDTypography>
          <MDTypography sx={styles.orderItemDetail}>{status}</MDTypography>
        </Grid>
        <Grid item md={2} sx={styles.orderDetails}>
          <MDTypography sx={styles.orderDetailTitle}>Order Total</MDTypography>
          <MDTypography sx={styles.orderItemDetail}>{money_fmt(orderTotal)}</MDTypography>
        </Grid>
      </Grid>
      </Grid>
    </Card>

  )
})

const styles = {
  locationIcon: {
    width: pxToRem(20),
    height: pxToRem(20),
    color: "#0B7340"
  },
  orderContainer: {
    width: "100%",
    height: pxToRem(167),
    backgroundColor: "#fff",
    borderRadius: pxToRem(16),
    padding: pxToRem(13),
  },
  orderHeader: {
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    alignItems:"center",
    // width: "100%",
    pr: pxToRem(10)
  },
  orderDetailsContainer: {
    background: "#c6c6c6",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // width: "100%",
  },
  orderDetails: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderRight: "1px solid #DBDBDB",
    height: pxToRem(64),
    pl: pxToRem(24),
    pr: pxToRem(23),
    pt: pxToRem(10)

  },
  orderDetailItem: {
    display: "flex",
    gap: pxToRem(7),
    alignItems: "center"
  },
  orderNumber: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.1),
    color: "#0B7340"
  },
  orderStageContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: pxToRem(250)
  },
  orderItemName: {
    fontSize: pxToRem(20),
    fontWeight: 700,
    mb:"6px",
    lineHeight: pxToRem(24),
  },
  orderItemDetail: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    // width:"100px",
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.1),
    pt: pxToRem(4)
  },
  orderDetailTitle: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: pxToRem(14),
    pb: pxToRem(7),
  },
  orderStatusMessage: {
    pt: pxToRem(4),
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.1),
    color: "#6A686D"
  },
  logo: {

    width: "100%",
    height: "100%",
    // position:"relative",
    objectFit: "contain"

  },
  contLogo:{
    // padding: "4px",
    display: "flex",
    alignItems:"center",
    alignContent:"center",
    overflow:"hidden",
    minHeight:pxToRem(24),
    minWidth:pxToRem(24),
    maxHeight:pxToRem(24),
    borderRadius:"50%",
    maxWidth:pxToRem(24),
    background:"fff"


  }

}
