import {Grid} from "@mui/material";
import {Form, Formik} from "formik";
import FormikInput from "../../../../../components/FormikInput";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import {GeneralModal} from "../../../../../components/general-modal";
import * as React from "react";
import {observer} from "mobx-react";
import MDTypography from "../../../../../components/MDTypography";

export const ShareOrderModal = observer(({ manufacturer, handleClose, open, loading, handleShareQuote}) => {
  return (
    <GeneralModal title={'Share Cart'} open={open} handleClose={handleClose}>
      <Grid>
        <Formik
          initialValues={{
            manufacturer: null,
            requested_action: null,
            message: ''
          }}
          onSubmit={(values) => handleShareQuote(values)}
        >
          {(formik) => (
            <Form>
              <Grid container pl={2} pr={2} pt={2} spacing={2}>
                <Grid item xs={12} lg={12} mb={2}>
                  <MDTypography variant={'text'} >Sending to Vendor: {manufacturer?.name}</MDTypography>
                </Grid>
              </Grid>
              <Grid container pl={2} pr={2}>
                <Grid item xs={12} lg={12}>
                  <FormikInput
                    name={'message'}
                    type={"textarea"}
                    label={"Message (Optional)"}
                    rows={7}/>
                </Grid>
              </Grid>
              <MDBox borderTop={'1px solid #DBDBDB'} pr={2} pt={2} gap={2} display={'flex'}  justifyContent={'flex-end'}>
                <MDButton
                  variant="outlined"
                  color="secondary"
                  disabled={loading || !formik.isValid}
                  onClick={() => handleClose(formik)}
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant="contained"
                  color="secondary"
                  type='submit'
                  disabled={loading}
                  loading={loading}
                >
                  Share Cart
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </Grid>
    </GeneralModal>
  )
})
