
import {useEffect, useState} from "react";
import {observer} from "mobx-react";

// Components
import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Images and Theme functions
import pxToRem from "assets/theme/functions/pxToRem";
import {showMessage, useApi} from "../../../services/helpers";
import bgImage from "assets/images/background/background-login.jpg";
import {useStores} from "../../../models";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";

function UploadContentPage() {
  const api = useApi()
  const rootStore = useStores()
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const uploadNewBackgroundImage = () => {
    setConfirmModal(false)
    setLoading(true)
    api.updateBackgroundImage({image: file}).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Background image updated successfully', 'success')
        if (result?.image !== null) {
          rootStore.setBackgroundImage(result?.image)
        }
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const getCurrentBackgroundImage = () => {
    setLoading(true)
    api.getBackgroundImage().handle({
      onSuccess: (result) => { if (result.data?.image !== null) {
        rootStore.setBackgroundImage(result.data.image)
      }},
      errorMessage: 'Error getting background image',
      onFinally: () => setLoading(false)
    })
  }


  useEffect(() => {
    getCurrentBackgroundImage()
  }, [])

  return (
    <AdminLayout
      title={'Upload Content'}
      header={
        <MDBox display='flex' alignItems='center' gap={pxToRem(20)} flex={1}>
          <MDButton
            variant="outlined"
            color="secondary"
            type='button'
            sx={{width: pxToRem(200)}}
            onClick={() => setFile(null)}
            disabled={file === null || loading}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            sx={{width: pxToRem(200)}}
            onClick={() => setConfirmModal(true)}
            loading={loading}
            disabled={file === null}
          >
            Save
          </MDButton>
        </MDBox>
      }
    >
      <ConfirmDialogModal
        title={'Update background images'}
        description={'Do you want to update the background image of the login flow pages?'}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={confirmModal}
        handleClose={() => setConfirmModal(false)}
        handleConfirm={() => uploadNewBackgroundImage()}
      />
      <MDBox mr='auto' width={pxToRem(433)} textAlign={'center'}>

        {!file
          ? (
            <MDBox
              border={'2px dashed gray'}
              height={pxToRem(581)}
              display='flex'
              justifyContent='center'
              flexDirection='column'
              gap={pxToRem(10)}
              alignItems='center'
              sx={{ backgroundImage: `url(${rootStore.backgroundImage ? rootStore.backgroundImage : bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
            >
            </MDBox>
          )
          : (
            <MDBox
              height={pxToRem(581)}
              width='inherit'
              sx={{ backgroundImage: `url(${URL.createObjectURL(file)})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
            >
            </MDBox>
          )
        }

        <input
          type='file'
          accept='image/*'
          id={'input_file'}
          onChange={(e) => setFile(e.target.files[0])}
          style={{display: 'none'}}
        />

        <label htmlFor='input_file'>
          <MDTypography
            variant='h6'
            sx={({palette: {tertiary}}) => ({
              color: tertiary.main,
              cursor: 'pointer',
              mt: pxToRem(18),
              textAlign: 'center',
            })}
          >
            Upload Photo
          </MDTypography>
        </label>
        <MDTypography variant={'text'} textAlign={'center'} mx={'auto'}>
          Recommended size 760 x 1024 px
        </MDTypography>
      </MDBox>

    </AdminLayout>
  );
}

export default observer(UploadContentPage);
