import AdminLayout from "../../../components/AdminLayout";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {useEffect, useState} from "react";

import {ROLES, ROUTES} from "../../../services/constants";
import {useNavigate} from "react-router-dom";
import {UserModalForm} from "./userModalForm";

function CreateUserAccount() {
  const navigate = useNavigate()
  const [role, setRole] = useState('')
  const [selectedType, setSelectedType] = useState(null)
  const [loading, setLoading] = useState(false);

  const buttonTypes = [
    {...ROLES.SUB_CONTRACTOR_REP, style: 'success'},
    {...ROLES.PROJECT_MANAGER, style: 'secondaryWhite'},
    {...ROLES.SUPPLY_MANAGER, style: 'primary'},
    {...ROLES.ADMIN, style: 'tertiary'},
  ]

  useEffect(() => {
    switch (selectedType?.id) {
      case ROLES.ADMIN.id:
        setRole(ROLES.ADMIN.name)
        break
      case ROLES.PROJECT_MANAGER.id:
        setRole(ROLES.PROJECT_MANAGER.name)
        break
      case ROLES.SUPPLY_MANAGER.id:
        setRole(ROLES.SUPPLY_MANAGER.name)
        break
      case ROLES.SUB_CONTRACTOR_REP.id:
        setRole(ROLES.SUB_CONTRACTOR_REP.name)
        break
      default:
        break
    }

  }, [selectedType])

  const renderButtons = (types) => {
    return (
      <MDButton
        key={types.id}
        variant="contained"
        color={types.style}
        sx={{width: pxToRem(300)}}
        onClick={() => setSelectedType(types)}
      >
        {types.name}
      </MDButton>
    )
  }

  return (
    <AdminLayout
      title={'Create User Account'}
      showCard
      cardWidth={'500px'}
    >
      <MDTypography
        variant={'h4'}>{selectedType === null ? 'Select User Type' : `Add new ${selectedType.name}`}</MDTypography>
      <MDBox bgColor="dark" opacity={0.1} p={0.09}/>
      <MDBox
        p={selectedType === null ? 10 : 2}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        minHeight={360}
      >
        {selectedType === null && buttonTypes.map(type => renderButtons(type))}
        {selectedType !== null && (
          <MDBox>
            <MDTypography variant={'text'} color={'title'}>
              The new user will be added to your organization and receive instructions to log in their new account.
            </MDTypography>
            <UserModalForm
              modalLayout={false}
              item={null}
              open={selectedType !== null}
              onClose={() => setSelectedType(null)}
              setLoading={setLoading}
              loading={loading}
              userRole={role}
              onSave={() => navigate(ROUTES.ADMIN_ALL_USERS)}/>
          </MDBox>
        )}
      </MDBox>
    </AdminLayout>
  );
}

export default CreateUserAccount;
