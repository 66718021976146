import {useState} from "react";
import {observer} from "mobx-react";

// Components
import MDButton from "components/MDButton";
import AdminLayout from "components/AdminLayout";

//Utils
import {truncate, useApi, useIsMobile} from "../../../services/helpers";
import {AddManagerModal} from "./components/AddManagerModal";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {popoverActionsIcon} from "../../../components/PopoverActionsIcon";
import DataTable from "components/DataTable";
import {ProjectModalForm} from "./components/ProjectModalForm";
import {PopoverDeleteText} from "../../../components/PopoverActions/PopoverEditDelete";
import {PopoverActions, PopoverItem} from "../../../components/PopoverActions";
import SearchBar from "../../../components/SearchBar";
import MDBox from "../../../components/MDBox";
import {useApiMessages} from "../../../services/api/api-messages";
import {UpdateSubContractorModal} from "./components/UpdateSubContractorModal";
import {SupplierModalBlackList} from "./components/AddSupplierModal";


export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name"},
    {Header: "Address", accessor: "address"},
    {Header: "Company", accessor: "company__name"},
    {Header: "Region", accessor: "region__name"},
    {Header: "Brand", accessor: "brand__name"},
    {Header: "Sub-Contractors", accessor: "sub_contractors", disableOrdering: true},
    {Header: "Project Managers", accessor: "project_managers_names", disableOrdering: true},
    {Header: "Excluded Supply Managers", accessor: "excluded_supply_managers_names", disableOrdering: true},
    {Header: "", accessor: "actions", align: "left", disableOrdering: true, width: pxToRem(5)}
  ],
  rows: [],
};


function ManageProjectsPage() {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAddManagerModal, setOpenAddManagerModal] = useState(false)
  const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false)
  const [openAddSubContractorModal, setOpenAddSubContractorModal] = useState(false)
  const [openProjectModalForm, setOpenProjectModalForm] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const messages = useApiMessages('project', 'projects')
  const isMobile = useIsMobile()
  const renderTableRow = (item) => {
    const contract_reps = item?.sub_contract_reps?.map(e => e.name).join(', ')
    item.company__name = item.company.name
    item.region__name = item.region.name
    item.brand__name = item.brand.name
    item.project_managers_names = truncate(item.project_managers_names, 30)
    item.sub_contractors = truncate(contract_reps, 30)
    item.actions = (popoverActionsIcon(item, setAnchorEl, setOpenPopover, setSelectedItem))
    return item
  }

  const getProjects = (searchData = '', page = 1, ordering = paginationData.ordering, page_size = 25) => {

    setLoading(true)
    api.getProjects({search: searchData, page, ordering}).handle( {
          onSuccess: (result) => {
            const {count, results} = result.data
            const tmp = {...dataTableModel}
            tmp.rows = results.map(e => renderTableRow(e, setAnchorEl, setOpenPopover, setSelectedItem))
            setDatatable(tmp)
            setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length, ordering}))
          },
          errorMessage: messages.list.error,
          onFinally: () => setLoading(false)
        }
    )
  }


  const onDelete = () => {
    setLoading(true)
    api.deleteProject(selectedItem?.id).handle({
          successMessage: messages.delete.success,
          onSuccess: () => { getProjects(''); onClose() },
          errorMessage: messages.delete.error,
          onFinally: () => setLoading(false)
        }
    )
  }

  const onClose = () => {
    setOpenPopover(false)
    setSelectedItem(null)
    setOpenConfirmModal(false)
    setOpenProjectModalForm(false)
  }


  return (
    <AdminLayout
      title={'Manage Projects'}
      header={
        <MDBox display={'flex'} flex={1} flexDirection={'row'} alignItems={'center'}>
          <SearchBar loading={loading} search={getProjects} setSearchQuery={setSearchQuery}/>
          <MDButton variant="contained" color="secondary" type='button' onClick={() => setOpenProjectModalForm(true)}
                    sx={isMobile ? { fontSize:"14px", flexWrap:"nowrap", width:"178px", alignContent:"center", pb:"6px"}:{}}>
            Add New Project
          </MDButton>
        </MDBox>
      }
    >

      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getProjects}
        searchQuery={searchQuery}
        onPageChange={page => {
          getProjects(searchQuery, page)
          setPaginationData(value => ({...value, currentPage: page}))
        }}
      />

      {openProjectModalForm && (
        <ProjectModalForm
          item={selectedItem}
          open={openProjectModalForm}
          onClose={() => setOpenProjectModalForm(false)}
          setLoading={setLoading}
          loading={loading}
          onSave={() => {
            onClose()
            getProjects(searchQuery);
          }}/>
      )}

      {openAddManagerModal && (
        <AddManagerModal
          open={openAddManagerModal}
          item={selectedItem}
          loading={loading}
          onUpdate={() => {
            getProjects(searchQuery)
          }}
          onClose={() => {
            setOpenAddManagerModal(false)
            setOpenPopover(false)
            getProjects(searchQuery)
          }}/>
      )}

      {openAddSupplierModal && (
        <SupplierModalBlackList
          open={openAddSupplierModal}
          item={selectedItem}
          loading={loading}
          onUpdate={() => {
            getProjects(searchQuery)
          }}
          onClose={() => {
            setOpenAddSupplierModal(false)
            setOpenPopover(false)
            getProjects(searchQuery)
          }}/>
      )}

      {openAddSubContractorModal && (
        <UpdateSubContractorModal
          open={openAddSubContractorModal}
          item={selectedItem}
          loading={loading}
          onUpdate={() => {
            getProjects(searchQuery)
          }}
          onClose={() => {
            setOpenAddSubContractorModal(false)
            setOpenPopover(false)
            getProjects(searchQuery)
          }}/>
      )}

      <ConfirmDialogModal
        title={'Do you want to delete this Project?'}
        description={`${selectedItem?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openConfirmModal}
        handleClose={() => onClose()}
        handleConfirm={() => onDelete()}
      />

      <PopoverActions open={openPopover} onClose={onClose} anchorEl={anchorEl}>
        <PopoverItem label={"Edit"} onClick={() => setOpenProjectModalForm(true)}/>
        <PopoverItem label={"Update Project Managers"} onClick={() => setOpenAddManagerModal(true)}/>
        <PopoverItem label={"Manage Sub-Contractors"} onClick={() => setOpenAddSubContractorModal(true)}/>
        <PopoverItem label={"Manage Supply Managers"} onClick={() => setOpenAddSupplierModal(true)}/>
        <PopoverItem label={"Delete"} onClick={() => setOpenConfirmModal(true)} sx={PopoverDeleteText}/>
      </PopoverActions>

    </AdminLayout>
  );
}

export default observer(ManageProjectsPage);
