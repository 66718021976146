/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";

// Components
import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Utils
import {formatNumberToCurrency, showMessage, useApi} from "services/helpers";
import {apiMessages, useApiMessages} from "../../../../services/api/api-messages";


// Styles
import {
  ProductImage,
  ProductBrandText,
  ProductDetailBox,
  ProductPriceText, ProductImageContainer
} from "./../styles";
import {ROUTES} from "../../../../services/constants";
import {
  Container,
  FilterItem,
  FilterItemModified,
  FilterText, Input,
  InputContainer
} from "../../../../components/FiltersBar/styles";
import {Autocomplete, Grid, InputAdornment, TextField} from "@mui/material";
import search from "../../../../assets/icons/search.svg";
import Pagination from "../../../../components/DataTable/Pagination/Pagination";
import {wrapApiCall} from "../../../../services/helpers_ts";

function ProductOrderListPage({ showTotalEntries = true}) {
  const api = useApi()
  const navigate = useNavigate();
  const {idp: idProject, ido: orderId} = useParams();
  const [loading, setLoading] = useState(false);
  const [Products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const messages = useApiMessages('product', 'products')



  const getProducts = (data) => {
    setLoading(true)
    wrapApiCall(api.getProjectProducts(idProject, {...data, order_id: orderId}), {
      onSuccess: (result) => {
        const {count, results} = result.data
        setProducts(results)
        setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length}))
      },
      errorMessage: messages.list.error
    })
  }

  useEffect(() => {
    getProducts({search: searchQuery});
  }, [])

  const getFilters = ()=>{
    let filters =  {search: searchQuery, page: 1}
    setPaginationData(value => ({...value, currentPage: filters.page}))

    return filters
  }

  useEffect(() => {
   getProducts(getFilters())
  }, [searchQuery])


  return (
    <AdminLayout
      title={'Order Management'}
    >
      <MDBox width='100%'>
        <MDBox sx={Container}>
          <MDBox sx={FilterItemModified}>
          </MDBox>
          <MDBox sx={FilterItem}>
          </MDBox>
          <MDBox sx={InputContainer}>
            <TextField
              sx={Input}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start" sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={'Search for a product'}
            />
          </MDBox>
        </MDBox>
        <Grid container spacing={5}>
          {Products?.length > 0  ? Products.map((product, index) => (
              <Grid item xs={12} md={6} lg={3} key={`product-key-${index}`}
                onClick={() => navigate(ROUTES.ADMIN_PRODUCT_ORDER_DETAIL(idProject, product.id, orderId))}>
                <MDBox sx={ProductImageContainer}>
                  <MDBox
                    component={'img'}
                    src={product.main_img}
                    sx={ProductImage}
                  />
                </MDBox>

                <MDBox sx={ProductDetailBox}>
                  <MDTypography variant='button' sx={ProductBrandText}>{product.manufacturer}</MDTypography>
                  <MDTypography variant='h3'  sx={{width: '100%', wordWrap: 'break-word'}}>{product.name}</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Model {product.model}</MDTypography>
                  <MDTypography variant='h3' sx={ProductPriceText}>
                    {formatNumberToCurrency(product.price)}
                    <MDTypography variant='button' fontWeight='regular'>/unit</MDTypography>
                  </MDTypography>
                </MDBox>
              </Grid>
            )) :
            <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No items found</MDTypography>
            </MDBox>

          }
        </Grid>
        {showTotalEntries && <Grid container mt={5} >
          <Grid item>
            <MDBox m={2} sx={{color: '#666666', fontSize: 17, width: 300}}>Showing <span
              style={{color: '#000000'}}>{paginationData.itemsPerPage}</span> from <span
              style={{color: '#000000'}}>{paginationData.counts}</span> data</MDBox>
          </Grid>
          <Grid item ml={'auto'}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={24}
              onPageChange={page => {
                // page:page is to ensure that we have the current value in the filters
                getProducts({...getFilters(), page:page})
                setPaginationData(value => ({...value, currentPage: page}))
              }}
            />
          </Grid>
        </Grid>}
      </MDBox>
    </AdminLayout>
  );
}

export default observer(ProductOrderListPage);

