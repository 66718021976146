import pxToRem from "assets/theme/functions/pxToRem";
import check from 'assets/icons/check.svg';

export const Container = ({palette: {white, table}}) => ({
  background: white.main,
  border: `1px solid ${table.border}`,
  borderRadius: '10px',
  overflow: 'auto',
  paddingBottom: pxToRem(28),
  width: pxToRem(870),
})

export const Title = ({palette: {table}}) => ({
  borderBottom: `1px solid ${table.border}`,
  fontSize: pxToRem(16),
  fontWeight: 400,
  letterSpacing: pxToRem(0.5),
  lineHeight: pxToRem(24),
  pb: pxToRem(20),
  pl: pxToRem(24),
  pt: pxToRem(25),
  width: '100%',
})

export const Subtitle = ({palette: {table}}) => ({
  fontSize: pxToRem(16),
  fontWeight: 400,
  letterSpacing: pxToRem(0.5),
  lineHeight: pxToRem(24),
  pb: pxToRem(20),
  // pl: pxToRem(24),
  pt: pxToRem(25),
  width: '100%',
})

export const FormContainer = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(4),
  mt: pxToRem(24),
})

export const FilecardContainer = ({palette: {white, table}}) => ({
  alignItems: 'center',
  background: 'white',
  border: `2px solid ${table.border}`,
  borderRadius: '5px',
  display: 'flex',
  height: pxToRem(48),
  justifyContent: 'space-between',
  my: pxToRem(10),
  padding: '5px',
  width: '100%',
})


export const ImagecardWrapContainer = ({palette: {white, table}}) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap:'5%',
  flexWrap:'wrap',
  lineHeight: 0
})

export const ImagecardContainer = ({palette: {white, table}}) => ({
  display: 'flex',
  border: `2px solid ${table.border}`,
  // boxSizing:'border-box',
  borderRadius: '5px',
  my: pxToRem(10),
  position: 'relative',

})

export const ImageCardImage = {
  left:0,
  top:0,
  width: '150px',
  height: '150px',
  objectFit: 'cover',
  // boxSizing:'border-box',
  borderRadius: '5px',
  cursor: 'pointer'
}

export const ImagecardDeleteIcon = ({palette: {icons}}) => ({
  background: 'white',
  borderRadius: '14px',
  right:'-5px',
  top:'-5px',
  position: 'absolute',
  cursor: 'pointer',
  color: icons.remove,
})

export const FormSection = () => ({
  px: pxToRem(44),
})

export const FieldsContainer = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(4),
  width: pxToRem(355),
})

export const FileFieldsContainer = () => ({
  ...FieldsContainer(),
  gap: pxToRem(22),
})

export const BooleanFieldsContainer = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(16),
  mt: pxToRem(12),
  width: pxToRem(355)
})

export const BooleanTitle = () => ({
  fontSize: pxToRem(12),
  fontWeight: 400,
})

export const BooleanBox = () => ({
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(8),
})

export const CheckboxItem = ({palette: {secondary, table}}) => ({
  '&.MuiCheckbox-root': {
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    border: `1px solid ${table.border}`,
    borderRadius: '4px',
    height: pxToRem(16),
    width: pxToRem(16),
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundImage: `url(${check}),linear-gradient(195deg, ${secondary.main},${secondary.main})`,
      border: 'none',
      borderRadius: '4px',
      color: secondary.main,
      height: pxToRem(16),
      width: pxToRem(16),
    },
  },
})

export const FilenameText = () => ({
  fontSize: pxToRem(14),
  fontWeight: 400,
  // pt: pxToRem(3),
  width: '70%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const CheckboxValue = () => ({
  fontSize: pxToRem(14),
  fontWeight: 400,
  pt: pxToRem(3),
})

export const AddFilterText = ({palette: {info}}) => ({
  color: info.main,
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 400,
  justifyContent: 'flex-end',
})

export const AddFilterTextEmpty = ({palette: {info}}) => ({
  color: info.main,
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 400,
  justifyContent: 'flex-start',
  ml: 4,
})

export const ActionsBox = () => ({
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(11),
  justifyContent: 'flex-start',
  px: pxToRem(44),
})

export const CompanyPricingSection = (theme) => ({
  ...Title(theme),
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(16),
})

export const CompanyPricingTitle = (theme) => ({
  ...Title(theme),
  border: 'none',
  pb: 0,
  pl: 0,
  pt: 0,
  width: 'fit-content'
})

export const AddButton = ({palette: {icons}}) => ({
  cursor: 'pointer',
  color: icons.add,
})


export const SectionTitle = (theme) => ({
  ...Title(theme),
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(16),
})

export const SectionTitleText = (theme) => ({
  ...Title(theme),
  border: 'none',
  pb: 0,
  pl: 0,
  pt: 0,
  width: 'fit-content'
})


export const RemoveButton = ({palette: {icons}}) => ({
  cursor: 'pointer',
  color: icons.remove,
})
