/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {observer} from "mobx-react";


// Components
import AdminLayout from "components/AdminLayout";
import MDButton from "components/MDButton";

import {useApi, useIsMobile} from "../../../services/helpers";
import {useEffect, useState} from "react";
import MDBox from "../../../components/MDBox";
import SearchBar from "../../../components/SearchBar";
import {dataTableModel, renderTableRow} from "./utils";
import DataTable from "../../../components/DataTable";
import {PopoverDeleteText} from "../../../components/PopoverActions/PopoverEditDelete";
import {SubContractorsModalForm} from "./SubContractorsModalForm";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";
import {PopoverActions, PopoverItem} from "../../../components/PopoverActions";
import {AddSubContractorModal} from "./addSubContractorModal";
import {useApiMessages} from "../../../services/api/api-messages";
import {wrapApiCall} from "../../../services/helpers_ts";

//Styles


function ManageSubContractorsPage() {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAddManufactureModal, setOpenAddManufacturerModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [openAssignSubContractorModal, setOpenAssignSubContractorModal] = useState(false)
  const messages = useApiMessages('manufacturer', 'manufacturers')
  const isMobile = useIsMobile()

  const getSubContractors = (search = '', page = 1, ordering = paginationData.ordering, page_size = 10) => {
    setLoading(true)
    wrapApiCall(api.getSubContractors({search, page, ordering, page_size}), {
      onSuccess: (result) => {
        const {count, results} = result.data
        const tmp = {...dataTableModel}
        tmp.rows = results.map(e => renderTableRow(e, setAnchorEl, setOpenPopover, setSelectedItem))
        setDatatable(tmp)
        setPaginationData((value) => ({
          ...value,
          counts: count,
          itemsPerPage: results.length,
          currentPage: page,
          ordering: ordering
        }))
      },
      errorMessage: messages.list.error,
      onFinally: () => setLoading(false)
    })
  }

  const onClose = () => {
    setOpenPopover(false)
    setSelectedItem(null)
    setOpenConfirmModal(false)
    setOpenAddManufacturerModal(false)
  }

  const onDelete = () => {
    setLoading(true)
    wrapApiCall(api.deleteSubContractors(selectedItem?.id), {
      successMessage: messages.delete.success,
      onSuccess: () => { getSubContractors() ; onClose() } ,
      errorMessage: messages.delete.error,
      onFinally: () => setLoading(false)
    })
  }


  useEffect(() => {
    getSubContractors()
  }, [])

  return (
    <AdminLayout
      title={'Manage Sub-Contractors'}
      header={
        <MDBox display={'flex'} flex={1} flexDirection={'row'} alignItems={'center'}>
          <SearchBar loading={loading} search={getSubContractors} setSearchQuery={setSearchQuery}/>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            onClick={() => setOpenAddManufacturerModal(true)}
            sx={isMobile ? { fontSize:"14px", flexWrap:"nowrap", width:"156px", alignContent:"center", pb:"6px", pl:"14px", pr:"14px"}:{}}
          >
            Add Sub-Contractor
          </MDButton>
        </MDBox>
      }
    >
      {openAddManufactureModal && (
        <SubContractorsModalForm
          item={selectedItem}
          open={openAddManufactureModal}
          onClose={() => setOpenAddManufacturerModal(false)}
          setLoading={setLoading}
          loading={loading}
          onSave={() => {
            onClose()
            getSubContractors(searchQuery);
          }}/>
      )}
      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getSubContractors}
        searchQuery={searchQuery}
        onPageChange={page => {
          getSubContractors(searchQuery, page)
          setPaginationData(value => ({...value, currentPage: page}))
        }}
      />

      <ConfirmDialogModal
        title={'Do you want to delete this Sub-Contractor?'}
        description={`${selectedItem?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openConfirmModal}
        handleClose={() => onClose()}
        handleConfirm={() => onDelete()}
      />

      {openAssignSubContractorModal && (
        <AddSubContractorModal
          open={openAssignSubContractorModal}
          item={selectedItem}
          loading={loading}
          onUpdate={() => {
            getSubContractors(searchQuery)
          }}
          onClose={() => {
            setOpenAssignSubContractorModal(false)
            setOpenPopover(false)
            getSubContractors()
          }}/>
      )}


      <PopoverActions open={openPopover} onClose={onClose} anchorEl={anchorEl}>
        <PopoverItem label={"Edit"} onClick={() => setOpenAddManufacturerModal(true)}/>
        <PopoverItem label={"Update SubContractors"} onClick={() => setOpenAssignSubContractorModal(true)}/>
        <PopoverItem label={"Delete"} onClick={() => setOpenConfirmModal(true)} sx={PopoverDeleteText}/>
      </PopoverActions>

    </AdminLayout>
  );
}

export default observer(ManageSubContractorsPage);
