import { Link } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { GeneralModal } from "components/general-modal";
import { ImageCardImage } from "pages/admin/manage-products/manage_product/styles";
import { ImagecardContainer } from "pages/admin/manage-products/manage_product/styles";
import { useState } from "react";

const PodDetails = ({ selectedPod, openModal, handleClose }) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <GeneralModal
      title={`POD ${selectedPod.index} Details`}
      open={openModal}
      handleClose={handleClose}
      fullHeight
    >
      <GeneralModal open={openImageModal} handleClose={() => setOpenImageModal(false)} fullHeight>
        <MDBox
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 500,
            height: 600,
            overflow: "hidden",
            borderRadius: 4,
          }}
        >
          <img
            style={{ border: 1, height: "100%", width: "100%", objectFit: "contain" }}
            alt={selectedImage?.name}
            src={selectedImage?.url || selectedImage?.image}
          />
        </MDBox>
      </GeneralModal>
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "550px",
          maxHeight: "70vh",
          overflowY: "auto",
          paddingY: 4,
        }}
      >
        {/* DOCS */}
        {selectedPod.docs.length > 0 && (
          <MDBox
            sx={[
              {
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                maxHeight: 130,
                overflowX: "hidden",
                overflowY: "auto",
                paddingX: 4,
                paddingBottom: 2,
                marginBottom: 2,
              },
            ]}
          >
            {selectedPod.docs?.map((doc, index) => (
              <MDBox
                key={`doc-${index}`}
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <MDTypography
                  component={Link}
                  variant={"subtitle2"}
                  key={doc.document_file}
                  href={doc.document_file}
                  target={"_blank"}
                  rel={"noreferrer"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: colors.primary.main,
                    textDecoration: "underline !important",
                  }}
                >
                  {doc.doc_file_name || "document"}
                </MDTypography>
                <MDButton
                  variant="text"
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: "-24px",
                  }}
                  href={doc.document_file}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  View
                </MDButton>
              </MDBox>
            ))}
          </MDBox>
        )}

        {/* IMAGES */}
        {selectedPod.images.length > 0 && (
          <MDBox
            sx={[
              {
                display: "flex",
                gap: 1.5,
                paddingBottom: 1,
                marginBottom: 3,
                marginX: 4,
                overflowY: "hidden",
                overflowX: "auto",
              },
            ]}
          >
            {selectedPod.images?.map((image, index) => {
              return (
                <MDBox sx={[ImagecardContainer, { margin: 0 }]} key={`key-${index}`}>
                  <img
                    style={ImageCardImage}
                    alt={image.image?.name}
                    src={image?.url || image.image}
                    onClick={() => {
                      console.log(image);
                      setOpenImageModal(true);
                      setSelectedImage(image);
                    }}
                  />
                </MDBox>
              );
            })}
          </MDBox>
        )}

        <MDBox sx={{ marginX: 4, marginBottom: 2 }}>
          <MDInput
            disabled={true}
            multiline
            rows={4}
            name="pod_note"
            label="Note for Partial Fulfillment"
            value={selectedPod.pod_note}
            sx={{
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                fontWeight: "bold",
                color: "#000",
                "-webkit-text-fill-color": "#000",
              },
              backgroundColor: "white !important",
              pointerEvents: "none",
              width: "100%",
            }}
          />
        </MDBox>
        <MDButton variant="contained" color="primary" onClick={handleClose} sx={{ marginX: 4 }}>
          Go Back
        </MDButton>
      </MDBox>
    </GeneralModal>
  );
};

export default PodDetails;
