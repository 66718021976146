import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {Checkbox, Icon} from "@mui/material";
import FormikInput from "../../../../components/FormikInput";
import {getIn} from "formik";
import {useApi} from "../../../../services/helpers";
import {useEffect, useState} from "react";
import {
  AddButton,
  CompanyPricingSection,
  CompanyPricingTitle,
  RemoveButton,
  SectionTitleText
} from "./styles";

import {
  BooleanBox,
  BooleanFieldsContainer,
  BooleanTitle,
  CheckboxItem,
  CheckboxValue,
  FieldsContainer,
  FormSection
} from "./styles";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import {CUSTOM_PRICING} from "./data";

const debugBorders = {}

export const CustomPricingDetailForm = ({is_default, value, varPath, formik}) => {
  const subtitles = is_default ? 'Default' : 'Company/Region'

  return (<>
    <MDBox sx={FieldsContainer}>
      <FormikInput
        label={`${subtitles} Part Price`}
        name={`${varPath}.part_price`}
        value={value?.part_price}
        errors={formik.errors}
      />
      {/**/}
      <MDBox sx={BooleanFieldsContainer}>
        <MDTypography sx={BooleanTitle}>Is shipping included?</MDTypography>
        <MDBox sx={BooleanBox}>
          <Checkbox sx={CheckboxItem}
                    checked={value?.includes_shipping}
                    onChange={(e) => {
                      formik.setFieldValue(`${varPath}.includes_shipping`, true)
                      formik.setFieldValue(`${varPath}.shipping_price`, 0)
                      is_default && formik.setFieldValue(`shipping_tax_code`, '')
                    }}
          />
          <MDTypography sx={CheckboxValue}>Yes</MDTypography>
        </MDBox>
        <MDBox sx={BooleanBox}>
          <Checkbox sx={CheckboxItem}
                    checked={!value?.includes_shipping}
                    onChange={(e) => formik.setFieldValue(`${varPath}.includes_shipping`, false)}
          />
          <MDTypography sx={CheckboxValue}>No</MDTypography>
        </MDBox>
        {!value?.includes_shipping && <>
          <MDBox ml={pxToRem(24)}>
            <FormikInput
              label={`${subtitles} Shipping Price`}
              name={`${varPath}.shipping_price`}
              value={value?.shipping_tax_code}
              errors={formik.errors}
            />
          </MDBox>
        </>}
      </MDBox>
      {/**/}
    </MDBox>
    <MDBox sx={BooleanFieldsContainer}>
      <MDTypography sx={BooleanTitle}>Does the product offer installation?</MDTypography>
      <MDBox sx={BooleanBox}>
        <Checkbox sx={CheckboxItem}
                  checked={value?.includes_installation}
                  onChange={(e) => formik.setFieldValue(`${varPath}.includes_installation`, true)}
        />
        <MDTypography sx={CheckboxValue}>Yes</MDTypography>
      </MDBox>
      <MDBox sx={BooleanBox}>
        <Checkbox sx={CheckboxItem}
          checked={!value?.includes_installation}
          onChange={(e) => {
            formik.setFieldValue(`${varPath}.includes_installation`, false)
            formik.setFieldValue(`${varPath}.installation_price`, 0)
            is_default && formik.setFieldValue(`installation_tax_code`, '')
            formik.setFieldValue(`${varPath}.installation_is_optional`,false)
          }}
        />
        <MDTypography sx={CheckboxValue}>No</MDTypography>
      </MDBox>
      {value?.includes_installation && <>
        <MDBox ml={pxToRem(24)}>
          <FormikInput
            label={`${subtitles} Installation Price`}
            name={`${varPath}.installation_price`}
            value={value.installation_price}
            errors={formik.errors}
          />
        </MDBox>
        { is_default &&
          <MDBox ml={pxToRem(24)}>
            <FormikInput
              label={'Installation Tax Code'}
              name={`installation_tax_code`}
              value={formik?.values.installation_tax_code}
              errors={formik.errors}
            />
          </MDBox>
        }
        <MDBox sx={BooleanFieldsContainer} ml={pxToRem(40)} mt={`0 !important`}>
          <MDTypography sx={BooleanTitle}>Is installation optional?</MDTypography>
          <MDBox sx={BooleanBox}>
            <Checkbox sx={CheckboxItem}
                      checked={value?.installation_is_optional}
                      onChange={(e) => formik.setFieldValue(`${varPath}.installation_is_optional`, true)}
            />
            <MDTypography sx={CheckboxValue}>Yes</MDTypography>
          </MDBox>
          <MDBox sx={BooleanBox}>
            <Checkbox sx={CheckboxItem}
                      checked={!value?.installation_is_optional}
                      onChange={(e) => formik.setFieldValue(`${varPath}.installation_is_optional`, false)}
            />
            <MDTypography sx={CheckboxValue}>No</MDTypography>
          </MDBox>
        </MDBox>
      </>}
    </MDBox>
  </>)
}

export const CustomPricingInnerForm = ({parentPath, values, setFieldValue, formik, index, custom_pricing, pricingProps}) => {
  const mapPath = `${parentPath}.${index}`

  const currentCompany = getIn(values, `${parentPath}.${index}.company`)
  const currentRegion = getIn(values, `${parentPath}.${index}.region`)

  const api = useApi()

  const [brands, setBrands] = useState([])
  const [regions, setRegions] = useState([])
  const [companies, setCompanies] = useState([])

  const getCompanies = ({search}) => {
    api.getCompanies({search}).then((result) => {
      if (result.kind === 'ok') {
        setCompanies(result.data.results)
      }
    })
  }

  const getBrands = (data) => {
    api.getBrands(data).then((result) => {
      if (result.kind === 'ok') {
        setBrands(result.data.results)
      }
    })
  }

  const getRegions = (data) => {
    api.getRegions(data).then((result) => {
      if (result.kind === 'ok') {
        setRegions(result.data.results)
      }
    })
  }

  useEffect(() => {
    getCompanies({search: ''})

    if (currentCompany?.id) {
      getRegions({search: "", company_id: currentCompany?.id})
    }

    if (currentRegion?.id) {
      getBrands({search: "", region_id: currentRegion?.id})
    }
  },[])


  return (
    <MDBox display={"flex"} flexDirection={"column"} sx={{px: 3, ...debugBorders}}>
      <MDTypography variant={"h4"} component={"h4"}>Custom pricing #{index + 1}</MDTypography>
      {/*########################################*/}
      {/*########################################*/}
      {/*########################################*/}
      {/*########################################*/}


      <MDBox sx={FormSection} mt={pxToRem(22)}>
        <MDBox sx={FieldsContainer}>
          <FormikInput
            type={"autocomplete"}
            value={custom_pricing.company}
            fieldName={`${mapPath}.company`}
            label={"Company"}
            options={companies}
            accessKey={"name"}
            onInputChange={(value) => {
              getCompanies({search: value})
            }}
            onChange={(value, fieldName) => {
              setFieldValue(fieldName, value)
              setFieldValue(`${mapPath}.region`, null)
              setFieldValue(`${mapPath}.brand`, null)
              getRegions({search: "", company_id: value?.id ? value.id : null})
            }}/>

          <FormikInput
            type={"autocomplete"}
            value={custom_pricing.region}
            fieldName={`${mapPath}.region`}
            label={"Region"}
            options={regions}
            onInputChange={(value) => {
              getRegions({search: value})
            }}
            onChange={(value, fieldName) => {
              setFieldValue(fieldName, value)
              setFieldValue(`${mapPath}.brand`, null)
              getBrands({search: "", region_id: value?.id ? value?.id : null})
            }}/>

          {/*<FormikInput*/}
          {/*  type={"autocomplete"}*/}
          {/*  value={custom_pricing.brand}*/}
          {/*  fieldName={`${mapPath}.brand`}*/}
          {/*  label={"Brand"}*/}
          {/*  options={brands}*/}
          {/*  onInputChange={(value) => {*/}
          {/*    getBrands({search: value})*/}
          {/*  }}*/}
          {/*  onChange={(value, fieldName) => {*/}
          {/*    setFieldValue(fieldName, value)*/}
          {/*  }}*/}
          {/*/>*/}
        </MDBox>

        <CustomPricingDetailForm
          is_default={false}
          value={custom_pricing}
          varPath={mapPath}
          formik={formik}
        />
      </MDBox>


      {/*########################################*/}
      {/*########################################*/}
      {/*########################################*/}
      {/*########################################*/}
      <MDBox sx={{display: 'flex', alignItems: "center", flexDirection: "row", justifyContent: "start",}}>
        <MDTypography variant={"h4"} component={"h4"}>Delete pricing #{index + 1}</MDTypography>
        <Icon sx={(th) => ({...RemoveButton(th), ml: 2})} fontSize='medium'
              onClick={() => pricingProps.remove(index)}>
          remove_circle
        </Icon>
      </MDBox>

    </MDBox>
  )

}
export const CustomPricingForm = ({pricingProps, values, setFieldValue, formik, index}) => {
  const parentPath = pricingProps.name
  const valuesArray = getIn(values, parentPath)

  return (
    <MDBox
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'start'}
      sx={{width: "820px", mb: 3, ...debugBorders}}
      key={'custom_pricing_' + index}
    >
      <MDBox sx={CompanyPricingSection}>
        <MDTypography sx={CompanyPricingTitle}>Company/Region Pricing</MDTypography>
        <Icon sx={AddButton} fontSize='medium' onClick={() => pricingProps.push({...CUSTOM_PRICING})}>add_circle</Icon>
      </MDBox>

      {valuesArray.length === 0 &&
        <MDBox sx={{display: 'flex', flexDirection: "column", width: "800px", px: 3, mt: 3, alignItems: 'center'}}>
          <MDTypography sx={SectionTitleText}>No custom pricing in this product</MDTypography>
        </MDBox>
      }

      {valuesArray.length > 0 && <MDBox sx={{display: 'flex', flexDirection: "column", width: "800px", px: 3}}>
        {valuesArray.map((custom_pricing, index) => {
            return (
              <CustomPricingInnerForm
                custom_pricing={custom_pricing}
                parentPath={parentPath}
                values={values}
                setFieldValue={setFieldValue}
                formik={formik}
                index={index}
                pricingProps={pricingProps}
                key={'custom_pricing_' + index}
              />

            )
          }
        )}
      </MDBox>}

    </MDBox>
  )

}
