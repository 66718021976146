import MDBox from "components/MDBox";
import { Field, FormikProvider, useFormik } from "formik";
import FormikInput from "components/FormikInput";
import pxToRem from "assets/theme/functions/pxToRem";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "services/helpers";
import { GeneralModal } from "components/general-modal";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import moment from "moment/moment";

const ShipmentCompletetedForm = ({ data, openModal, handleClose, handleChangedData }) => {
  const params = useParams();
  const { id: projectId, ido: orderId } = params;
  const shipmentId = data?.id;
  const trackingId = data?.tracking;

  const api = useApi();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    tracking_status: Yup.string().required("Choose one of the options"),
    scheduled_ship_date: Yup.date()
      .when("tracking_status", {
      is: "Partially Fulfilled",
      then: Yup.date().required("Required"),
      otherwise: Yup.date().notRequired(),
    }),
    scheduled_arrival_date: Yup.date()
      .when(
        "scheduled_ship_date",
        (shipDate, schema) =>
          shipDate ? schema.min(shipDate, "Arrival date cannot be before Ship date") : schema
      )
      .when("tracking_status", {
        is: (value) => value === "Partially Fulfilled",
        then: Yup.date().required("Required"),
        otherwise: Yup.date().notRequired(),
      }),
    revised_note: Yup.string().when("tracking_status", {
      is: "Partially Fulfilled",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const onSubmit = (values) => {
    const newValues = { ...values };
    if (values.tracking_status === "Received in Full") {
      newValues.revised_note = null;
      newValues.scheduled_ship_date = "";
      newValues.scheduled_arrival_date = "";
    }

    setLoading(true);
    api.setShipmentCompleted(projectId, orderId, trackingId, shipmentId, newValues).handle({
      onSuccess: () => {
        handleChangedData();
        handleClose();
      },
      errorMessage: "Error updating shipment status",
      onFinally: () => setLoading(false),
    });
  };

  const formik = useFormik({
    initialValues: {
      tracking_status: "",
      revised_note: "",
      scheduled_ship_date: "",
      scheduled_arrival_date: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <GeneralModal
      title={"Mark as"}
      open={openModal}
      loading={loading}
      handleClose={handleClose}
      onSubmit={formik.handleSubmit}
      fullHeight
    >
      <FormikProvider value={formik}>
        <MDBox
          display="flex"
          flexDirection="column"
          gap={pxToRem(22)}
          sx={{ padding: 4, width: "500px" }}
        >
          <Field name={"tracking_status"}>
            {({ meta }) => (
              <MDBox sx={{}}>
                <MDBox sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
                  <MDButton
                    sx={{ width: 200 }}
                    variant={
                      formik.values?.tracking_status === "Partially Fulfilled"
                        ? "contained"
                        : "outlined"
                    }
                    color="secondary"
                    onClick={() => formik.setFieldValue("tracking_status", "Partially Fulfilled")}
                  >
                    Partially Fulfilled
                  </MDButton>
                  <MDButton
                    sx={{ width: 200 }}
                    variant={
                      formik.values?.tracking_status === "Received in Full"
                        ? "contained"
                        : "outlined"
                    }
                    color="secondary"
                    onClick={() => {
                      formik.setFieldValue("tracking_status", "Received in Full")
                      formik.setFieldValue("scheduled_ship_date", "")
                      formik.setFieldValue("scheduled_arrival_date", "")
                      formik.setFieldValue("revised_note", "")
                    }}
                  >
                    Received in Full
                  </MDButton>
                </MDBox>
                {meta.touched && meta.error && (
                  <MDTypography
                    variant={"subtitle2"}
                    sx={{
                      paddingTop: 0.5,
                      paddingLeft: 2,
                      fontSize: "12px",
                      fontWeight: "400",
                      color: colors.error.main,
                    }}
                  >
                    {meta.error}
                  </MDTypography>
                )}
              </MDBox>
            )}
          </Field>

          <FormikInput
            name="scheduled_ship_date"
            label="Scheduled Ship Date"
            type="date"
            setFieldValue={formik.setFieldValue}
            setTouched={formik.setTouched}
            extraParams={{
              minDate: moment(new Date().getDate(), "DD/MM/YYYY"),
              clearable: true,
              disabled: formik.values?.tracking_status === "Received in Full",
            }}
          />
          <FormikInput
            name="scheduled_arrival_date"
            label="Scheduled Arrival Date"
            type="date"
            setFieldValue={formik.setFieldValue}
            setTouched={formik.setTouched}
            extraParams={{
              minDate: moment(new Date().getDate(), "DD/MM/YYYY"),
              clearable: true,
              disabled: formik.values?.tracking_status === "Received in Full",
            }}
          />
          <FormikInput
            disabled={formik.values?.tracking_status === "Received in Full"}
            type="textarea"
            rows={4}
            name="revised_note"
            label="Note for Revision"
          />

          <MDButton variant="contained" color="primary" onClick={formik.handleSubmit}>
            Confirm
          </MDButton>
        </MDBox>
      </FormikProvider>
    </GeneralModal>
  );
};

export default ShipmentCompletetedForm;
