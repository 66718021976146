import Icon from "@mui/material/Icon";

export const popoverActionsIcon = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  return (
      <Icon
        fontSize='medium' sx={{cursor: 'pointer'}}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setOpenPopover(true)
          setSelectedItem(item)
        }}
      >
        more_vert
      </Icon>
  )
}
