// noinspection JSUnresolvedFunction

import { FieldArray, FormikProvider, getIn, useFormik } from "formik";
import FormikInput from "../../../components/FormikInput";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import { memo, useEffect, useState } from "react";
import { openInNewTab, showMessage, useApi } from "../../../services/helpers";
import { useDebouncedValidate } from "../../../shared/formik_debounce_validate";
import AddBaseModal from "../../../components/add-base-modal/AddBaseModal";
import MDTypography from "../../../components/MDTypography";
import { renderBrandLogo } from "./utils";
import { apiMessages, useApiMessages } from "../../../services/api/api-messages";
import { wrapApiCall } from "../../../services/helpers_ts";
import {
  FileFieldsContainer,
  ImagecardContainer,
  ImagecardDeleteIcon,
} from "../manage-products/manage_product/styles";
import { Icon } from "@mui/material";
import InputDragAndDrop from "../../../components/InputDragAndDrop";

Yup.addMethod(Yup.mixed, "foreignKey", function (args) {
  return this.test("test-object-id", args.message, (value) => value && value.id);
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Manufacturer name is required"),
  address: Yup.string().required("Manufacturer address is required"),
  category: Yup.mixed().foreignKey("Manufacturer category is required"),
  logo: Yup.mixed().nullable(),
  program_documents: Yup.mixed().nullable(),
});

const defaultFK = {
  id: null,
  name: "",
};

export const ManufacturerModalForm = memo(
  ({ open, item, onClose, onSave, setLoading, loading }) => {
    const api = useApi();
    const [categories, setCategories] = useState([]);
    const messages = useApiMessages("manufacturer", "manufacturers");
    const [files, setFiles] = useState([]);

    const formik = useFormik({
      initialValues: {
        id: item?.id ? item.id : null,
        name: item ? item.name : "",
        address: item ? item.address : "",
        category: item ? { id: item.category, name: item.category_name } : { ...defaultFK },
        logo: item ? item.logo : null,
        program_documents: item ? item.program_documents : null,
      },
      validateOnChange: false,
      validationSchema: validationSchema,
      onSubmit: (values) => save(values),
    });

    useDebouncedValidate({
      validate: (values) => {
        formik.validateForm(values);
      },
      debounceTime: 200,
      values: formik.values,
    });

    useEffect(() => {
      getCategories({ search: "" });
    }, []);

    const save = (values) => {
      setLoading(true);
      const keys = [];
      if (values.logo) {
        if (item) {
          if (item.logo !== values.logo) {
            keys.push(["logo"]);
          } else {
            delete values["logo"];
          }
        } else {
          keys.push(["logo"]);
        }
      }

      let data = { ...values };
      data["category"] = values.category.id;

      if (item?.id) {
        editManufacturer(data, keys);
      } else {
        createManufacturer(data, keys);
      }
    };

    const getCategories = ({ search }) => {
      setLoading(true);
      wrapApiCall(api.getCategories({ search: search, page: 1, ordering: "", page_size: 25 }), {
        onSuccess: (result) => {
          setCategories(result.data.results);
        },
        errorMessage: apiMessages.list.error("categories"),
        onFinally: () => setLoading(false),
      });
    };

    const editManufacturer = (data) => {
      setLoading(true);
      api.editManufacturer(data).handle({
        successMessage: messages.update.success,
        onSuccess: onSave,
        errorMessage: messages.update.error,
        onFinally: () => setLoading(false),
      });
    };

    const createManufacturer = (data) => {
      setLoading(true);
      api.createManufacturer(data).handle({
        successMessage: messages.create.success,
        onSuccess: onSave,
        errorMessage: messages.create.error,
        onFinally: () => setLoading(false),
      });
    };

    const handleDrop = (e, filesProps) => {
      e.preventDefault();
      const newFiles = [...e.dataTransfer.files];
      setFiles([...files, ...newFiles]);
      newFiles.forEach((file) => {
        filesProps.push({
          image: file,
          url: URL.createObjectURL(file),
        });
      });
    };

    return (
      <AddBaseModal
        open={open}
        handleClose={() => {
          formik.resetForm();
          onClose();
        }}
        title={item?.id ? "Edit Manufacturer" : "Add Manufacturer"}
        buttonText={"Save"}
        onSubmit={formik.handleSubmit}
        loading={loading}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          gap={pxToRem(22)}
          pt={pxToRem(32)}
          px={pxToRem(45)}
        >
          <FormikProvider value={formik}>
            <FormikInput name="name" label="Manufacturer Name" />

            <FormikInput
              type={"autocomplete"}
              value={formik.values.category}
              fieldName={"category"}
              label={"Category"}
              options={categories}
              accessKey={"name"}
              onInputChange={(value) => {
                getCategories({ search: value.name });
              }}
              onChange={(value) => {
                formik.setFieldValue("category", value);
              }}
            />

            <FormikInput name="address" label="Manufacturer Address" />

            <FormikInput
              fullWidth
              filename={"logo"}
              type={"file"}
              name={"logo"}
              label={"Manufacturer Logo"}
              onChange={(e) => {
                const fileValue = e.target.files[0];
                formik.setFieldValue("logo", fileValue);
              }}
            />
            {item?.id && item.logo && (
              <MDBox display={"flex"} flexDirection={"row"} gap={"5px"} alignItems={"center"}>
                <MDTypography variant={"h5"} mt={"-4px"} fontWeight={"bold"} fontSize={12}>
                  Current logo:
                </MDTypography>
                {renderBrandLogo(item)}
              </MDBox>
            )}
            <MDBox sx={FileFieldsContainer}>
              <FieldArray name={"program_documents"}>
                {(programDocumentsProps) => {
                  const parentPath = programDocumentsProps.name;
                  const valueArray = getIn(formik.values, parentPath);
                  return (
                    <MDBox>
                      <input
                        type="file"
                        multiple
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        id="program_document"
                        style={{ display: "none" }}
                        onInput={(event) => {
                          if (event.target.files?.length) {
                            const selectedFiles = [...event.target.files];
                            selectedFiles.forEach((file) => {
                              programDocumentsProps.push({
                                program_document_file: file,
                                file_name: file.name,
                              });
                            });
                          }
                        }}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                      <InputDragAndDrop
                        htmlFor="program_document"
                        label="Program Document"
                        errors={formik.errors?.program_document}
                        name={"program_documents"}
                      />
                      {valueArray?.map((file, index) => {
                        return (
                          <>
                            <MDBox sx={ImagecardContainer} key={`architectural-1${index}`}>
                              <Icon
                                sx={(th) => ({ ml: pxToRem(11), mr: 2, mt: 2 })}
                                fontSize="medium"
                                onClick={() => {
                                  openInNewTab(
                                    file?.program_document_file instanceof File
                                      ? URL.createObjectURL(file?.program_document_file)
                                      : file?.program_document_file
                                  );
                                }}
                              >
                                attachment
                              </Icon>
                              <MDTypography
                                variant={"subtitle2"}
                                onClick={() => {
                                  openInNewTab(
                                    file?.program_document_file instanceof File
                                      ? URL.createObjectURL(file?.program_document_file)
                                      : file?.program_document_file
                                  );
                                }}
                                p={2}
                                sx={{
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  cursor: "pointer",
                                }}
                              >
                                {file?.file_name || file?.program_document_file.name}
                              </MDTypography>
                              <Icon
                                sx={ImagecardDeleteIcon}
                                fontSize="medium"
                                onClick={() => programDocumentsProps.remove(index)}
                              >
                                remove_circle
                              </Icon>
                            </MDBox>
                            {/*Add here the formk*/}
                            <FormikInput
                              name={`${parentPath}.[${index}].file_name`}
                              label="File Name"
                            />
                          </>
                        );
                      })}
                    </MDBox>
                  );
                }}
              </FieldArray>
            </MDBox>
          </FormikProvider>
        </MDBox>
      </AddBaseModal>
    );
  }
);
