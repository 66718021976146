import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";
import * as Types from "./api.types";
import { ApiBase, ApiReturnType } from "./api-base";
import { API_VERSION_PREFIX } from "../constants";
import { PMTaskType, VendorTaskType } from "../constants_ts";
import { SimplePostResult } from "./api.types";

/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config);
  }

  login(username: string, password: string): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/login/`, {
      email: username,
      password,
    });
  }

  forgotPassword(email: string): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/reset-password/`, { email: email });
  }

  resetPassword(data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/set-new-password/`, data);
  }

  changePassword(data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/change-password/`, data);
  }

  getTermsConditions(): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/terms_and_conditions/`);
  }

  getPrivacyPolicy() {
    return this.simple_get(`${API_VERSION_PREFIX}/modules/privacy-policy/`);
  }

  getRegions(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/region/`, data);
  }

  getOrdersAdmin(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/order/`, data);
  }

  getOrdersStatusAdmin(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/order/list_status/`, data);
  }

  getOrders(idProject: any, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/order/`, data);
  }

  getOrder(idProject: number | string, orderId: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/`);
  }

  getOrderDetail(idProject: any, orderId: number | string, version?: number | string) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/full/` +
        (version ? `?version=${version}` : "")
    );
  }

  getOrderVersions(idProject: number | string, orderId: number | string, data: any) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/versions/`,
      data
    );
  }

  getOrderActivity(idProject: number | string, orderId: number | string, data: any) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/`,
      data
    );
  }

  sendOrderMessage(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/send_message/`,
      data
    );
  }

  shareOrder(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/share_order/`,
      data
    );
  }

  projectManufacturers(idProject: number | string, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/manufacturers/`, data);
  }

  requestQuote(idProject: number | string, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${idProject}/request_quote/`, data);
  }

  getCategories(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/category/`, data);
  }

  getManufacturers(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer/`, data);
  }

  getManufacturerById(manufacturerId?: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer/${manufacturerId}/`);
  }

  getManufacturersByCategory(category_id: Number, data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer/by_category/`, {
      category_id,
      ...data,
    });
  }

  getCompanies(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/company/`, data);
  }

  getProjects(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/`, data);
  }

  getProject(id: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${id}/`);
  }
  getProjectCartSections(idProject: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/cart_section/`);
  }

  createProjectCartSection(idProject: number | string, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${idProject}/cart_section/`, data);
  }

  deleteProjectCartSection(idProject: number | string, idProjectCartSection: number | string) {
    return this.simple_delete(
      `${API_VERSION_PREFIX}/project/${idProject}/cart_section/${idProjectCartSection}/`
    );
  }

  addItemToCartOrQuote(idProject: number | string, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${idProject}/order/add_item/`, data);
  }

  getProjectDashboardInfo(idProject: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/dashboard/`);
  }

  downloadOrderCutsheets(idProject: number | string, orderId: number | string, params: any) {
    return this.download_file_get(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/download_cutsheets/`,
      params
    );
  }

  downloadManufacturerDocuments(idManufacturer: number | string, params: any) {
    return this.download_file_get(
      `${API_VERSION_PREFIX}/manufacturer/${idManufacturer}/download_program_documents/`,
      params
    );
  }

  downloadBrandDrawing(idproject: number | string, iddrawing: number | string) {
    return this.download_file_get(
      `${API_VERSION_PREFIX}/brand/${idproject}/drawing/${iddrawing}/files/`
    );
  }

  getProducts(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/product/`, data);
  }

  getProjectProducts(idProject: number | string, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/product/`, data);
  }

  getMyProfile(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/my-profile/`, data);
  }

  saveMyProfile(data: any, keys: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/my-profile/`, data);
  }

  getProduct(id: any, data: any = {}) {
    return this.simple_get(`${API_VERSION_PREFIX}/product/${id}/`, data);
  }

  getProjectProduct(idProject: number | string, id: number | string, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/product/${id}/`, data);
  }

  getManufacturerProducts(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer_product/`, data);
  }

  getManufacturerProductDetail(id: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer_product/${id}/`);
  }

  getMisCostTaxes() {
    return this.simple_get(`${API_VERSION_PREFIX}/tax-category/`);
  }

  createProduct(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/product/`, data);
  }

  deleteProduct(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/product/${id}/`);
  }

  saveMiscellaneousCost(idProject: number | string, orderId: number | string, misc_cost: any) {
    return this.simple_id_save_or_create(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/miscellaneous_costs`,
      misc_cost
    );
  }

  deleteMiscellaneousCost(
    idProject: number | string,
    orderId: number | string,
    miscCostId: number | string
  ) {
    return this.simple_delete(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/miscellaneous_costs/${miscCostId}/`
    );
  }

  deleteOrderLine(
    idProject: number | string,
    orderId: number | string,
    orderLineId: number | string
  ) {
    return this.simple_delete(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/order_line/${orderLineId}/`
    );
  }

  updateQtyOrderLine(
    idProject: number | string,
    orderId: number | string,
    orderLineId: number | string,
    quantity: number | string
  ) {
    return this.simple_put(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/order_line/${orderLineId}/`,
      { quantity }
    );
  }

  addCustomTax(orderId: number, data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/order/${orderId}/add_custom_tax/`, data);
  }

  deleteOrder(idProject: number | string, orderId: number | string) {
    return this.simple_delete(`${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/`);
  }

  doOrderTask(
    idProject: number | string,
    orderId: number | string,
    task: PMTaskType,
    message: string
  ) {
    let data: any = { action: task };
    if (message) data["message"] = message;
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/do_task/`,
      data
    );
  }
  shareOrderTask(idProject: number | string, orderId: number | string, data: any) {
    // let data: any = {action: task}
    // if (message) data['message'] = message
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/share_order/`,
      data
    );
  }

  doOrderVendorTask(idProject: number | string, orderId: number | string, task: VendorTaskType) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/do_task_vendor/`,
      { action: task }
    );
  }

  approveContract(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/approve_contract/`,
      data
    );
  }

  sendContract(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/contract/`,
      data
    );
  }

  sendDeliverySchedule(idProject: number | string, orderId: number | string, data: any) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${idProject}/order/${orderId}/activity/delivery_schedule/`,
      data
    );
  }

  updateProduct(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/product/${data?.id}/`,
      data
    );
  }

  getProductsByManufacturer(manufacturer_id: Number, data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/product/by_manufacturer/`, {
      manufacturer_id,
      ...data,
    });
  }

  deleteProject(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/project/${id}/`);
  }

  createProject(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/project/`, data);
  }

  editProject(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(`${API_VERSION_PREFIX}/project/${data?.id}/`, data);
  }

  addDeleteProjectManager(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${data?.id}/project_manager/`, data);
  }

  sendEmailToManufacturer(projectId: string | number, data: any): ApiReturnType<SimplePostResult> {
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${projectId}/send_email_to_manufacturer/`,
      data
    );
  }

  getProjectPackage(id: number | string, idPackage: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${id}/package/${idPackage}/`);
  }

  getManufacturerPackage(idPackage: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer_package/${idPackage}/`);
  }

  getProjectPackages(id: number | string, data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${id}/package/`, data);
  }

  getManufacturerPackages(id: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/manufacturer/${id}/packages/`);
  }

  createUser(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/user/`, data);
  }

  editUser(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(`${API_VERSION_PREFIX}/user/${data?.id}/`, data);
  }

  deleteUser(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/user/${id}/`);
  }

  createCompany(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/company/`, data);
  }

  editCompany(data: any, keys: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/company/${data?.id}/`,
      data
    );
  }

  deleteCompany(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/company/${id}/`);
  }

  createRegion(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/region/`, data);
  }

  editRegion(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(`${API_VERSION_PREFIX}/region/${data?.id}/`, data);
  }

  deleteRegion(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/region/${id}/`);
  }

  reSendAdminUserEmail(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/user/${id}/email/`);
  }

  setUserPassword(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(`${API_VERSION_PREFIX}/user/${id}/set_user_password/`);
  }

  getAllUsers(data: any): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/user/`, data);
  }

  getAllTags(categoryId: number | string): ApiReturnType<Types.SimpleGetResult> {
    return this.simple_get(`${API_VERSION_PREFIX}/category/${categoryId}/tag/`);
  }

  createTag(categoryId: number, data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/category/${categoryId}/tag/`, data);
  }

  deleteTag(categoryId: number, tagId: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/category/${categoryId}/tag/${tagId}/`);
  }

  createCategory(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/category/`, data);
  }

  editCategory(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/category/${data?.id}/`,
      data
    );
  }

  deleteCategory(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/category/${id}/`);
  }

  getBackgroundImage() {
    return this.simple_get(`${API_VERSION_PREFIX}/background-image/`);
  }

  updateBackgroundImage(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/background-image/`, data);
  }

  getAnalyticsInitialData() {
    return this.simple_get(`${API_VERSION_PREFIX}/analytics/overview/`);
  }

  downloadAnalyticsReport(data: any) {
    return this.download_file_post(`${API_VERSION_PREFIX}/analytics/download/`, data);
  }

  getBrands(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/brand/`, data);
  }

  getBrand(id: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/brand/${id}/`);
  }

  deleteBrand(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/brand/${id}/`);
  }
  duplicateBrand(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(API_VERSION_PREFIX + `/brand/${id}/duplicate_brand/`);
  }

  duplicateProduct(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(API_VERSION_PREFIX + `/product/${id}/duplicate_product/`);
  }

  reorderProduct(id: number, data: any): ApiReturnType<Types.GenericResponse> {
    return this.simple_post(API_VERSION_PREFIX + `/product/${id}/reorder/`, data);
  }

  createManufacturer(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/manufacturer/`, data);
  }

  getSubContractors(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/sub-contractor/`, data);
  }

  createSubContractor(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/sub-contractor/`, data);
  }

  editSubContractor(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/sub-contractor/${data?.id}/`,
      data
    );
  }

  addSubContractorsRep(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(
      `${API_VERSION_PREFIX}/sub-contractor/${data?.id}/sub_contractor_rep/`,
      data
    );
  }

  addSubContractorsRepToProject(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${data?.id}/sub_contractor_reps/`, data);
  }

  deleteSubContractors(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/sub-contractor/${id}/`);
  }

  editManufacturer(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/manufacturer/${data?.id}/`,
      data
    );
  }

  deleteManufacturer(id: number): ApiReturnType<Types.GenericResponse> {
    return this.simple_delete(API_VERSION_PREFIX + `/manufacturer/${id}/`);
  }

  addDeleteSupplyManager(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/manufacturer/${data?.id}/supply_manager/`, data);
  }

  addSupplyManager(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/project/${data?.id}/supply_manager/`, data);
  }

  createBrand(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/brand/`, data);
  }

  updateBrand(data: any): ApiReturnType<Types.SimplePostResult> {
    return this.patch_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/brand/${data?.id}/`,
      data
    );
  }

  getNotifications(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/notification/`, data);
  }

  markAsRead(id: number | string) {
    return this.simple_post(`${API_VERSION_PREFIX}/notification/${id}/mark_as_read/`);
  }

  hasUnreadNotifications() {
    return this.simple_get(`${API_VERSION_PREFIX}/notification/has_unread/`);
  }
  hasUnreadMessages() {
    return this.simple_get(`${API_VERSION_PREFIX}/conversation/has_unread/`);
  }

  getProjectContactList(idProject: number | string, categoryId: number | string) {
    return this.simple_get(`${API_VERSION_PREFIX}/project/${idProject}/contact_list/?category_id=${categoryId}`);
  }

  getConversationUsers(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/conversation/`, data);
  }

  getConversation(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/conversation/conversation_detail/`, data);
  }

  setDevice(data: any) {
    return this.simple_post(`${API_VERSION_PREFIX}/set-device/`, data);
  }

  getConfigurations() {
    return this.simple_get(`${API_VERSION_PREFIX}/configuration/`);
  }

  // TRACKING | DELIVERY

  getTrackingInfo(
    projectId: number | string,
    orderId: number | string,
    trackingId: number | string
  ) {
    return this.simple_get(
      `${API_VERSION_PREFIX}/project/${projectId}/order/${orderId}/tracking/${trackingId}/`
    );
  }

  updateTrackingInfo(
    projectId: number | string,
    orderId: number | string,
    trackingId: number | string,
    data: any
  ): ApiReturnType<Types.SimplePostResult> {
    console.log(data)
    return this.simple_patch(
      `${API_VERSION_PREFIX}/project/${projectId}/order/${orderId}/tracking/${trackingId}/`,
      data
    );
  }

  createShipment(
    projectId: number | string,
    orderId: number | string,
    trackingId: number | string,
    data: any
  ) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${projectId}/order/${orderId}/tracking/${trackingId}/shipment/`,
      data
    );
  }

  updateShipment(
    projectId: number | string,
    orderId: number | string,
    trackingId: number | string,
    shipmentId: number | string,
    data: any
  ): ApiReturnType<Types.SimplePostResult> {
    return this.simple_patch(
      `${API_VERSION_PREFIX}/project/${projectId}/order/${orderId}/tracking/${trackingId}/shipment/${shipmentId}/`,
      data
    );
  }

  addPod(
    projectId: number | string,
    orderId: number | string,
    trackingId: number | string,
    shipmentId: number | string,
    data: any
  ) {
    return this.post_collected_multipart_form_data(
      `${API_VERSION_PREFIX}/project/${projectId}/order/${orderId}/tracking/${trackingId}/shipment/${shipmentId}/add_pod/`,
      data
    );
  }

  setShipmentCompleted(
    projectId: number | string,
    orderId: number | string,
    trackingId: number | string,
    shipmentId: number | string,
    data: any
  ) {
    return this.simple_post(
      `${API_VERSION_PREFIX}/project/${projectId}/order/${orderId}/tracking/${trackingId}/shipment/${shipmentId}/mark_as_completed/`,
      data
    );
  }
}
