import {useEffect, useState} from "react";
import {observer} from "mobx-react";

import MDButton from "components/MDButton";
import AdminLayout from "components/AdminLayout";
import DataTable from "components/DataTable";

import {showMessage, useApi, useIsMobile} from "../../../services/helpers";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";
import {PopoverEditDelete} from "../../../components/PopoverActions/PopoverEditDelete";
import {popoverActionsIcon} from "../../../components/PopoverActionsIcon";
import {RegionModalForm} from "./components/RegionModalForm";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDBox from "../../../components/MDBox";
import editIcon from "assets/icons/pencil.svg";
import trashIcon from "assets/icons/trash.svg";
import SearchBar from "../../../components/SearchBar";


export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name"},
    {Header: "Address", accessor: "address"},
    {Header: "Company", accessor: "company__name"},
    {Header: "", accessor: "actions", align: "left", disableOrdering: true, width: pxToRem(5)}
  ],
  rows: [],
};


function ManageRegionsPage() {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [openModalForm, setOpenModalForm] = useState(false)
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const api = useApi()
  const [searchQuery, setSearchQuery] = useState('');
  const isMobile = useIsMobile()

  const renderTableRow = (item) => {
    item.actions = (popoverActionsIcon(item, setAnchorEl, setOpenPopover, setSelectedItem))
    item.company__name = item.company_name
    return item
  }


  const getRegions = (searchData = '', page = 1, ordering = paginationData.ordering) => {
    setLoading(true)
    api.getRegions({search: searchData, page, ordering}).then((result) => {
      if (result.kind === "ok") {
        const {count, results} = result.data
        const tmp = {...dataTableModel}
        tmp.rows = results.map(item => renderTableRow(item, setAnchorEl, setOpenPopover, setSelectedItem))
        setDatatable(tmp)
        setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length, ordering}))
      }
    })
      .catch(reason => console.log(reason))
      .finally(() => setLoading(false))
  }


  const onDelete = () => {
    setLoading(true)
    api.deleteRegion(selectedItem?.id).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Region deleted successfully', 'success')
        getRegions('')
        onClose()
      } else if (result.kind === 'bad-data') {
        showMessage(result.errors[0])
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }


  const onClose = () => {
    setOpenPopover(false)
    setSelectedItem(null)
    setOpenConfirmModal(false)
    setOpenModalForm(false)
  }


  return (
    <AdminLayout
      title={'Manage Regions'}
      header={
        <MDBox display={'flex'} flex={1} flexDirection={'row'} alignItems={'center'}>
          <SearchBar loading={loading} search={getRegions} setSearchQuery={setSearchQuery}/>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            onClick={() => setOpenModalForm(true)}
            sx={isMobile ? { fontSize:"14px", flexWrap:"nowrap", width:"178px", alignContent:"center", pb:"6px"}:{}}
          >
            Add New Region
          </MDButton>
        </MDBox>
      }
    >

      {openModalForm && (
        <RegionModalForm
          item={selectedItem}
          open={openModalForm}
          onClose={onClose}
          setLoading={setLoading}
          loading={loading}
          onSave={() => {
            onClose()
            getRegions(searchQuery);
          }}/>
      )}
      <ConfirmDialogModal
        title={'Do you want to delete this region?'}
        description={`${selectedItem?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openConfirmModal}
        handleClose={() => onClose()}
        handleConfirm={() => onDelete()}
      />

      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getRegions}
        searchQuery={''}
        onPageChange={page => {
          getRegions(searchQuery, page)
          setPaginationData(value => ({...value, currentPage: page}))
        }}
      />

      <PopoverEditDelete
        open={openPopover}
        onClose={() => onClose()}
        onClickEdit={() => setOpenModalForm(true)}
        onClickDelete={() => setOpenConfirmModal(true)}
        anchorEl={anchorEl}/>


    </AdminLayout>
  );
}

export default observer(ManageRegionsPage);

