import pxToRem from "../../../assets/theme/functions/pxToRem";
import {Box} from "@mui/material";
import logo from "../../../assets/icons/dolovo-D.png";
import {popoverActionsIcon} from "../../../components/PopoverActionsIcon";
import MDTypography from "../../../components/MDTypography";


export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name"},
    {Header: "Address", accessor: "address"},
    {Header: "Logo", accessor: "logo_row", disableOrdering: true},
    {Header: "", accessor: "actions", align: "left", disableOrdering: true, width: pxToRem(5)}
  ],
  rows: [],
};


export const renderCompanyLogo = (item) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} alignItems={"center"} sx={{background:"#fff"}} borderRadius={'50%'}>
      {item.logo
        ? <Box component={"img"} src={item.logo} alt={"profile_picture"} width={pxToRem(50)} height={pxToRem(50) }
               borderRadius={"50%"}  sx={{objectFit: 'contain'}}/>
        : <Box component={"img"} src={logo} alt={"profile_logo"} width={pxToRem(50)} height={pxToRem(50)}
               borderRadius={"50%"}/>
      }
      </Box>
    </Box>
  )
}


export const renderTableRow = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  item.logo_row = renderCompanyLogo(item)
  item.actions = (popoverActionsIcon(item, setAnchorEl, setOpenPopover, setSelectedItem))
  return item
}
