import React, {useEffect, useState} from 'react';
import {Grid, Modal} from "@mui/material";
import MDButton from "../MDButton";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowForward} from "@mui/icons-material";
import {ROUTES} from "../../services/constants";


const KeepShoppingModal = (
  {
    open,
    handleClose
  }) => {
  const {id: idProject} = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <MDBox sx={styles.modal}>
          <Grid container mt={3}>
            <Grid item xs={6}>
              <MDTypography sx={styles.itemTitle}>Go to:</MDTypography>
            </Grid>
              <Grid item xs={12} sx={{height: 350, overflowY: 'scroll'}}>
                <MDBox sx={styles.itemList}>
                  <MDTypography sx={styles.itemTitle}>Products</MDTypography>
                  <MDButton
                    disabled={loading}
                    size={'small'}
                    iconOnly
                    variant='contained'
                    color='primary'
                    sx={styles.itemButton}
                    onClick={() => {
                      navigate(`${ROUTES.USER_PRODUCTS(idProject)}${window.location.search || "" }`)
                    }}
                  >
                    <ArrowForward/>
                  </MDButton>
                </MDBox>
                <MDBox sx={styles.itemList}>
                  <MDTypography sx={styles.itemTitle}>Packages</MDTypography>
                  <MDButton
                    disabled={loading}
                    size={'small'}
                    iconOnly
                    variant='contained'
                    color='primary'
                    sx={styles.itemButton}
                    onClick={() => {
                      navigate(`${ROUTES.USER_PROJECT_PACKAGES(idProject)}${window.location.search || "" }`)
                    }
                    }
                  >
                    <ArrowForward/>
                  </MDButton>
                </MDBox>
              </Grid>

          </Grid>
          <MDBox display='flex' flexDirection='column' mx={pxToRem(15)} mt={'auto'} mb={5}>
            <MDButton disabled={loading} fullWidth variant='outlined' color='secondary' onClick={handleClose}>
              Cancel
            </MDButton>
          </MDBox>
        </MDBox>

      </Modal>
    </>
  )
};

export default KeepShoppingModal;

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 410,
    borderRadius: "10px",
    backgroundColor: '#fff',
    border: 'none',
    boxShadow: 24,
    height: 520,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  titleLeft: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    fontWeight: 600,
    textAlign: 'center',

  },
  titleRight: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    fontWeight: 600,
    textAlign: 'center',
    color: '#0B7340',
    cursor: 'pointer'
  },
  itemList: {
    border: '1px solid #DBDBDB',
    borderRadius: pxToRem(8),
    marginLeft: pxToRem(15),
    marginRight: pxToRem(15),
    marginTop: pxToRem(7.5),
    marginBottom: pxToRem(7.5),
    display: 'flex',
    height: pxToRem(50),
    alignItems: 'center',
  },
  itemTitle: {
    width: pxToRem(300),
    fontWeight: '600',
    fontSize: pxToRem(17),
    marginLeft: pxToRem(15),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemButton: {
    width: pxToRem(30),
    height: pxToRem(30),
    borderRadius: pxToRem(15),
    fontSize: pxToRem(20),
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    fontWeight: '400'
  },
  itemEmpty: {
    fontWeight: '600',
    fontSize: pxToRem(17),
    alignItems: 'center',
    textAlign: 'center',
    marginTop: pxToRem(100),
  },
}
