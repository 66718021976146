// noinspection JSUnresolvedFunction

import { FormikProvider, useFormik } from "formik";
import FormikInput from "../../../../components/FormikInput";
import MDBox from "../../../../components/MDBox";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import { memo, useEffect, useState } from "react";
import { useApi } from "../../../../services/helpers";
import { useDebouncedValidate } from "../../../../shared/formik_debounce_validate";
import AddBaseModal from "../../../../components/add-base-modal/AddBaseModal";
import { wrapApiCall } from "../../../../services/helpers_ts";
import { apiMessages, useApiMessages } from "../../../../services/api/api-messages";

const REQUIRED_FIELD_MSG = "This field is required";

Yup.addMethod(Yup.mixed, "foreignKey", function (args) {
  return this.test("test-object-id", args.message, (value) => value && value.id);
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_MSG),
  address: Yup.string().required(REQUIRED_FIELD_MSG),
  company: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
  region: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
  brand: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
});

const initialValues = {
  name: "",
  address: "",
  company: null,
  region: null,
  brand: null,
};

export const ProjectModalForm = ({ open, item, onClose, onSave, setLoading, loading }) => {
  const api = useApi();
  const [brands, setBrands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(item?.company.id);
  const [selectedRegion, setSelectedRegion] = useState(item?.region.id);
  const [init, setInit] = useState(false);
  const messages = useApiMessages("project", "projects");

  const formik = useFormik({
    initialValues: item ? { ...item } : initialValues,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => save(values),
  });

  useDebouncedValidate({
    validate: (values) => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  const save = (values) => {
    setLoading(true);
    let data = { ...values };
    data["company"] = values.company.id;
    data["region"] = values.region.id;
    data["brand"] = values.brand.id;

    if (item?.id) {
      editProject(data);
    } else {
      createProject(data);
    }
  };

  const getCompanies = ({ search }) => {
    wrapApiCall(api.getCompanies({ search }), {
      onSuccess: (result) => {
        setCompanies(result.data.results);
      },
      errorMessage: apiMessages.list.error("companies"),
      onFinally: () => setLoading(false),
    });
  };

  const getRegions = ({ search, company_id }) => {
    wrapApiCall(api.getRegions({ search, company_id: selectedCompany || company_id }), {
      onSuccess: (result) => {
        setRegions(result.data.results);
      },
      errorMessage: apiMessages.list.error("regions"),
      onFinally: () => setLoading(false),
    });
  };

  useEffect(() => {
  }, [selectedCompany]);

  const getBrands = ({ search, region_id }) => {
    wrapApiCall(api.getBrands({ search, region_id: selectedRegion || region_id }), {
      onSuccess: (result) => {
        return setBrands(result.data.results);
      },
      errorMessage: apiMessages.list.error("brands"),
      onFinally: () => setLoading(false),
    });
  };

  const editProject = (data) => {
    wrapApiCall(api.editProject(data), {
      successMessage: messages.update.success,
      onSuccess: onSave,
      errorMessage: messages.update.error,
      onError: (result) => {
        formik.setErrors(result.errors);
      },
      onFinally: () => setLoading(false),
    });
  };

  const createProject = (data) => {
    wrapApiCall(api.createProject(data), {
      successMessage: messages.create.success,
      onSuccess: onSave,
      errorMessage: messages.create.error,
      onError: (result) => {
        formik.setErrors(result.errors);
      },
      onFinally: () => setLoading(false),
    });
  };

  useEffect(() => {
    getRegions({ search: "" });
    if (init) {
      formik.setFieldValue("region", null);
    }
  }, [selectedCompany]);

  useEffect(() => {
    getBrands({ search: "" });
    if (init) {
      formik.setFieldValue("brand", null);
    }
  }, [selectedRegion, selectedCompany]);

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    getCompanies({ search: "" });
    getRegions({ search: "" });
    getBrands({ search: "" });
  }, []);

  return (
    <AddBaseModal
      open={open}
      loading={loading}
      handleClose={() => {
        formik.resetForm();
        onClose();
      }}
      title={item?.id ? "Edit Project" : "Add Project"}
      buttonText={"Save"}
      onSubmit={formik.handleSubmit}
    >
      <MDBox
        display="flex"
        flexDirection="column"
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
      >
        <FormikProvider value={formik}>
          <FormikInput name="name" label="Project Name" />
          <FormikInput name="address" rows={2} type={"textarea"} label="Project Address" />

          <FormikInput
            type={"autocomplete"}
            value={formik.values.company}
            fieldName={"company"}
            label={"Company"}
            options={companies}
            accessKey={"name"}
            onInputChange={(value) => {
              getCompanies({ search: value });
            }}
            onChange={(value) => {
              formik.setFieldValue("company", value);
              setSelectedCompany(value?.id ? value?.id : null);
            }}
          />

          <FormikInput
            type={"autocomplete"}
            value={formik.values.region}
            fieldName={"region"}
            label={"Region"}
            options={regions}
            onInputChange={(value, param2, param3) => {
              getRegions({ search: value });
            }}
            onChange={(value) => {
              formik.setFieldValue("region", value);
              setSelectedRegion(value?.id ? value?.id : null);
            }}
          />

          <FormikInput
            type={"autocomplete"}
            value={formik.values.brand}
            fieldName={"brand"}
            label={"Brand"}
            options={brands}
            onInputChange={(value) => {
              getBrands({ search: value });
            }}
            onChange={(value) => {
              formik.setFieldValue("brand", value);
            }}
          />
          <FormikInput name="contact_phone" label="Contact Phone" type="phone_input"/>
          <FormikInput name="contact_email" label="Contact Email" type="email"/>
          <FormikInput
            name="delivery_instructions"
            rows={2}
            type={"textarea"}
            label="Delivery Instructions"
          />
        </FormikProvider>
      </MDBox>
    </AddBaseModal>
  );
};
