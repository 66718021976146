import Icon from "@mui/material/Icon";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import logo from "assets/icons/dolovo-D.png";
import {Box} from "@mui/material";
import {ROLES} from "../../../services/constants";

export const dataTableModel = {
  columns: [
    {Header: "Role", accessor: "group", width: "auto", custom_ordering: 'group_name'},
    {Header: "Title", accessor: "title", width: "auto"},
    {Header: "Coverage Area", accessor: "coverage", width: `${pxToRem(150)}`, whiteSpace:"nowrap",textOverflow:"clip"},
    {Header: "Name", accessor: "name_table", width: "auto", custom_ordering: "name"},
    {Header: "Company", accessor: "company_row_name", width: "auto"},
    {Header: "Phone", accessor: "phone_number", width: "auto"},
    {Header: "Email", accessor: "email", width: "auto"},
    {Header: "Photo", accessor: "photo", width: "auto"},

  ],
  rows: [],
};

const renderProfilePicture = (item) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {item.profile_picture
        ? <Box component={"img"} src={item.profile_picture} alt={"profile_picture"} width={pxToRem(100)}
               height={pxToRem(100)} borderRadius={"50%"} sx={{objectFit:"cover"}} />
        : <Box component={"img"} src={logo} alt={"profile_logo"} width={pxToRem(100)} height={pxToRem(100)}
               borderRadius={"50%"} sx={{objectFit:"cover"}}/>
      }
    </Box>
  )
}

const renderName = (item) => {
  return (
    <Box>
      {item.name}
    </Box>
  )
}


export const renderTableRow = (item) => {
  item.name_raw = item.name
  item.photo = renderProfilePicture(item)
  item.name_table = renderName(item)
  return item
}
