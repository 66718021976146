/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {useState, useEffect} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {observer} from "mobx-react";

// MUI Components
import {Grid, Icon, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';


// Components
import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Utils
import {convertStringToList, money_fmt, openInNewTab, showMessage, useApi} from "services/helpers";

// Styles
import {
  ButtonsContainer,
  EditButton,
  DeleteButton,
  BrandText,
  PrimaryImageContainer,
  OtherImagesContainer,
  OtherImageContainer,
  OtherImage,
  GeneralInfoContainer,
  GeneralInfoItem,
  GeneralInfoText,
  Divider,
  AccordionBox,
  ListItem,
  AccordionDivider,
} from "./styles";

// Images and Theme functions
import ExpandMore from 'assets/images/expand_more.svg';
import AdaLogo from 'assets/images/ada-logo.png';
import pxToRem from "assets/theme/functions/pxToRem";
import {ROUTES} from "../../../../services/constants";
import ConfirmDialogModal from "../../../../components/ConfirmDialogModal";
import {FilecardContainer, FilenameText, RemoveButton} from "../manage_product/styles";
import {wrapApiCall} from "../../../../services/helpers_ts";
import {useApiMessages} from "../../../../services/api/api-messages";
import {useStores} from "../../../../models";
import {MainContainer} from "../styles";
import {
  GeneralInfoItemHeader,
  InputQuantity,
  PrimaryImage
} from "../../../project-manager/products/product-detail/styles";
import NextImage from "../../../../assets/icons/next-image.svg";
import MDInput from "../../../../components/MDInput";
import CartSectionModal from "../../../../components/CartSectionModal";

const MAX = 1000000

function ProductDetailOrderPage() {
  const api = useApi()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const navigate = useNavigate();
  const [searchParams ] = useSearchParams();
  const order_or_quote_id = searchParams.get('oqn');
  const [Product, setProduct] = useState(null);
  const [openCartModal, setOpenCartModal] = useState(false);
  const [productOrder, setProductOrder] = useState({
    quantity: 1,
    product_price: 0,
    total_product: 0,
    shipping_price: 0,
    total_shipping: 0,
    installation: 0
  })
  const [selectedImage, setSelectedImage] = useState({src: null, currentIndex: 0});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {id: idProject, idp: idProduct, ido: orderId} = useParams();
  const messages = useApiMessages('product', 'products')

  const getProduct = () => {
    wrapApiCall(api.getProjectProduct(idProject, idProduct, {order_id: orderId} ), {
      onSuccess: (result) => {
        const productPrice = result.data?.default_pricing?.part_price;
        const shippingPrice = result.data?.default_pricing?.shipping_price;
        const installationPrice = result.data?.default_pricing?.installation_price
        setProduct(result.data);
        setSelectedImage({src: result.data?.images[0]?.image, currentIndex: 0});
        setProductOrder({
          ...productOrder,
          product_price: productPrice,
          shipping_price: shippingPrice,
          installation_price: installationPrice,
          includes_shipping: result.data?.default_pricing?.includes_shipping,
          total_product: productOrder.quantity * productPrice,
          total_shipping: productOrder.quantity * shippingPrice * (result.data?.default_pricing?.includes_shipping ? 0: 1),
          total_installation: productOrder.quantity * installationPrice,
          includes_installation : result.data?.default_pricing?.includes_installation,
          installation_is_optional : result.data?.default_pricing?.installation_is_optional,
        })
      },
      errorMessage: messages.list.error,
      onError: (err) => console.log(err),
      onFinally: () => setLoading(false),
    })
  }


  const handleProductOrderQuantityChange = (e) => {
    let quantity = e.target.value? Math.trunc(e.target.value) : ""

    if (quantity > MAX) quantity = MAX;

    setProductOrder({
      ...productOrder,
      quantity: quantity,
      total_product: productOrder.product_price * quantity,
      total_shipping: productOrder.shipping_price * quantity * (productOrder.includes_shipping ? 0: 1),
      total_installation: productOrder.installation_price * quantity,
    });
  }

  const handleNextImage = () => {

    let nextIndex = selectedImage.currentIndex + 1;
    if (nextIndex === Product.images.length) {
      nextIndex = 0;
    }
    setSelectedImage({
      src: Product.images[nextIndex].image,
      currentIndex: nextIndex
    })
  }

  const handleClickOtherImage = (img) => {
    const imageIndex = Product.images?.findIndex(i => i.id === img.id);
    setSelectedImage({src: img.image, currentIndex: imageIndex})
  }

  const onDelete = () => {
    setLoading(true)
    api.deleteProduct(Product?.id).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Product deleted successfully', 'success')
        setOpenDeleteModal(false)
        navigate(ROUTES.ADMIN_PRODUCTS)
      } else if (result.kind === 'bad-data') {
        showMessage(result.errors[0])
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const calculateTotalOrderAmount = () => {
    const totalProduct = productOrder.total_product
    const totalShipping = productOrder.total_shipping
    const totalInstallation = productOrder.total_installation
    const subTotal = totalProduct + (productOrder.includes_shipping ? 0 : totalShipping)
    if (!productOrder.installation_is_optional) {
      return money_fmt(subTotal + totalInstallation);
    } else {
      if (productOrder.includes_installation && productOrder.installation_is_optional) {
        return money_fmt(subTotal);
      } else{
        return money_fmt(subTotal + totalInstallation);
      }
    }
  }

  const handleAddRemoveInstallationToOrder = () => {
    if (productOrder.installation_is_optional) {
      setProductOrder({
        ...productOrder,
        installation_is_optional: false,
        added_to_cart: true
      })
    } else {
      setProductOrder({
        ...productOrder,
        installation_is_optional: true,
        added_to_cart: false
      })
    }
  }

  useEffect(() => {
    getProduct();
  }, [])


  return (
    <AdminLayout
      title={'Manage Products'}
      hasNavigationBack={true}
    >
      <MDBox sx={MainContainer}>
        <Grid container spacing={pxToRem(28)}>
          {/*Product Main Info and Images Section*/}
          <Grid item xs={12} xl={6} height='100%'>
            <Grid container>
              <Grid item xs={10}>
                <MDTypography variant='h3' sx={BrandText}>{Product?.manufacturer?.name}</MDTypography>
                <MDTypography variant='h2' mt={1} mb={2}  sx={{width: '100%', wordWrap: 'break-word'}}>{Product?.name}</MDTypography>

              </Grid>
              <Grid item xs={2} display={'flex'}>
                {Product?.ada_compliant && <MDBox
                  ml={'auto'}
                  component='img'
                  src={AdaLogo}
                  height={pxToRem(40)}
                />}
              </Grid>
            </Grid>
            <Grid item xs={12} display='flex' flexDirection={{xs: 'column', xl: 'row'}} gap={{xs: 0, lg: pxToRem(32)}}>
              <MDBox sx={PrimaryImageContainer}>
                <MDBox component='img' src={selectedImage.src} sx={PrimaryImage}/>
                {Product?.images?.length > 1 && <MDBox
                  component='img'
                  src={NextImage}
                  sx={{position: 'absolute', right: 9, cursor: 'pointer'}}
                  onClick={handleNextImage}
                />}
              </MDBox>
              <Grid container sx={OtherImagesContainer} flexDirection={{xs: 'row', xl: 'column'}}>
                {Product?.images.map((img, i) => (
                  <Grid item sx={OtherImageContainer} key={`img-${i}}`} onClick={() => handleClickOtherImage(img)}>
                    <MDBox component='img' src={img.image} sx={OtherImage}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {/*Product Main Info and Images Section*/}

          {/*Product Information Section*/}
          <Grid item xs={12} xl={6}>
            {/*Product Information Summary*/}
            <MDBox display='flex' justifyContent={{xs: 'center', xl: 'flex-start'}}>
              <MDBox sx={{lineBreak: 'auto'}}>
                <MDTypography variant='h2' mt={pxToRem(5)}>
                  {money_fmt(Product?.default_pricing?.part_price)}
                  <MDTypography variant='button'> /unit</MDTypography>
                </MDTypography>
                <MDTypography variant='button'>Model {Product?.model}</MDTypography>
                <MDBox>
                  <MDTypography variant='button'>{Product?.summary}</MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
            {/*Product Information Summary*/}

            {/*Product General Information Paper*/}
            <MDBox sx={GeneralInfoContainer}>
              <MDTypography variant='h2'>Order</MDTypography>
              <MDBox mt={pxToRem(10)} display='flex' flexDirection='column' gap={pxToRem(15)}>
                <MDBox sx={GeneralInfoItemHeader}>
                  <MDTypography variant='button' fontWeight='regular'>Item</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Unit Cost</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Quantity</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Subtotal</MDTypography>
                </MDBox>
                <MDBox sx={GeneralInfoItem}>
                  <MDTypography variant='button' fontWeight='regular'>Parts</MDTypography>
                  <MDTypography variant='button'
                                fontWeight='regular'>{money_fmt(productOrder.product_price)}</MDTypography>
                  <MDInput
                    sx={InputQuantity}
                    onChange={handleProductOrderQuantityChange}
                    value={productOrder.quantity}
                    inputProps={{max: MAX}}
                    type='number'
                  />
                  <MDTypography variant='button'
                                fontWeight='bold'>{money_fmt(productOrder.total_product)}</MDTypography>
                </MDBox>
                <MDBox sx={GeneralInfoItem}>
                  {productOrder?.includes_shipping ? <>
                    <MDTypography variant='button' fontWeight='regular'>Shipping is included</MDTypography>
                      </> :
                      <>
                          <MDTypography variant='button' fontWeight='regular'>Shipping</MDTypography>
                          <MDTypography variant='button'
                                        fontWeight='regular'>{money_fmt(productOrder.shipping_price)}</MDTypography>
                          <MDTypography variant='button' fontWeight='regular'
                                        sx={{ml: pxToRem(10)}}>{productOrder.quantity}</MDTypography>
                          <MDTypography variant='button'
                                        fontWeight='bold'>{money_fmt(productOrder.total_shipping)}</MDTypography>
                      </>}
                </MDBox>
                {/*Installetion*/}
                {!Product?.default_pricing?.installation_is_optional &&
                  <MDBox sx={GeneralInfoItem}>
                    {productOrder?.includes_installation?
                      <>
                        <MDTypography variant='button' fontWeight='regular'>Installation</MDTypography>
                        <MDTypography variant='button'
                                      fontWeight='regular'>{money_fmt(productOrder.installation_price)}</MDTypography>
                        <MDTypography variant='button' fontWeight='regular'
                                      sx={{ml: pxToRem(10)}}>{productOrder.quantity}</MDTypography>
                        <MDTypography variant='button'
                                      fontWeight='bold'>{money_fmt(productOrder.total_installation)}</MDTypography>
                      </>:
                      <MDTypography variant='button'
                                    fontWeight='regular'>Installation not included</MDTypography>
                    }
                  </MDBox>
                }
                {productOrder.added_to_cart &&
                  <MDBox sx={GeneralInfoItem}>
                    <MDTypography variant='button' fontWeight='regular'>Installation</MDTypography>

                    <MDTypography variant='button'
                                  fontWeight='regular'>{money_fmt(productOrder.installation_price)}</MDTypography>
                    <MDTypography variant='button' fontWeight='regular'
                                  sx={{ml: pxToRem(10)}}>{productOrder.quantity}</MDTypography>
                    <MDTypography variant='button'
                                  fontWeight='bold'>{money_fmt(productOrder.total_installation)}</MDTypography>
                  </MDBox>
                }
                {Product?.default_pricing?.installation_is_optional &&
                  <MDBox display='flex' alignItems='center' gap={pxToRem(5)}>
                    <BuildOutlinedIcon fontSize={'medium'} sx={({palette: {text}}) => ({color: text.bold})}/>
                    <MDTypography variant='button' sx={GeneralInfoText}>
                      Include installation at {' '}
                      {money_fmt(Product?.default_pricing?.installation_price)} /unit
                    </MDTypography>
                    <MDTypography
                      variant='button'
                      sx={[GeneralInfoText, {color: productOrder?.installation_is_optional ? '#0B7340' : '#b60808', cursor: 'pointer'}]}
                      ml={'auto'}
                      onClick={handleAddRemoveInstallationToOrder}
                    >
                      {`${productOrder?.installation_is_optional ? '+ Add' : '- Remove'} installation ${productOrder?.installation_is_optional ? 'to' : 'from'} this order`}
                    </MDTypography>
                  </MDBox>
                }
                <MDBox sx={Divider}/>
              </MDBox>
              <MDBox mt={pxToRem(14)} display='flex' alignItems='center' justifyContent='space-between'>
                <MDTypography variant='button' fontWeight='bold'>Grand Total *</MDTypography>
                <MDTypography variant='h2'>{calculateTotalOrderAmount()}</MDTypography>
              </MDBox>
              {!loginStore.isViewer &&<MDButton color='secondary' disabled={productOrder.quantity <= 0} sx={{width: '100%', mt: pxToRem(24)}} onClick={() => setOpenCartModal(true)}>Add to Cart</MDButton>}
              <MDBox mt={pxToRem(17)}>
                <MDTypography variant='caption' fontWeight='regular'>* Tax to be added later by vendor based on
                  location.</MDTypography>
              </MDBox>

            </MDBox>
            {/*Product General Information Paper*/}

            {/*Accordion Product Details*/}
            <MDBox mt={pxToRem(24)}>
              <Accordion sx={AccordionBox}>
                <AccordionSummary
                  expandIcon={<img src={ExpandMore} alt='expand_more' style={{marginRight: pxToRem(10)}}/>}
                  sx={{minHeight: 'auto !important'}}
                >
                  <MDTypography variant='h2'>Product Details</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDTypography
                    variant='button'
                    sx={GeneralInfoText}
                    dangerouslySetInnerHTML={{ __html: convertStringToList(Product?.details) }}
                  ></MDTypography>
                  <MDBox>
                    <MDTypography variant='button' fontWeight='bold' sx={{textDecoration: 'underline'}}>
                      Features
                    </MDTypography>
                    <ul>
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        dangerouslySetInnerHTML={{ __html: convertStringToList(Product?.features) }}
                      />
                    </ul>
                  </MDBox>

                </AccordionDetails>
              </Accordion>
            </MDBox>
            {/*Accordion Product Details*/}

            {/*Accordion Cut Sheet*/}
            <MDBox sx={AccordionDivider}/>
            <MDBox mt={pxToRem(18)}>
              <Accordion sx={AccordionBox}>
                <AccordionSummary
                  expandIcon={<img src={ExpandMore} alt='expand_more' style={{marginRight: pxToRem(10)}}/>}
                  sx={{minHeight: 'auto !important'}}
                >
                  <MDTypography variant='h2'>Cut Sheet</MDTypography>
                </AccordionSummary>
                {Product?.cut_sheets?.map((cutSheet) => (
                    <MDBox sx={FilecardContainer} key={cutSheet.id}>
                      <MDBox display='flex' alignItems='center'>
                        <Icon sx={(th) => ({ml: pxToRem(11), mr: 2})} fontSize='medium'>attachment</Icon>
                        <MDTypography sx={FilenameText}>
                          {cutSheet.cut_sheet_file?.name || cutSheet?.cut_sheet_file_name}
                        </MDTypography>
                      </MDBox>
                      <MDTypography
                        sx={{textDecoration: 'underline', mr: pxToRem(10), cursor: 'pointer'}}
                        variant='button'
                        fontWeight='regular'
                        color='tertiary'
                        onClick={() => openInNewTab(cutSheet.cut_sheet_file)}>
                        Download
                      </MDTypography>
                    </MDBox>
                  )
                )}
              </Accordion>
            </MDBox>
            {/*Accordion Cut Sheet*/}

            {/*Accordion Specifications*/}
            <MDBox sx={AccordionDivider}/>
            <MDBox mt={pxToRem(18)}>
              <Accordion sx={AccordionBox}>
                <AccordionSummary
                  expandIcon={<img src={ExpandMore} alt='expand_more' style={{marginRight: pxToRem(10)}}/>}
                >
                  <MDTypography variant='h2'>Specifications</MDTypography>
                </AccordionSummary>
                <ul>
                  <MDTypography variant='button' fontWeight='regular' dangerouslySetInnerHTML={{ __html: convertStringToList(Product?.specs) }}/>
                </ul>
              </Accordion>
            </MDBox>
            {/*Accordion Specifications*/}

          </Grid>
          {/*Product Information Section*/}
        </Grid>
        <ConfirmDialogModal
          title={'Do you want to delete this product?'}
          description={`${Product?.name}`}
          cancelText={'Cancel'}
          confirmText={'Confirm'}
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          handleConfirm={() => onDelete()}
        />
        <CartSectionModal
          open={openCartModal}
          product={Product}
          quantity={productOrder.quantity}
          installation={productOrder.added_to_cart}
          shipping={productOrder?.includes_shipping}
          order={parseInt(orderId)}
          handleClose={() => setOpenCartModal(false)}
        />
      </MDBox>

    </AdminLayout>
  );
}

export default observer(ProductDetailOrderPage);
