// noinspection JSUnresolvedFunction

import {FormikProvider, useFormik} from "formik";
import FormikInput from "../../../components/FormikInput";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import {memo, useEffect, useState} from "react";
import { useApi} from "../../../services/helpers";
import {useDebouncedValidate} from "../../../shared/formik_debounce_validate";
import AddBaseModal from "../../../components/add-base-modal/AddBaseModal";
import MDTypography from "../../../components/MDTypography";
import {renderBrandLogo} from "./utils";
import {apiMessages, useApiMessages} from "../../../services/api/api-messages";
import {wrapApiCall} from "../../../services/helpers_ts";

Yup.addMethod(Yup.mixed, 'foreignKey', function (args) {
  return this.test('test-object-id', args.message, (value) => value && value.id);
});


const validationSchema = Yup.object().shape({
  name: Yup.string().required("Sub-contractor's name is required"),
  address: Yup.string().required("Sub-contractor's address is required"),
  category: Yup.mixed().foreignKey("Sub-contractor's category is required"),
  logo: Yup.mixed().nullable(),
})

const defaultFK = {
  id: null,
  name: ""
}

export const SubContractorsModalForm = memo(({open, item, onClose, onSave, setLoading, loading}) => {

  const api = useApi()
  const [categories, setCategories] = useState([])
  const messages = useApiMessages('sub-contractor', 'sub-contractor')

  const formik = useFormik({
    initialValues: {
      id: item?.id ? item.id : null,
      name: item ? item.name : "",
      address: item ? item.address : "",
      category: item ? {id: item.category, name: item.category_name} : {...defaultFK},
      logo: item ? item.logo : null,
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => save(values),
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  useEffect(() => {
    getCategories({search: ''})
  }, [])

  const save = (values) => {
    setLoading(true)
    const keys = []
    if (values.logo) {
      if (item) {
        if (item.logo !== values.logo) {
          keys.push(['logo'])
        } else {
          delete values['logo']
        }
      } else {
        keys.push(['logo'])
      }
    }

    let data = {...values}
    data["category"] = values.category.id

    if (item?.id) {
      editSubContractor(data, keys)
    } else {
      createSubContractor(data, keys)
    }
  }

  const getCategories = ({search}) => {
    setLoading(true)
    wrapApiCall(api.getCategories({search: search, page: 1, ordering: '', page_size: 25}), {
      onSuccess: (result) => { setCategories(result.data.results) },
      errorMessage: apiMessages.list.error('categories'),
      onFinally: () => setLoading(false)
    })
  }

  const editSubContractor = (data) => {
    if (loading || !item) return
    setLoading(true)
    api.editSubContractor(data).handle({
      successMessage: messages.update.success,
      onSuccess: onSave,
      errorMessage: messages.update.error,
      onFinally: () => setLoading(false)
    })
  }

  const createSubContractor = (data) => {
    if (loading) return
    setLoading(true)
    api.createSubContractor(data).handle({
        successMessage: messages.create.success,
        onSuccess: onSave,
        errorMessage: messages.create.error,
        onFinally: () => setLoading(false)
    })
  }

  return (
    <AddBaseModal
      open={open}
      handleClose={() => {
        formik.resetForm()
        onClose()
      }}
      title={item?.id ? "Edit Sub-Contractor" : "Add Sub-Contractor"}
      buttonText={"Save"}
      onSubmit={formik.handleSubmit}
      loading={loading}
    >
      <MDBox
        display='flex'
        flexDirection='column'
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
      >
        <FormikProvider value={formik}>
          <FormikInput name="name" label="Sub-Contractor Name"/>

          <FormikInput
            type={"autocomplete"}
            value={formik.values.category}
            fieldName={"category"}
            label={"Category"}
            options={categories}
            accessKey={"name"}
            onInputChange={(value) => {
              getCategories({search: value.name})
            }}
            onChange={(value) => {
              formik.setFieldValue('category', value)
            }}/>

          <FormikInput name="address" label="Sub-Contractor Address"/>

          <FormikInput
            fullWidth
            filename={"logo"}
            type={"file"}
            name={"logo"}
            label={"Sub-Contractor Logo"}
            onChange={(e) => {
              const fileValue = e.target.files[0]
              formik.setFieldValue("logo", fileValue);
            }}
          />
          {item?.id && item.logo && (
            <MDBox display={"flex"} flexDirection={"row"} gap={"5px"} alignItems={"center"}>
              <MDTypography variant={"h5"} mt={"-4px"} fontWeight={"bold"} fontSize={12}>Current logo:</MDTypography>
              {renderBrandLogo(item)}
            </MDBox>
          )}
        </FormikProvider>
      </MDBox>
    </AddBaseModal>
  )
})

