import {FormikProvider, useFormik} from "formik";
import FormikInput from "../../../../components/FormikInput";
import MDBox from "../../../../components/MDBox";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import {memo, useEffect, useState} from "react";
import {showMessage, useApi} from "../../../../services/helpers";
import {useDebouncedValidate} from "../../../../shared/formik_debounce_validate";
import MDTypography from "../../../../components/MDTypography";
import AddBaseModal from "../../../../components/add-base-modal/AddBaseModal";
import {useApiMessages} from "../../../../services/api/api-messages";

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Region name is required'),
  address: Yup.string().required('Region address is required'),
  company: Yup.object().nullable().required('Company is required'),
})


export const RegionModalForm = memo(({open, item, onClose, onSave, setLoading, loading}) => {
  const api = useApi()
  const [companies, setCompanies] = useState([]);
  const messages = useApiMessages('region', 'regions')


  const formik = useFormik({
    initialValues: {
      id: item?.id ? item.id : null,
      name: item ? item.name : "",
      address: item ? item.address : "",
      company: item ? {id: item.company, name: item.company_name} : null,
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => save(values),
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  const save = (values) => {
    let data = {...values}
    setLoading(true)
    data["company"] = values.company.id
    if (item?.id) {
      editRegion(data)
    } else {
      createRegion(data)
    }
  }

  const getCompanies = ({search}) => {
    api.getCompanies({search: search, page: 1, ordering: '', page_size: 25}).then((result) => {
      if (result.kind === 'ok') {
        setCompanies(result.data.results)
      }
    })
  }


  const editRegion = (data) => {
    api.editRegion(data).handle( {
        successMessage: messages.update.success,
        onSuccess: onSave,
        errorMessage: messages.update.error,
        onError: (result) => { formik.setErrors(result.errors) },
        onFinally: () => setLoading(false)
      }
    )
  }

  const createRegion = (data) => {
    api.createRegion(data).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Region created successfully', 'success')
        onSave()
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }


  useEffect(() => {
    getCompanies({search: ''})
  }, [])


  return (
    <AddBaseModal
      open={open}
      handleClose={() => {
        formik.resetForm()
        onClose()
      }}
      title={item?.id ? "Edit Region" : "Add Region"}
      buttonText={"Save"}
      onSubmit={formik.handleSubmit}
      loading={loading}
    >
      <MDBox
        display='flex'
        flexDirection='column'
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
      >
        <FormikProvider value={formik}>
          <FormikInput name="name" label="Region Name"/>
          <FormikInput name="address" label="Region Address"/>
          <FormikInput
            type={"autocomplete"}
            value={formik.values.company}
            fieldName={"company"}
            label={"Company"}
            options={companies}
            accessKey={"name"}
            onInputChange={(value) => {
              getCompanies({search: value})
            }}
            onChange={(value) => {
              formik.setFieldValue('company', value)
            }}/>
        </FormikProvider>
      </MDBox>
    </AddBaseModal>
  )
})
