
export const dataTableModel = {
  columns: [
    {Header: "Project Name", accessor: "name"},
    {Header: "Address", accessor: "address"},
    {Header: "Company", accessor: "company__name"},
    {Header: "Region", accessor: "region__name"},
    {Header: "Brand", accessor: "brand__name"},
  ],
  rows: [
  ],
};