import pxToRem from "../../../assets/theme/functions/pxToRem";
import {Box, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import logo from "../../../assets/icons/dolovo-D.png";
import photo from "../../../assets/images/photo.png";
import {date_fmt} from "../../../services/helpers";
import MDTypography from "../../../components/MDTypography";
import * as React from "react";

export const dataTableModel = {
  columns: [
    {Header: "From", accessor: "from_user"},
    {Header: "Title", accessor: "title"},
    {Header: "Description", accessor: "description", width: pxToRem(300)},
    {Header: "Date", accessor: "timestamp", width: pxToRem(180)},
    {Header: "Is read", accessor: "read", disableOrdering: true},
  ],
  rows: [
  ],
};


const renderCheck = (item, markAsRead) => {

  return (
    <FormGroup sx={{cursor: 'pointer'}}>
      <FormControlLabel
        onClick={(event) => {
          event.stopPropagation()
          if (item.raw_read) return
          markAsRead(item.id)
        }}
        control={<Checkbox disabled checked={item.raw_read} sx={{ '.MuiSvgIcon-root': { color: '#00914C', border: '#00914C', cursor: 'pointer'} }} />}
        label={''}
      />
    </FormGroup>
  )
}


export const renderTableRow = (item, markAsRead) => {
  item.timestamp = date_fmt(item.timestamp)
  item.raw_read = item.read
  item.read = renderCheck(item, markAsRead)
  return item
}
