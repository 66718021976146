import * as Yup from "yup";

const REQUIRED_FIELD_MSG = "This field is required"

Yup.addMethod(Yup.mixed, 'foreignKey', function (args) {
  return this.test('test-object-id', args.message, (value) => value && value.id);
});


export const brandValidationSchema = Yup.object({
  name: Yup.string('').required(REQUIRED_FIELD_MSG),
  region: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
  company: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
  logo: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  contract_template: Yup.mixed().nullable(),
  packages: Yup.array().of(Yup.object({
    name: Yup.string('').required(REQUIRED_FIELD_MSG),
    logo: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
    categories: Yup.array().of(Yup.object({
      category: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
      manufacturer: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
      products: Yup.array().of(Yup.object({
        product: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
        quantity: Yup.number('').required(REQUIRED_FIELD_MSG).min(1),
        include_installation: Yup.boolean()
      })).min(1)
    })).min(1)
  })),
  drawings: Yup.array().of(Yup.object({
    name: Yup.string('').required(REQUIRED_FIELD_MSG),
    architectural_files: Yup.array().of(Yup.object({
      drawing_file: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable()
    })).min(1),
    images: Yup.array().of(Yup.object({
      image: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable()
    }))
  })),
  categories: Yup.array().of(Yup.object({
    category: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
    manufacturer: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
    products: Yup.array().of(
      Yup.mixed().foreignKey(REQUIRED_FIELD_MSG)
    ).min(1)
  }))
});

export const InitialProduct = {
  id: null,
  name: ""
}
export const InitialDrawingImage = {
  image: null
}

export const InitialArchitectural = {
  drawing_file: null,
}

export const InitialDrawing = {
  name: "",
  architectural_files: [
  ],
  images: []
}

export const InitialBrandCategory = {
  category: null,
  manufacturer: null,
  products: []
}

export const InitialPackageCategoryProduct = {
  product: null,
  quantity: 0,
  include_installation: false
}
export const InitialPackageCategory = {
  category: null,
  manufacturer: null,
  products: [
  ]
}

export const InitialPackage = {
  name: "",
  logo: "",
  categories: [
  ]
}


export const initialBrand = {
  name: "",
  company: null,
  region: null,
  logo: null,
  contract_template: null,
  packages: [
  ],
  categories: [
  ],
  drawings: [
  ]
}
