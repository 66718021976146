import {FormikProvider, useFormik} from "formik";
import FormikInput from "../../../components/FormikInput";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import {memo, useEffect, useState} from "react";
import {useApi} from "../../../services/helpers";
import {useDebouncedValidate} from "../../../shared/formik_debounce_validate";
import AddBaseModal from "../../../components/add-base-modal/AddBaseModal";
import MDTypography from "../../../components/MDTypography";
import {renderCategoryLogo} from "./utils";
import {useApiMessages} from "../../../services/api/api-messages";
import MDInput from "../../../components/MDInput";
import trash from "assets/icons/trash.svg"

Yup.addMethod(Yup.array, "unique", function (message) {
  return this.test("unique", message, function (list) {
    const mapper = x => x.name;
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `tags[${idx}].name`,
      message: message,
    });
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
  logo: Yup.mixed().nullable(),
})


export const CategoryModalForm = memo(({open, item, onClose, onSave, setLoading, loading}) => {
  const api = useApi()
  const messages = useApiMessages('category', 'categories')
  const tagsMessage = useApiMessages('tag', 'tags')
  const [addTag, setAddTag] = useState('')
  const [tags, setTags] = useState([]);


  const formik = useFormik({
    initialValues: {
      id: item?.id ? item.id : null,
      name: item?.id ? item.name : "",
      logo: item?.id ? item.logo : null,
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => save(values),
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });


  const save = (values) => {
    setLoading(true)
    const keys = []
    if (values.logo) {
      if (item) {
        if (item.logo !== values.logo) {
          keys.push(['logo'])
        } else {
          delete values['logo']
        }
      } else {
        keys.push(['logo'])
      }
    }
    const filters = JSON.stringify(values.filters)
    if (item?.id) {
      editCategory({...values, filters}, keys)
    } else {
      createCategory({...values, filters}, keys)
    }
  }



  const editCategory = (data) => {
    api.editCategory(data).handle({
          successMessage: messages.update.success,
          onSuccess: onSave,
          errorMessage: messages.update.error,
          onError: (result) => { formik.setErrors(result.errors) },
          onFinally: () => setLoading(false)
        }
    )
  }

  const createCategory = (data) => {
    api.createCategory(data).handle({
          successMessage: messages.create.success,
          onSuccess: onSave,
          errorMessage: messages.create.error,
          onError: (result) => { formik.setErrors(result.errors) },
          onFinally: () => setLoading(false)
        }
    )
  }

  const getAllTags = () => {
    api.getAllTags(item?.id).handle({
          onSuccess: (result) => setTags(result?.data.results),
          errorMessage: tagsMessage.get.error,
          onFinally: () => setLoading(false)
        }
    )
  }

  const createTag = () => {
    setLoading(true)
    api.createTag(item?.id, {name: addTag}).handle({
        successMessage: tagsMessage.create.success,
        onSuccess: ()=> getAllTags(),
        errorMessage: tagsMessage.create.error,
        onFinally: () => {
          setLoading(false)
          setAddTag('')
        }
      }
    )
  }

  const deleteTag = (categoryId, tagId) => {
    api.deleteTag(categoryId, tagId).handle({
        successMessage: tagsMessage.delete.success,
        onSuccess: ()=> getAllTags(),
        errorMessage: tagsMessage.delete.error,
        onFinally: () => setLoading(false)
      }
    )
  }


  useEffect(() => {
    if(item){
      getAllTags()
    }
  }, []);

  return (
    <AddBaseModal
      open={open}
      handleClose={() => {
        formik.resetForm()
        onClose()
      }}
      title={item?.id ? "Edit Category" : "Add Category"}
      buttonText={"Save"}
      onSubmit={formik.handleSubmit}
      loading={loading}
    >
      <MDBox
        display='flex'
        flexDirection='column'
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
        maxHeight={pxToRem(600)}
        sx={{ overflow: 'auto' }}
      >
        <FormikProvider value={formik}>
          <FormikInput name="name" label="Category Name"/>
          {item && (
            <>
              <MDInput type={'text'} value={addTag} label="Tag" onChange={(e) => setAddTag(e.target.value)}  />
              {tags?.length ? tags?.map((tag, indexFromMap) => (
                <MDBox
                  key={`id-tag-${indexFromMap}`}
                  display={'flex'}
                  width={'50%'}
                  justifyContent={'space-between'}
                  borderRadius={'xl'}
                  border={'1px solid grey'}
                  p={0.5}
                >
                  <MDTypography
                    variant={'button'}
                    color={'tertiary'}
                    fontWeight="regular"
                  >
                    {tag?.name}
                  </MDTypography>
                  <MDBox component={'img'} src={trash} onClick={() => deleteTag(item.id, tag.id)} />
                </MDBox>
              )) : null}
              <MDTypography
                variant="button"
                color="info"
                fontWeight="regular"
                sx={{cursor: 'pointer'}}
                textAlign="right"
                onClick={createTag}
              >
                + Create Tag
              </MDTypography>
            </>
            )}
          <FormikInput
            fullWidth
            filename={"category_logo"}
            type={"file"}
            name={"logo"}
            label={"Category Logo"}
            onChange={(e) => {
              const fileValue = e.target.files[0]
              formik.setFieldValue("logo", fileValue);
            }}
          />
          {item?.id && item.logo && (
            <MDBox display={"flex"} flexDirection={"row"} gap={"5px"} alignItems={"center"}>
              <MDTypography variant={"h5"} mt={"-4px"} fontWeight={"bold"} fontSize={12}>Current logo:</MDTypography>
              {renderCategoryLogo(item)}
            </MDBox>
          )}
        </FormikProvider>
      </MDBox>
    </AddBaseModal>
  )
})
