import pxToRem from "assets/theme/functions/pxToRem";
import check from 'assets/icons/check.svg';

export const Container = ({palette: {white, table}}) => ({
  background: white.main,
  border: `1px solid ${table.border}`,
  borderRadius: '10px',
  overflow: 'auto',
  paddingBottom: pxToRem(28),
  width: pxToRem(870),
})

export const Title = ({palette: {table}}) => ({
  borderBottom: `1px solid ${table.border}`,
  fontSize: pxToRem(16),
  fontWeight: 400,
  letterSpacing: pxToRem(0.5),
  lineHeight: pxToRem(24),
  pb: pxToRem(20),
  pl: pxToRem(24),
  pt: pxToRem(25),
  width: '100%',
})

export const FormContainer = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(4),
  mt: pxToRem(24),
})

export const FormSection = () => ({
  px: pxToRem(44),
})

export const FieldsContainer = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(4),
  width: pxToRem(355),
})

export const FileFieldsContainer = () => ({
  ...FieldsContainer(),
  gap: pxToRem(22),
})

export const BooleanFieldsContainer = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(16),
  mt: pxToRem(26),
  width: pxToRem(355)
})

export const BooleanTitle = () => ({
  fontSize: pxToRem(12),
  fontWeight: 400,
})

export const BooleanBox = () => ({
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(8),
})

export const CheckboxItem = ({palette: {secondary, table}}) => ({
  '&.MuiCheckbox-root': {
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    border: `1px solid ${table.border}`,
    borderRadius: '4px',
    height: pxToRem(16),
    width: pxToRem(16),
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundImage: `url(${check}),linear-gradient(195deg, ${secondary.main},${secondary.main})`,
      border: 'none',
      borderRadius: '4px',
      color: secondary.main,
      height: pxToRem(16),
      width: pxToRem(16),
    },
  },
})

export const CheckboxValue = () => ({
  fontSize: pxToRem(14),
  fontWeight: 400,
  pt: pxToRem(3),
})

export const AddFilterText = ({palette: {info}}) => ({
  color: info.main,
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 400,
  justifyContent: 'flex-end',
})

export const ActionsBox = () => ({
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(11),
  justifyContent: 'flex-end',
  px: pxToRem(44),
})

export const SectionTitle = (theme) => ({
  ...Title(theme),
  alignItems: 'center',
  display: 'flex',
  gap: pxToRem(16),
})

export const SectionTitleText = (theme) => ({
  ...Title(theme),
  border: 'none',
  pb: 0,
  pl: 0,
  pt: 0,
  width: 'fit-content'
})

export const AddButton = ({palette: {icons}}) => ({
  cursor: 'pointer',
  color: icons.add,
})

export const RemoveButton = ({palette: {icons}}) => ({
  cursor: 'pointer',
  color: icons.remove,
})
