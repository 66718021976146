import {observer} from "mobx-react";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {ROUTES} from "../../../services/constants";
import {Card} from "@mui/material";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../../components/FormikInput";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { showMessage, useApi } from "../../../services/helpers";

const initialValues = {
  current_password: "",
  new_password: "",
  confirm_new_password: ""
}
const validationSchema = {
  current_password: Yup.string().required('Current password is required'),
  new_password: Yup.string().required('New password is required'),
  confirm_new_password: Yup.string().required('Confirm password is required')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
}
const ChangePassword = () => {
  const api = useApi()

  const changePassword = (values)=>{
    api.changePassword(values).then(response => {
      if(response.kind === "ok"){
        showMessage('Password updated successfully', 'success')
      }else if(response.errors){
        let message = ""
        Object.keys(response.errors).forEach(function(key, index) {
          message +=  response.errors[key].errors.join(' \n')
        });
        showMessage(message)
      }
    }).catch(reason => {
      showMessage()
      console.log(reason);
    })
  }

  return (
    <DashboardProjectManagerLayout
      title={'Change Password'}
      backButton
      backButtonText={'Home / Settings'}
      backRoute={ROUTES.USER_ACCOUNT_SETTINGS}
      // marginHorizontal={50}
      alignContent={"center"}
    >
        <Card sx={{ minWidth: '700px', p: pxToRem(52), display: "flex", flexDirection: "column", alignItems: "center"}} >
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={values =>changePassword(values) }
          >
            {(formik)=> (
              <Form style={{ display: "flex", flexDirection: "column", alignItems: "center", width: '100%', maxWidth: pxToRem(355), gap: 5}}>
                <FormikInput type={"password"} label={"Current Password"} name={"current_password"}  sx={{ width: "100%" }}/>
                <FormikInput type={"password"} label={"New Password"} name={"new_password"} sx={{ width: "100%", mt:2}} />
                <FormikInput type={"password"} label={"Confirm New Password"} name={"confirm_new_password"} sx={{ width: "100%", mt:2 }}/>
                <MDButton type={"submit"} color={"primary"} sx={{ width: '100%', maxWidth: pxToRem(288), mt: pxToRem(20) }} >Update Password</MDButton>
              </Form>
            )}
          </Formik>
        </Card>
    </DashboardProjectManagerLayout>
  );
}

export default observer(ChangePassword);
