import {observer} from "mobx-react";
import {Grid} from '@mui/material';

// Components
import DashboardProjectManagerLayout from "components/DashboardProjectManager";

// Icons
import InboxIcon from 'assets/icons/inbox.svg';
import KitchenIcon from 'assets/icons/kitchen.svg';
import LightBulbIcon from 'assets/icons/lightbulb.svg';
import LumberIcon from 'assets/icons/lumber.svg';
import WindowsIcon from 'assets/icons/window.svg';

function ProjectManagerDashboardHomeScreen() {


  return (
    <DashboardProjectManagerLayout
      title={'Order By Category'}
    >
      <Grid container justifyContent='space-between'>
      {/*TODO: make a map of the categories to render the card according to the design*/}
      </Grid>
    </DashboardProjectManagerLayout>
  );
}

export default observer(ProjectManagerDashboardHomeScreen);
