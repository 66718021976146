import { observer } from "mobx-react";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import { Box } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { money_fmt } from "../../../../services/helpers";
import { MiscCostCard } from "../miscellaneous-card";
import * as React from "react";
import './style.css';

const OrderPdf = ({ order }) => {
  return (
    <MDBox sx={styles.cardContainer}>
      <table className="table"  cellpadding="0" cellspacing="0" width="100%" border="0">
        <tr align="top">
           <td style={{width: '15%',paddingRight:7}} align="top">
              <MDTypography sx={styles.title}>Project Manager</MDTypography>
              <MDTypography width="100%" pt={0.6} sx={styles.boldTextHeader}>{order?.project_manager?.name}</MDTypography>
           </td>
           <td style={{width:'15%',paddingRight:7}} align="top">
              <MDTypography sx={styles.title}>Email</MDTypography>
              <MDTypography width="100%" pt={0.6} sx={styles.boldTextHeader}>{order?.project_manager?.email}</MDTypography>
           </td>
           <td style={{width:'15%',paddingRight:7}} align="top">
              <MDTypography sx={styles.title}>Project</MDTypography>
              <MDTypography width="100%" pt={0.6} sx={styles.boldTextHeader}>{order?.project?.name}</MDTypography>
           </td>
           <td style={{width:'20%',paddingRight:7}} align="top">
              <MDTypography sx={styles.title}>Project Address</MDTypography>
              <MDTypography width="100%" pt={0.6} sx={styles.boldTextHeader}>{order?.project?.address}</MDTypography>
           </td>
           <td style={{width:'20%',paddingRight:7}} align="top">
              <MDTypography sx={styles.title}>Corporate Address</MDTypography>
              <MDTypography width="100%" pt={0.6} sx={styles.boldTextHeader}>{order?.company?.address}</MDTypography>
           </td>
        </tr>
      </table>
      <MDBox sx={styles.titlesContainer}>
        <MDTypography sx={{ ...styles.title, width: pxToRem(400) }}>Items</MDTypography>
        <MDBox display={"flex"} justifyContent={"space-between"} width={"40%"}>
          <MDTypography sx={styles.title}>Unit Cost</MDTypography>
          <MDTypography sx={styles.title}>Quantity</MDTypography>
          <MDTypography sx={styles.title}>Subtotal</MDTypography>
        </MDBox>
      </MDBox>
      {order?.sections?.length > 0 &&
        order.sections.map((section, index) => {
          const total = section.lines.reduce(
            (acc, line) => acc + line.unit_price * line.quantity,
            0
          );

          return (
            <MDBox key={section.id}>
              <MDTypography
                sx={{
                  ...styles.titles,
                  borderTop: index ? "1px solid #DBDBDB" : "",
                  pb: pxToRem(22),
                }}
              >
                {section.name}
              </MDTypography>
              {section.lines.map((line) => (
                <MDBox sx={styles.container}>
                  <MDBox display={"flex"} width={"60%"}>
                    <Box
                      component={"img"}
                      src={line.product.main_img}
                      alt={"item-to-buy"}
                      sx={styles.image}
                    />
                    <MDBox>
                      <MDTypography sx={styles.boldText}>{line.product.name}</MDTypography>
                      <MDTypography sx={styles.models}>Model {line.product.model}</MDTypography>
                      <MDTypography sx={styles.models}>
                        {line.installation ? `✓ Installation ${line.installation}` : ""}
                      </MDTypography>
                      <MDTypography sx={styles.models}>
                        {line.shipping ? `✓ Shipping ${line.shipping}` : ""}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    width={"40%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <MDBox>
                      <MDTypography sx={styles.prices}>{money_fmt(line.unit_price)}</MDTypography>
                    </MDBox>
                    <MDTypography sx={styles.prices}>{line.quantity}</MDTypography>
                    <MDBox alignItems={"center"}>
                      <MDTypography sx={{ ...styles.boldText, width: "100%" }}>
                        {money_fmt(line.unit_price * line.quantity)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
          );
        })}
      <MDBox
        display={"flex"}
        justifyContent={"space-between"}
        pb={2}
        m={3}
        mb={1}
        borderBottom={"1px solid #DBDBDB"}
      >
        <MDTypography variant={"h6"}>Miscellaneous Costs</MDTypography>
      </MDBox>
      <MDBox
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        pb={2}
        m={1}
        ml={5}
        mr={3}
        mb={1}
      >
        {order?.miscellaneous_costs?.map((item) => (
          <MiscCostCard misc_cost={item} order={order} isForPrint={true} />
        ))}
      </MDBox>
      <MDBox pt={2.5} pl={2.8} pb={3.8} pr={2.8} bgColor={"#0B7340"} mt={2}>
        <MDBox display={"flex"} justifyContent={"space-between"}>
          <MDTypography variant={"h6"} color={"white"}>
            Price
          </MDTypography>
          <MDTypography variant={"h6"} color={"white"}>
            {money_fmt(order?.total - order?.taxes)}
          </MDTypography>
        </MDBox>
        <MDBox display={"flex"} justifyContent={"space-between"}>
          <MDTypography variant={"h6"} color={"white"}>
            Taxes
          </MDTypography>
          <MDTypography variant={"h6"} color={"white"}>
            {money_fmt(order?.taxes)}
          </MDTypography>
        </MDBox>
        <MDBox display={"flex"} justifyContent={"space-between"}>
          <MDTypography variant={"h6"} color={"white"}>
            Grand Total
          </MDTypography>
          <MDTypography variant={"h2"} color={"white"}>
            {money_fmt(order?.total)}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default observer(OrderPdf);
const styles = {
  headerTitles: {
    display: "flex",
    justifyContent: "space-between",
    width: "30%",
    paddingLeft: pxToRem(30),
    gap: pxToRem(32),
  },
  titles: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    pt: pxToRem(20),
    pb: pxToRem(12),
  },
  titlesClickable: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    pt: pxToRem(20),
    pb: pxToRem(12),
    cursor: "pointer",
  },
  activeTitle: {
    borderBottom: "3px solid #81D61E",
  },
  activeVersion: {
    alignItems: "center",
    backgroundColor: "#D9F3BC",
    cursor: "pointer",
  },
  inactiveVersion: {
    alignItems: "center",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  cardContainer: {
    p: pxToRem(28),
  },
  headerOptions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  orderIdContainer: {
    display: "flex",
    alignItems: "center",
  },
  v2Card: {
    backgroundColor: "#0B7340",
    width: pxToRem(50),
    height: pxToRem(50),
    borderRadius: pxToRem(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  v2CardText: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    backgroundColor: "#0B7340",
    color: "#fff",
  },
  undoIcon: {
    width: pxToRem(20),
    color: "#0B7340",
    ml: pxToRem(5),
  },
  orderId: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
  },
  version2: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    color: "#0B7340",
    pl: pxToRem(4),
  },
  date: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(17),
    color: "#6A686D",
  },
  showChanges: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: pxToRem(14),
  },
  versionText: {
    fontSize: pxToRem(12),
    fontWeight: 600,
    lineHeight: pxToRem(14),
    color: "#fff",
  },
  visibility: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DBDBDB",
    width: pxToRem(35),
    height: pxToRem(35),
    borderRadius: pxToRem(8),
  },
  versionBadge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#9D9A9A",
    width: pxToRem(40),
    height: pxToRem(40),
    maxHeight: pxToRem(40),
    minWidth: pxToRem(40),
    borderRadius: pxToRem(8),
    cursor: "pointer",
  },
  versionBadgeActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0B7340",
    width: pxToRem(40),
    height: pxToRem(40),
    maxHeight: pxToRem(40),
    minWidth: pxToRem(40),
    borderRadius: pxToRem(8),
    cursor: "pointer",
  },
  visibilityIcon: {
    color: "#9D9A9A",
  },
  titlesContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#ECECEC",
    borderRadius: pxToRem(8),
    p: pxToRem(10),
    mt: pxToRem(36),
    mb: pxToRem(20),
  },
  title: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(17),
  },
  subtotalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    pb: pxToRem(22),
    gap: pxToRem(12),
  },
  subtotal: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    textAlign: "end",
  },
  total: {
    fontSize: pxToRem(18),
    fontWeight: 600,
    lineHeight: pxToRem(21),
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    pb: pxToRem(26),
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  image: {
    mr: pxToRem(17),
    width: "100%",
    height: "100%",
    maxWidth: pxToRem(80),
    maxHeight: pxToRem(80),
    objectFit: "cover",
  },
  boldText: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    width: pxToRem(330),
    pb: pxToRem(4),
  },
  boldTextHeader:{
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    pb: pxToRem(4),
    
  },
  models: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: pxToRem(14),
    pb: pxToRem(4),
  },
  prices: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(17),
  },
  removeText: {
    pt: pxToRem(20),
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    color: "#0B7340",
    cursor: "pointer",
  },
};
styles.activeTitle = { ...styles.titlesClickable, ...styles.activeTitle };
