/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Utils
import {formatNumberToCurrency, useApi} from "services/helpers";


import {ROUTES} from "../../../../services/constants";
import {
  Container,
  Input,
  InputContainer
} from "../../../../components/FiltersBar/styles";
import {Grid, InputAdornment, TextField} from "@mui/material";
import search from "../../../../assets/icons/search.svg";
import Pagination from "../../../../components/DataTable/Pagination/Pagination";
import DashboardProjectManagerLayout from "../../../../components/DashboardProjectManager";
import {MainContainer} from "../../../admin/manage-products/styles";
import {useStores} from "../../../../models";
import {
  ProductBrandText,
  ProductDetailBox,
  ProductImage,
  ProductImageContainer,
  ProductPriceText
} from "../../products/styles";

function SupplyManagerProductsPage({showTotalEntries = true}) {
  const api = useApi()
  const {manufacturerId, categoryId = null} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});


  const getProducts = (data) => {
    setLoading(true)
    api.getManufacturerProducts({...data, manufacturer_id: manufacturerId, category_id: categoryId, page_size: 12}).handle({
      onSuccess: (result) => {
        const {count, results} = result.data
        setProducts(results)
        setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length}))
      },
      errorMessage: 'Error getting products',
      onFinally: () => setLoading(false)
    })
  }

  useEffect(() => {
    getProducts(getFilters())
  }, [searchQuery])

  const getFilters = () => {
    let filters = {search: searchQuery, page: 1, manufacturer_id: manufacturerId}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    return filters
  }

  const gotoProductDetail = (id) => {
    navigate(ROUTES.SUPPLY_MANAGER_PRODUCT_DETAIL(manufacturerId, id))
  }

  return (
    <DashboardProjectManagerLayout
    >
      <MDBox sx={MainContainer}>
        <MDBox sx={Container}>
          <MDBox sx={InputContainer}>
            <TextField
              sx={Input}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start" sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={'Search for a product'}
            />
          </MDBox>
        </MDBox>
        <Grid container spacing={5}>
          {Products?.length > 0 ? Products.map((product, index) => (
              <Grid item xs={12} md={6} lg={3} key={`product-key-${index}`}
                    onClick={() => gotoProductDetail(product.id)}>
                <MDBox sx={ProductImageContainer}>
                  <MDBox
                    component={'img'}
                    src={product.main_img}
                    sx={ProductImage}
                  />
                </MDBox>

                <MDBox sx={ProductDetailBox}>
                  <MDTypography variant='button' sx={ProductBrandText}>{product.manufacturer}</MDTypography>
                  <MDTypography variant='h3' sx={{width: '100%', wordWrap: 'break-word'}}>{product.name}</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Model {product.model}</MDTypography>
                  <MDTypography variant='h3' sx={ProductPriceText}>
                    {formatNumberToCurrency(product.price)}
                    <MDTypography variant='button' fontWeight='regular'>/unit</MDTypography>
                  </MDTypography>
                </MDBox>
              </Grid>
            )) :
            <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No items found</MDTypography>
            </MDBox>

          }
        </Grid>
        {showTotalEntries && <Grid container mt={5}>
          <Grid item>
            <MDBox m={2} sx={{color: '#666666', fontSize: 17, width: 300}}>Showing <span
              style={{color: '#000000'}}>{paginationData.itemsPerPage}</span> from <span
              style={{color: '#000000'}}>{paginationData.counts}</span> data</MDBox>
          </Grid>
          <Grid item ml={'auto'}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={12}
              onPageChange={page => {
                // page: page is to ensure that we have the current value in the filters
                getProducts({page: page})
                setPaginationData(value => ({...value, currentPage: page}))
              }}
            />
          </Grid>
        </Grid>}
      </MDBox>
    </DashboardProjectManagerLayout>
  );
}

export default observer(SupplyManagerProductsPage);

