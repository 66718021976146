import pxToRem from "../../../assets/theme/functions/pxToRem";
import {popoverActionsIcon} from "../../../components/PopoverActionsIcon";



export const dataTableModel = {
  columns: [
    {Header: "Name", accessor: "name"},
    {Header: "Company", accessor: "company__name"},
    {Header: "Region", accessor: "region__name"},
    {Header: "Packages", accessor: "packages"},
    {Header: "", accessor: "actions", align: "left", disableOrdering: true, width: pxToRem(5)}
  ],
  rows: [],
};


export const renderTableRow = (item, setAnchorEl, setOpenPopover, setSelectedItem) => {
  item.actions = (popoverActionsIcon(item, setAnchorEl, setOpenPopover, setSelectedItem))
  item.company__name = item.company
  item.region__name = item.region
  return item
}
