import MDBox from "../../../../components/MDBox";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import { useCallback, useEffect, useState} from "react";
import {useApi} from "../../../../services/helpers";
import MDTypography from "../../../../components/MDTypography";
import {DoneText, NewPMButton} from "../styles";
import {Icon} from "@mui/material";
import CustomerCell from "../../../../layouts/ecommerce/orders/order-list/components/CustomerCell";
import MDButton from "../../../../components/MDButton";
import MDInput from "../../../../components/MDInput";
import debounce from "lodash.debounce";
import AddBaseModal from "../../../../components/add-base-modal/AddBaseModal";
import ConfirmDialogModal from "../../../../components/ConfirmDialogModal";
import {observer} from "mobx-react";


export const UpdateSubContractorModal = observer(({open, item, onClose, loading, onUpdate}) => {
  const api = useApi()
  const [subContractors, setSubContractors] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [currentIds, setCurrentIds] = useState(item?.sub_contract_reps?.map(item => item.id) || [])
  const [openModal, setOpenModal] = useState(false)
  const [selectedSubContract, setSelectedSubContract] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    getSubContractors({search: ""})
  }, [])

  const getSubContractors = ({search}) => {
    api.getSubContractors({
      search: search,
      page: 1,
      ordering: '',
      page_size: 25,
    }).handle({
      onSuccess: (result) => { setSubContractors(result?.data?.results) },
      errorMessage: 'Error getting sub-contractors',
    })
  }

  const searchContractors = (value) => {
    getSubContractors({search: value})
  }


  const debouncedInputChange = useCallback(
    debounce(searchContractors, 300)
    , [])

  const addSubContractorsRepToProject = () => {
    api.addSubContractorsRepToProject({id: item.id, sub_contractor_reps: [selectedUser.id]}).handle({
      onSuccess: (result) => {
        setCurrentIds(result.response?.map(item => item.id) || [])
      },
      successMessage: 'Sub Contractor updated successfully',
      errorMessage: 'Error updating Project Manager',
      onFinally: () => { setOpenModal(false) },
    })
  }

  return (
    <AddBaseModal
      open={open}
      loading={loading}
      handleClose={() => {
        onClose()
      }}
      title={'Update Sub-Contractor'}
      headerContent={
        <MDTypography
          variant='h6'
          sx={DoneText}
          onClick={() => {
            onUpdate?.()
            onClose()
          }}
        >
          Done
        </MDTypography>
      }
      showActions={false}
      modalContainer={{height: pxToRem(820)}}
    >
      <ConfirmDialogModal
        title={'Do you want to add this sub-contractor?'}
        description={``}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={() => addSubContractorsRepToProject()}
      />

      <MDBox padding={`${pxToRem(20)} ${pxToRem(24)}`} overflow={'auto'}>
        <MDInput
          sx={{width: '100%'}}
          placeholder={'Search for sub-contractors'}
          value={searchValue}
          onChange={(e) => {
            debouncedInputChange(e.target.value)
            setSearchValue(e.target.value);
          }}
          InputProps={{
            endAdornment:
              <Icon fontSize='medium' sx={({palette: {icons}}) => ({color: icons.search_icon})}>
                search
              </Icon>
          }}
        />
        <MDBox display='flex' flexDirection='column' gap={pxToRem(28)}>
          {!subContractors.length
            ? <MDBox height={'200px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <MDTypography variant='subtitle2'>No sub-contractors found</MDTypography>
              </MDBox>
            : subContractors?.map(subContractor => (
            <MDBox key={`sub-id-${subContractor?.id}`}>
              <MDBox display='flex' justifyContent='space-between' alignItems='center' borderBottom={'1px solid #ced4da'} pb={2}>
                <CustomerCell
                  name={subContractor.name}
                  image={subContractor.logo}
                  color={subContractor.color || "dark"}
                  styles={{fontSize: '1rem'}}
                />
                <MDButton
                  variant={"outlined"}
                  color={"secondary"}
                  onClick={() => {
                    setSelectedSubContract(subContractor.id)
                    setSelectedUser(subContractor)
                  }}
                  sx={NewPMButton}
                >
                  Select
                </MDButton>
              </MDBox>
              { selectedSubContract === subContractor.id && (
                <MDBox display='flex' flexDirection='column' gap={pxToRem(28)} px={3} pt={2}>
                  {!subContractor.sub_contractor_reps.length
                    ? <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <MDTypography variant='subtitle2'>No sub-contractor reps found</MDTypography>
                      </MDBox>
                    :
                    subContractor?.sub_contractor_reps?.map(rep => (
                    <MDBox key={`rep-id-${rep?.id}`} border='none' display='flex' justifyContent='space-between' alignItems='center'>
                      <CustomerCell
                        name={rep?.name || 'Rep name'}
                        image={rep?.profile_picture}
                        color={rep?.color || "dark"}
                        styles={{fontSize: '1rem'}}
                      />
                      <MDButton
                        variant={"outlined"}
                        color={currentIds.includes(rep.id) ? "error" : "secondary"}
                        onClick={() => {
                          setOpenModal(true)
                          setSelectedUser(rep)
                        }}
                        sx={NewPMButton}
                      >
                        {currentIds.includes(rep.id)  ? "Remove" : "Add"}
                      </MDButton>
                    </MDBox>
                  ))}
                </MDBox>
              )}
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </AddBaseModal>
  )
})
