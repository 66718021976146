import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import {Accordion, AccordionDetails, AccordionSummary, Divider, Fab, Icon} from "@mui/material";
import {InitialArchitectural, InitialBrandCategory, InitialDrawing} from "../constants";
import FormikInput from "../../../../../../components/FormikInput";
import {FieldArray, getIn} from "formik";
import {AddButton, RemoveButton, SectionTitle, SectionTitleText} from "../styles";
import InputDragAndDrop from "../../../../../../components/InputDragAndDrop";

import {
  ImagecardContainer, ImagecardDeleteIcon,
  ImageCardImage,
  ImagecardWrapContainer
} from "../../../../manage-products/manage_product/styles";
import {useState} from "react";
import {openInNewTab} from "../../../../../../services/helpers";
import pxToRem from "../../../../../../assets/theme/functions/pxToRem";

const debugBorders = {} //{border:"2px solid blue"}

export const DrawingsForm = ({formik, drawingsProps, values, setFieldValue}) => {
  const [files, setFiles] = useState([]);
  // const [architecturalImages, setArchitecturalImages] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const parentPath = drawingsProps.name
  const valuesArray = getIn(values, parentPath)
  const handleChange = (openedFrom) => {
    if (openedFrom === 'accordion') {
      setExpanded(v => !v);
    } else {
      setExpanded(true)
    }
  };
  const handleDrop = (e, fileProps, fileType) => {
    e.preventDefault();
    const newFiles = e.dataTransfer.files?.[0];
    if (fileType === 'pdf') {
      setFiles(newFiles);
      fileProps.push({
        drawing_file: newFiles
      });
    } else {
      setFiles(newFiles);
      fileProps.push({
        image: newFiles,
        url: URL.createObjectURL(newFiles)
      })
    }
  }
  return (
    <MDBox
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'start'}
      sx={{width: "820px", mb: 3, ...debugBorders}}
      key={`drawing-key`}
    >
      {/*<MDBox sx={SectionTitle}>*/}
      {/*  <MDTypography sx={SectionTitleText}>Drawings</MDTypography>*/}
      {/*  <Icon sx={AddButton} fontSize='medium' onClick={()=>drawingsProps.push({...InitialDrawing})}>add_circle</Icon>*/}
      {/*</MDBox>*/}
      <Accordion style={{width: "100%", marginBottom: '20px'}} expanded={expanded}
                 onChange={() => handleChange('accordion')}>
        <AccordionSummary
          expandIcon={<Icon>expand_more</Icon>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography sx={SectionTitleText}>Drawings</MDTypography>
          <Icon sx={AddButton} fontSize='medium' onClick={(event) => {
            drawingsProps.push({...InitialDrawing})
            handleChange('icon')
            event.stopPropagation();
          }}>
            add_circle
          </Icon>
        </AccordionSummary>
        <AccordionDetails>

          {valuesArray.length === 0 &&
            <MDBox sx={{display: 'flex', flexDirection: "column", width: "800px", px: 3, alignItems: 'center'}}>
              <MDTypography sx={SectionTitleText}>No drawings in the brand</MDTypography>
            </MDBox>
          }

          {valuesArray.length > 0 && <MDBox sx={{display: 'flex', flexDirection: "column", width: "800px", px: 3}}>
            {valuesArray.map((pkg, index) => {
                const mapPath = `${parentPath}.${index}`
                return (
                  <MDBox display={"flex"} flexDirection={"column"} sx={{px: 3, ...debugBorders}}
                         key={`id-drawings-${index}`}>
                    <MDTypography variant={"h4"} component={"h4"}>Drawing #{index + 1}</MDTypography>
                    <FormikInput name={`${mapPath}.name`} label="Name" mb={3}/>
                    <FieldArray name={`${mapPath}.images`}>
                      {(drawingImgProps) => {
                        const parentPath = drawingImgProps.name
                        const valueArray = getIn(values, parentPath)
                        return (
                          <MDBox key={`drawing-id-1${index}`}>
                            <MDBox
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={(e) => handleDrop(e, drawingImgProps, 'image')}
                            >
                              <input type='file' accept='image/*' name={`images-${index}`} id={`images-${index}`} style={{display: 'none'}}
                                     onInput={(event) => {
                                       drawingImgProps.remove(0)
                                       if (event.target.files?.length) {
                                         const selectedFiles = event.target.files[0];
                                         setFiles(selectedFiles);
                                         drawingImgProps.push({
                                           image: selectedFiles,
                                           url: URL.createObjectURL(selectedFiles)
                                         })
                                       }
                                     }}
                                     onClick={(event) => {
                                       event.target.value = null
                                     }}
                              />
                              <InputDragAndDrop htmlFor={`images-${index}`} label={'Images'} errors={formik.errors}
                                                name={`images-${index}`}/>
                            </MDBox>
                            <MDBox sx={ImagecardWrapContainer}>
                              {valueArray.map((image, index2) => {
                                  // console.log(index, image, image?.url || image)
                                  return (
                                    <MDBox sx={ImagecardContainer} key={`key-image-1${index2}`}>
                                      <img
                                        style={ImageCardImage}
                                        alt={image.image?.name}
                                        src={image?.url || image.image}
                                        onClick={() => {openInNewTab(image?.image instanceof File ? URL.createObjectURL(image?.image) : image?.image)}}
                                      />
                                      <Icon
                                        sx={ImagecardDeleteIcon}
                                        fontSize='medium'
                                        onClick={() => drawingImgProps.remove(index2)}>
                                        remove_circle
                                      </Icon>
                                    </MDBox>
                                  )
                                }
                              )}
                            </MDBox>
                          </MDBox>
                        )
                      }}
                    </FieldArray>
                    <FieldArray name={`${mapPath}.architectural_files`}>
                      {(architecturalProps) => {
                        const parentPath = architecturalProps.name
                        const valueArray = getIn(values, parentPath)
                        return (
                          <MDBox key={`architectural-id-1${index}`} pt={3}>
                            <MDBox
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={(e) => handleDrop(e, architecturalProps, 'pdf')}
                            >
                              <input type='file'
                                     accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                     name={`architectural_files-${index}`}
                                     id={`architectural_files-${index}`}
                                     style={{display: 'none'}}
                                     onInput={(event) => {
                                       if (event.target.files?.length) {
                                         setFiles(event.target.files);
                                         architecturalProps.push({
                                           drawing_file: event.target.files?.[0]
                                         });
                                       }
                                     }}
                                     onClick={(event) => {
                                       event.target.value = null
                                     }}
                              />
                              <InputDragAndDrop htmlFor={`architectural_files-${index}`}
                                                label={'Architectural Drawing'}
                                                errors={formik.errors}
                                                name={`architectural_files-${index}`}/>
                            </MDBox>
                            <MDBox>
                              {valueArray.map((file, index2) => {
                                  return (
                                    <MDBox sx={ImagecardContainer} key={`architectural-image-1${index2}`} >
                                      <Icon sx={(th) => ({ml: pxToRem(11), mr: 2, mt: 2})} fontSize='medium' onClick={() => {
                                        openInNewTab(file?.drawing_file instanceof File ? URL.createObjectURL(file?.drawing_file) : file?.drawing_file)
                                      }}>attachment</Icon>

                                      <MDTypography
                                        variant={'subtitle2'}
                                        onClick={() => {openInNewTab(file?.drawing_file instanceof File ? URL.createObjectURL(file?.drawing_file) : file?.drawing_file)}}
                                        p={2}
                                        sx={{
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        cursor: 'pointer'
                                      }}>
                                        {file?.drawing_file.name || file?.drawing_file}
                                      </MDTypography>
                                      <Icon
                                        sx={ImagecardDeleteIcon}
                                        fontSize='medium'
                                        onClick={() => architecturalProps.remove(index2)}>
                                        remove_circle
                                      </Icon>
                                    </MDBox>
                                  )
                                }
                              )}
                            </MDBox>
                          </MDBox>
                        )
                      }}
                    </FieldArray>



                    <MDBox sx={{display: 'flex', alignItems: "center", flexDirection: "row", justifyContent: "start",}}>
                      <MDTypography variant={"h4"} component={"h4"}>Delete Drawing #{index + 1}</MDTypography>
                      <Icon sx={(th) => ({...RemoveButton(th), ml: 2})} fontSize='medium'
                            onClick={() => drawingsProps.remove(index)}>
                        remove_circle
                      </Icon>
                    </MDBox>

                  </MDBox>

                )
              }
            )}
          </MDBox>}

        </AccordionDetails>
      </Accordion>
    </MDBox>
  )
}
