import { Box, Icon } from "@mui/material";
import { observer } from "mobx-react";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {money_fmt, showMessage, useApi, useLoginStore} from "../../../../services/helpers";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import * as React from "react";
import { useState } from "react";
import MDInput from "../../../../components/MDInput";
import { InputQuantity } from "../../packages/package-detail/styles";
import ConfirmDialogModal from "../../../../components/ConfirmDialogModal";

const MAX = 1000000
const ItemToBuyCard = ({
  id,
  image,
  name,
  model,
  installation,
  shipping,
  unitCost,
  quantity,
  subtotal,
  deleteLine,
  onOrderUpdated,
  projectId,
  order,
}) => {
  const api = useApi();
  const loginStore = useLoginStore();
  const [editQty, setEditQty] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const editQtyHandler = () => {
    api
      .updateQtyOrderLine(projectId, order.id, id, editQty)
      .then((result) => {
        if (result.kind === "ok") {
          onOrderUpdated();
          loginStore.setIsEditingQuantity(false)
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        showMessage("Error updating order line quantity");
      });
  };

  const qtySection = (
    <MDBox
      display={"flex"}
      justifyContent={"space-between"}
      flexDirection={"row"}
      alignItems={"center"}
    >
      {editQty === null ? (
        <MDTypography sx={styles.prices}>{quantity}</MDTypography>
      ) : (
        <MDBox display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <MDInput
            sx={InputQuantity}
            onChange={(e) => {
              let quantity = e.target.value? Math.trunc(e.target.value) : ""
              if (quantity > MAX) quantity = MAX;
              setEditQty(quantity);
            }}
            value={editQty}
            inputProps={{ min: 1 }}
            type="number"
          />
          <Icon
            sx={({ palette: { icons } }) => ({ color: icons.arrow_icon, ml: 1 })}
            onClick={editQtyHandler}
          >
            check
          </Icon>
          <Icon
            sx={({ palette: { icons } }) => ({ color: icons.remove, ml: 1 })}
            onClick={() => {
              setEditQty(null);
              loginStore.setIsEditingQuantity(false)
            }}
          >
            cancel
          </Icon>
        </MDBox>
      )}
      {(loginStore?.id === order?.buyer?.id || (order?.buyer?.is_projectmanager && loginStore.isProjectManager)  || loginStore.isSupplyManager) && editQty === null && order.is_editable ? (
        <Icon
          sx={{ ml: 2 }}
          onClick={() => {
            setEditQty(quantity);
            loginStore.setIsEditingQuantity(true)
          }}
        >
          edit
        </Icon>
      ) : null}
    </MDBox>
  );

  return (
    <MDBox sx={styles.container}>
      <ConfirmDialogModal
        open={confirmDelete}
        title={`Delete Item`}
        handleClose={() => setConfirmDelete(false)}
        handleConfirm={() => {
          deleteLine(id);
          setConfirmDelete(false);
        }}
        description={"Are you sure you want to confirm this action?"}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
      />
      <MDBox display={"flex"} width={"60%"}>
        <Box component={"img"} src={image} alt={"item-to-buy"} sx={styles.image} />
        <MDBox>
          <MDTypography sx={styles.boldText}>{name}</MDTypography>
          <MDTypography sx={styles.models}>Model {model}</MDTypography>
          <MDTypography sx={styles.models}>
            {installation ? `✓ Installation ${installation}` : ""}
          </MDTypography>
          <MDTypography sx={styles.models}>{shipping ? `✓ Shipping ${shipping}` : ""}</MDTypography>
          {(loginStore?.id === order?.buyer?.id || (order?.buyer?.is_projectmanager && loginStore.isProjectManager) || loginStore.isSupplyManager) && order.is_editable && (
            <MDTypography sx={styles.removeText} onClick={() => setConfirmDelete(true)}>
              Remove
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
      <MDBox width={"40%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <MDBox>
          <MDTypography sx={styles.prices}>{money_fmt(unitCost)}</MDTypography>
        </MDBox>
        {qtySection}
        <MDBox alignItems={"center"}>
          <MDTypography sx={{ ...styles.boldText, width: "100%" }}>
            {money_fmt(subtotal)}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default observer(ItemToBuyCard);
const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    pb: pxToRem(26),
  },
  image: {
    mr: pxToRem(17),
    width: "100%",
    height: "100%",
    maxWidth: pxToRem(80),
    maxHeight: pxToRem(80),
    objectFit: "cover",
  },
  boldText: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    width: pxToRem(330),
    pb: pxToRem(4),
  },
  models: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: pxToRem(14),
    pb: pxToRem(4),
  },
  prices: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(17),
  },
  removeText: {
    pt: pxToRem(20),
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    color: "#0B7340",
    cursor: "pointer",
  },
};
