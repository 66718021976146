/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import AdminAnalytics from "./pages/admin/analytics";
import Logout from "./pages/base/logout";
import CreateUserAccount from "./pages/admin/create-user-account";
import AdminAllUsers from "./pages/admin/all-users";
import ManageCompaniesPage from "./pages/admin/manage-companies";
import ManageRegionsPage from "./pages/admin/manage-regions";
import ManageCategoriesPage from "./pages/admin/manage-categories";
import ManageProjectsPage from "./pages/admin/manage-projects";
import ManageManufacturersPage from "./pages/admin/manage-manufacturers";
import ManageBrandsPage from "./pages/admin/manage-brands";
import ManageProductsPage from "./pages/admin/manage-products";
import OrderManagement from "./pages/admin/order-management";
import UploadContentPage from "./pages/admin/upload-content";
import {ROUTES} from "./services/constants";
import ManageSubContractorsPage from "./pages/admin/manage-sub-contractors";

const routes = [
  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    route: "/dashboard/analytics",
    component: <AdminAnalytics />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Management",
    key: "management",
    collapse: [
      {
        name: "Manage Companies",
        key: "manage-companies",
        route: "/management/manage-companies",
        component: <ManageCompaniesPage />,
      },
      {
        name: "Manage Regions",
        key: "manage-regions",
        route: "/management/manage-regions",
        component: <ManageRegionsPage />,
      },
      {
        name: "Manage Projects",
        key: "manage-projects",
        route: "/management/manage-projects",
        component: <ManageProjectsPage />,
      },
      {
        name: "Manage Categories",
        key: "manage-categories",
        route: "/management/manage-categories",
        component: <ManageCategoriesPage />,
      },
      {
        name: "Manage Manufacturers",
        key: "manage-manufacturers",
        route: "/management/manage-manufacturers",
        component: <ManageManufacturersPage />,
      },
      {
        name: "Manage Products",
        key: "manage-products",
        route: "/management/manage-products",
        component: <ManageProductsPage />,
      },
      {
        name: "Manage Brands",
        key: "manage-brands",
        route: ROUTES.ADMIN_BRAND,
        component: <ManageBrandsPage />,
      },
      {
        name: "Manage Sub-Contractors",
        key: "manage-subcontractors",
        route: "/management/manage-subcontractors",
        component: <ManageSubContractorsPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Order Management",
    key: "order-management",
    route: "/dashboard/order-management",
    component: <OrderManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "All Users",
    key: "all-users",
    route: "/dashboard/all-users",
    component: <AdminAllUsers />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Create User Account",
    key: "create-user-account",
    route: "/dashboard/create-user-account",
    component: <CreateUserAccount />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Create and Assign Projects",
  //   key: "create-assign-projects",
  //   route: "/dashboard/create-assign-projects",
  //   component: <AdminAnalytics />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Role based Access Control",
  //   key: "role-based-access-control",
  //   route: "/dashboard/role-based-access-control",
  //   component: <AdminAnalytics />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Upload content",
    key: "upload-content",
    route: "/dashboard/upload-content",
    component: <UploadContentPage />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    route: "/logout",
    component: <Logout />,
    noCollapse: true,
  },
];

export default routes;
