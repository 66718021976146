

export const EMPTY_INITIAL = {
  "id": null,
  "name": "",
  "region": null,
  "company": null,
  "logo": null,
  "contract_template": null,
  "packages": [
  ],
  "drawings": [
  ],
  "categories": [
  ]
}

