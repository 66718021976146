import MDBox from "../../../components/MDBox";
import {observer} from "mobx-react";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {ROUTES} from "../../../services/constants";
import {Box, Card, CircularProgress} from "@mui/material";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {Form, Formik} from "formik";
import {projectManagerInitialValues, projectManagerValidationRules} from "../../admin/create-user-account/utils";
import * as Yup from "yup";
import FormikInput from "../../../components/FormikInput";
import MDButton from "../../../components/MDButton";
import UserAvatar from "assets/images/blank_avatar.png";
import camera from "assets/icons/camera.svg";
import { useEffect, useState } from "react";
import {showMessage, useApi, useIsMobile} from "../../../services/helpers";

let validationSchema = {...projectManagerValidationRules}
delete validationSchema.permission_type

const MyAccount = () => {
  const api = useApi()
  const [loading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState(projectManagerInitialValues)
  const [imgPreview, setImgPreview] = useState(UserAvatar);
  const isMobile = useIsMobile();
  const handleUploadFile = (file) => {
    setImgPreview(URL.createObjectURL(file))
  }


  const getMyProfile = ()=>{
    api.getMyProfile().then(response => {
      if(response.kind === "ok"){

        setInitialValues(response.data)
        if(response.data.profile_picture){
          setImgPreview(response.data.profile_picture)
        }
      }
    }).catch(reason => {
      showMessage('Error getting profile')
    }).finally(() => {
      setLoading(false)
    })
  }

  const saveProfile = (data)=>{
    setLoading(true);
    const keys = []

    if (initialValues.profile_picture !== data.profile_picture) {
      keys.push(['profile_picture'])
    } else {
      // delete data['profile_picture']
    }

    api.saveMyProfile(data, keys).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Profile updated successfully', 'success')
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => {setLoading(false)})
  }

  useEffect(() => {
    getMyProfile()
  },[])

  return (
    // <MDBox sx={{display:"flex", alignItems:"center", alignContent:"center", justifyContent:"center"}}>
    <DashboardProjectManagerLayout
      title={'My Account'}
      backButton
      backRoute={ROUTES.USER_PROJECT_SELECTOR}
      alignContent={"center"}
    >
      <Card sx={{ minWidth: '700px', p: pxToRem(52), display: "flex", flexDirection: "column", alignItems: "center"}} >
        {/*{!loading && (*/}
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={values =>saveProfile(values)}
            enableReinitialize
          >
            {(formik)=> (
              <Form style={{ display: "flex", flexDirection: "column", alignItems: "center", width: pxToRem(355), gap: 5}}>
                <MDBox sx={{ position: 'relative' }}>
                  <Box component={"img"} src={imgPreview} alt={"Profile Picture"} width={pxToRem(145)}
                       height={pxToRem(145)} borderRadius={'50%'} sx={{objectFit: 'cover'}}/>
                  <input
                    type='file'
                    accept='image/*'
                    id={'input_file'}
                    onChange={(e) => {
                      const file = e.target.files[0]
                      handleUploadFile(file);
                      formik.setFieldValue('profile_picture', file)
                    }}
                    style={{display: 'none'}}
                  />
                  <label htmlFor='input_file'>
                    <Box component={"img"} src={camera} alt={"camera"} sx={{ position: 'absolute', bottom: 15, right: 0, cursor: 'pointer'}} />
                  </label>
                </MDBox>
                <FormikInput type={"text"} label={"Full Name"} name={"name"}  sx={{ width: "100%", mt:2 }}/>
                <FormikInput type={"text"} label={"Email"} name={"email"} sx={{ width: "100%", mt:2 }}   InputProps={{ readOnly: true}}  />
                <FormikInput type={"text"} label={"Job Title"} name={"title"} sx={{ width: "100%", mt:2 }}/>
                <FormikInput type={"phone_input"} label={"Phone Number"} name={"phone_number"} sx={{ width: "100%", mt:2 }}/>

                {initialValues.is_projectmanager === true && (<>
                  <FormikInput type={"text"} label={"Company"} name={"company_name"} sx={{ width: "100%", mt:2 }}
                              disabled />
                  <FormikInput type={"text"} label={"Region"} name={"region_name"} sx={{ width: "100%", mt:2 }}
                               disabled  />
                </>)}
                {initialValues.is_supplymanager === true && (<>
                  <FormikInput type={"text"} label={"Manufacturer"} name={"manufacturer_name"} sx={{ width: "100%", mt:2 }}
                               disabled  />
                </>)}


                <MDButton type={"submit"} color={"primary"} sx={{ width: pxToRem(288), mt: pxToRem(20) }} loading={loading} disabled={loading}>
                 Save
                </MDButton>
              </Form>
            )}
          </Formik>
      </Card>
    </DashboardProjectManagerLayout>
  );
}

export default observer(MyAccount);
