import boxShadows from "assets/theme/base/boxShadows";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import moment from "moment";

const ShipmentItem = ({
  handleOpenPodForm,
  handleOpenShipmentForm,
  handleOpenPodDetails,
  handleOpenShipmentCompleted,
  handleSelectedShipment,
  handleSelectedPod,
  shipment,
  index,
  showButton,
}) => {
  const {
    tracking_status,
    carrier,
    bill_of_lading,
    pro,
    trailer,
    scheduled_ship_date,
    scheduled_arrival_date,
    revisions,
    pods = [],
  } = shipment;

  const shipmentDetails = [
    {
      label: "Status",
      value: tracking_status,
    },
    {
      label: "Carrier",
      value: carrier,
    },
    {
      label: "Bill of Lading Shipment Number",
      value: bill_of_lading,
    },
    {
      label: "Pro Number",
      value: pro,
    },
    {
      label: "Trailer Number",
      value: trailer,
    },
    {
      label: "Scheduled Ship Date",
      value: scheduled_ship_date,
    },
    {
      label: "Scheduled Arrival Date",
      value: scheduled_arrival_date,
    },
  ]

  const revisionDetails = [
    {
      label: "Revised Note",
      value: "revised_note",
    },
    {
      label: "Revised Ship Date",
      value: "revised_ship_date",
    },
    {
      label: "Revised Arrival Date",
      value: "revised_arrival_date",
    },
  ];

  const getColorByStatus = (status) => {
    switch (status) {
      case "Order Received":
        return "successV2";

      case "Waiting for Update":
        return "warningV2";

      case "On Schedule":
        return "successV2";

      case "Late":
        return "errorV2";

      case "Revised":
        return "warningV2";

      case "Partially Fulfilled":
        return "warningV2";

      case "Received in Full":
        return "successV3";

      default:
        return "successV3";
    }
  };

  return (
    <MDBox
      sx={{
        backgroundColor: colors.lightGrey,
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: 4,
        paddingY: 3,
        borderRadius: pxToRem(6),
      }}
    >
      {/* Box 1 */}
      <MDBox sx={{ flex: 1, paddingLeft: 3 }}>
        <MDTypography variant="h6">Load {index + 1} Details</MDTypography>

        <MDBox sx={{ display: "flex", flexDirection: "column", gap: 0.5, marginTop: 1 }}>
          {shipmentDetails.map((item) => (
            <MDBox
              key={item.label}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography variant={"subtitle2"} fontSize={"14px"} sx={{ flex: 1, wordBreak: "break-word" }}>
                {item.label}
              </MDTypography>

              {item.label.includes("Status") ? (
                <MDBadge
                  width={120}
                  textTransform="capitalize"
                  customFontSize="11px"
                  badgeContent={item.value}
                  variant="contained"
                  color={getColorByStatus(item.value)}
                />
              ) : (
                <MDTypography
                  variant={"subtitle2"}
                  fontSize={"14px"}
                  fontWeight="bold"
                  textAlign="right"
                  sx={{ flex: 1, wordBreak: "break-word" }}
                >
                  {item.label.includes("Date")
                    ? moment(item.value).format("MM/DD/YY")
                    : item.value || "-"}
                </MDTypography>
              )}
            </MDBox>
          ))}
        </MDBox>

        {revisions?.length > 0 && (
          <MDBox
            sx={{
              maxHeight: 220,
              overflow: "auto",
              // marginTop: 1,
              paddingTop: 1,
              // borderTop: "1px solid #DBDBDB",
            }}
          >
            {revisions?.map((revision, index) => (
              <MDBox
                key={revision.id}
                sx={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                    // marginBottom: 1,
                    paddingBottom: 1,
                  },
                  // index < revisions.length - 1 && { borderBottom: "1px solid #DBDBDB" },
                ]}
              >
                {revisionDetails.map((item) => (
                  <MDBox
                    key={`${item.label}-${revision.id}`}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: item.label.includes("Note")? "1px solid #DBDBDB" : "none",
                      marginBottom: item.label.includes("Note")? 1: 0,
                    }}
                  >
                    <MDTypography variant={"subtitle2"} fontSize={"14px"} sx={{ flex: 1, wordBreak: "break-word" }}>
                      {item.label}
                    </MDTypography>

                    <MDTypography
                      variant={"subtitle2"}
                      fontSize={"14px"}
                      fontWeight="bold"
                      textAlign="right"
                      sx={{
                        flex: 1,
                        wordBreak: "break-word",

                      }}
                    >
                      {item.label.includes("Date")
                        ? moment(revision[item.value]).format("MM/DD/YY")
                        : revision[item.value]}
                    </MDTypography>
                  </MDBox>
                ))}
              </MDBox>
            ))}
          </MDBox>
        )}
      </MDBox>

      {/* Box 2 */}
      <MDBox
        sx={{
          borderLeft: "2px solid #FFF",
          flex: 1,
          gap: 4,
          paddingLeft: 4,
          paddingRight: 3,
          maxHeight: 450,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {pods?.map((pod, index) => (
          <MDBox
            key={`pod-${pod.id}`}
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 1,
              marginBottom: 1,
              borderBottom: "1px solid #DBDBDB",
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <MDTypography variant="h6">POD {index + 1}</MDTypography>
              <MDButton
                variant="text"
                color="primary"
                sx={{
                  position: "absolute",
                  right: "-24px",
                  top: "-10px",
                }}
                onClick={() => {
                  handleOpenPodDetails();
                  handleSelectedPod({ index: index + 1, ...pod });
                }}
              >
                View Details
              </MDButton>
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MDTypography variant={"subtitle2"} fontSize={"14px"} fontWeight="bold">
                Note
              </MDTypography>
              <MDTypography variant={"subtitle2"} fontSize={"14px"} sx={{ wordBreak: "break-word" }}>
                {pod.pod_note}
              </MDTypography>
            </MDBox>
          </MDBox>
        ))}
      </MDBox>

      {/* Buttons */}
      {showButton && (
        <MDBox
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: 2, paddingX: 3 }}
        >
          <MDButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleOpenPodForm();
              handleSelectedShipment({ index: index + 1, ...shipment });
            }}
          >
            Add POD
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpenShipmentForm();
              handleSelectedShipment({ index: index + 1, ...shipment });
            }}
          >
            Revise
          </MDButton>
          {pods.length > 0 && tracking_status !== "Completed" && (
            <MDButton
              variant="contained"
              color="secondary"
              onClick={() => {
                handleSelectedShipment(shipment);
                handleOpenShipmentCompleted();
              }}
            >
              Change Completion Status
            </MDButton>
          )}
        </MDBox>
      )}
    </MDBox>
  );
};

export default ShipmentItem;
