import { observer } from "mobx-react";
import ItemToBuyCard from "./ItemToBuyCard";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import { Box, Card, Grid, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { ROUTES } from "../../../../services/constants";
import { useNavigate, useParams } from "react-router-dom";
import closeIcon from "assets/icons/closeIcon.svg";
import { money_fmt, dolovo_date_fmt, showMessage, useApi } from "../../../../services/helpers";
import { MiscellaneousCard } from "../miscellaneous-card";
import * as React from "react";
import logo from "../../../../assets/icons/dolovo-D.png";
import { useStores } from "../../../../models";
import { useReactToPrint } from "react-to-print";
import OrderPdf from "./OrderPdf";

const ItemsToBuyList = ({ products, extraProducts, order, onOrderUpdated, onTabChanged }) => {
  const { id: projectId, ido: orderId } = useParams();
  const api = useApi();
  const [visibility, setVisibility] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [tabVisible, setTabVisible] = useState(0);
  const [versionFocused, setVersionFocused] = useState(0);
  const [orders, setOrders] = useState([]);
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const componentToPrintRef = React.useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = React.useRef(null);

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);
  useEffect(() => {
    onTabChanged(tabVisible);
  }, [tabVisible]);

  const handleShowChanges = () => {
    setVisibility((v) => !v);
    setOpen(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
        document.title = `order_${order.id}`;
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
      document.title = `DOLOVO`;
    },
  });

  const gotoPackageDetail = () => {
    const q = order?.id ? `?oqn=${order?.id}` : "";
    navigate(ROUTES.USER_PROJECT_PACKAGES(projectId) + q);
  };

  const gotoProductDetail = () => {
    const q = order?.id ? `?oqn=${order?.id}` : "";
    const categoryId = order?.manufacturer?.category ? `&category=${order?.manufacturer?.category}` : "";
    //navigate(ROUTES.USER_PROJECT_SELECTED(projectId) + q)
    navigate(ROUTES.USER_PRODUCTS(projectId) + q + categoryId);
  };

  const gotoOrderDetail = () => {
    navigate(ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(projectId, order?.id));
  };

  const gotoOrderVersion = (versionId, focusedValue, order) => {
    setVersionFocused(focusedValue);
    if (!projectId || !order || !order?.id) return;
    const version_string = versionId === order?.version_number ? "" : `?version=${versionId}`;
    navigate(ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(projectId, order.id) + version_string);
    setOpen(false);
  };
  const deleteOrderLine = (id) => {
    api
      .deleteOrderLine(projectId, orderId, id)
      .then((result) => {
        if (result.kind === "ok") {
          onOrderUpdated();
        } else {
          showMessage("Error deleting order line");
        }
      })
      .catch((error) => {
        showMessage("Error deleting order line");
      });
  };
  const getOrderVersions = (id) => {
    api
      .getOrderVersions(projectId, orderId, { page_size: "all" })
      .then((result) => {
        if (result.kind === "ok") {
          setOrders(result.data.results);
        } else {
          showMessage("Error deleting order line");
        }
      })
      .catch((error) => {
        showMessage("Error deleting order line");
      });
  };
  useEffect(() => {
    getOrderVersions(orderId);
  }, [order?.version_number]);

  const latestLink = !order?.is_latest_version ? (
    <MDTypography
      variant={"button"}
      color={"primary"}
      sx={{ cursor: "pointer" }}
      onClick={gotoOrderDetail}
    >
      (Not latest)
    </MDTypography>
  ) : // <Link
  //     to={ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(projectId, order?.id, order?.latest_version?.id)}
  //     // style={{ textDecoration: 'none' }}
  //     ml={2}
  // >
  //
  // </Link>
  null;

  const versionBadge = (
    <MDBox display={"flex"}>
      <MDBox display={"flex"} sx={{ cursor: "pointer" }} onClick={handleShowChanges} mr={1}>
        <Card
          sx={{
            backgroundColor: "#0B7340",
            width: pxToRem(50),
            height: pxToRem(50),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MDTypography sx={{ fontSize: pxToRem(14), fontWeight: 600, color: "#fff" }}>
            V{order?.version?.version}
          </MDTypography>
        </Card>
        <MDBox ml={1}>
          <MDBox>
            <MDTypography
              variant={"h6"}
              sx={{ color: "#2A272E" }}
            >{`Order #${order?.id} - Version ${order?.version?.version}`}</MDTypography>
            <MDTypography variant={"h6"} sx={{ fontWeight: 400, color: "#6A686D" }}>
              {dolovo_date_fmt(order?.version?.time)}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mt={0.5} display={"flex"} alignItems={"flex-start"}>
        {latestLink}
      </MDBox>
    </MDBox>
  );
  const VersionCard = ({ orderVersion, focusedValue, order }) => {
    return (
      <MDBox
        display={"flex"}
        px={3}
        py={1}
        sx={
          orderVersion.version === order.version.version
            ? styles.activeVersion
            : styles.inactiveVersion
        }
        onClick={() => gotoOrderVersion(orderVersion.version, focusedValue, order)}
      >
        <MDBox
          sx={
            orderVersion.version === order.version.version
              ? styles.versionBadgeActive
              : styles.versionBadge
          }
          onClick={handleShowChanges}
        >
          <MDTypography sx={styles.versionText}>V{orderVersion.version}</MDTypography>
        </MDBox>
        <MDBox ml={1}>
          <MDTypography variant={"h6"} sx={{ color: "#2A272E" }}>
            {dolovo_date_fmt(orderVersion.time)}
          </MDTypography>
          <MDBox display={"flex"} justifyContent={"center"} gap={1}>
            <Box
              component={"img"}
              src={
                orderVersion.from_user.profile_picture
                  ? orderVersion.from_user.profile_picture
                  : logo
              }
              alt={"profile_picture"}
              width={30}
              height={30}
              sx={{ borderRadius: "50%", objectFit: "cover" }}
            />
            <MDTypography
              variant={"h6"}
              sx={{ fontSize: "14px", fontWeight: 400, color: "#555258" }}
            >
              {orderVersion.from_user.name ? orderVersion.from_user.name : "DOLOVO Admin"}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    );
  };

  return (
    <MDBox>
      <Modal open={open} onClose={() => setOpen(false)}>
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            // maxWidth: pxToRem(600),
            height: 600,
            minWidth: 300,
          }}
        >
          <MDBox sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
            <MDBox
              display={"flex"}
              sx={{ flexDirection: "row" }}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              p={2}
            >
              <MDTypography variant={"h6"}>Versions</MDTypography>
              <Box
                component={"img"}
                src={closeIcon}
                alt={"closeIcon"}
                sx={{ cursor: "pointer" }}
                width={15}
                height={15}
                onClick={() => setOpen(false)}
              />
            </MDBox>
            <Grid sx={{ maxHeight: "540px", overflowY: "auto" }}>
              {orders?.length &&
                orders.map((orderVersion, index) => (
                  <VersionCard
                    key={`key-${index}`}
                    focusedValue={index}
                    orderVersion={orderVersion}
                    order={order}
                  />
                ))}
            </Grid>
          </MDBox>
        </MDBox>
      </Modal>
      <MDBox sx={styles.headerTitles}>
        <MDTypography
          sx={tabVisible === 0 ? styles.activeTitle : styles.titlesClickable}
          onClick={() => setTabVisible(0)}
        >
          Items
        </MDTypography>
        <MDTypography
          sx={tabVisible === 1 ? styles.activeTitle : styles.titlesClickable}
          onClick={() => setTabVisible(1)}
        >
          Files & Attachments
        </MDTypography>
        {/*<MDTypography sx={tabVisible === 0 ? styles.activeTitle : styles.titles}>Project Information</MDTypography>*/}
      </MDBox>
      {tabVisible === 0 && (
        <Card sx={styles.cardContainer}>
          <MDBox sx={styles.headerOptions}>
            <MDBox display={"flex"} alignItems={"center"} gap={pxToRem(12)}>
              {versionBadge}
              {/*{changesVisibilitySelector}*/}
              {(order?.is_editable && (order.buyer?.id === loginStore?.id || (order.buyer.is_projectmanager && loginStore?.isProjectManager) || loginStore?.isSupplyManager)) && (
                <MDButton
                  variant="outlined"
                  color="secondary"
                  type="button"
                  styles={{ width: pxToRem(164), cursor: "pointer" }}
                  onClick={() => gotoProductDetail()}
                >
                  Add Product
                </MDButton>
              )}
              {order?.is_editable && loginStore.isSupplyManager && (
                <MDButton
                  variant="outlined"
                  color="secondary"
                  type="button"
                  styles={{ width: pxToRem(164), cursor: "pointer" }}
                  onClick={() => gotoPackageDetail()}
                >
                  Add Package
                </MDButton>
              )}
              <MDButton
                variant="outlined"
                color="secondary"
                type="button"
                styles={{ width: pxToRem(164), cursor: "pointer" }}
                onClick={handlePrint}
              >
                Export to PDF
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox sx={styles.titlesContainer}>
            <MDTypography sx={{ ...styles.title, width: pxToRem(400) }}>Item</MDTypography>
            <MDBox display={"flex"} justifyContent={"space-between"} width={"40%"}>
              <MDTypography sx={styles.title}>Unit Cost</MDTypography>
              <MDTypography sx={styles.title}>Quantity</MDTypography>
              <MDTypography sx={styles.title}>Subtotal</MDTypography>
            </MDBox>
          </MDBox>
          {order?.sections?.length > 0 &&
            order.sections.map((section, index) => {
              const total = section.lines.reduce(
                (acc, line) => acc + line.unit_price * line.quantity,
                0
              );

              return (
                <MDBox key={section.id}>
                  <MDTypography
                    sx={{
                      ...styles.titles,
                      borderTop: index ? "1px solid #DBDBDB" : "",
                      pb: pxToRem(22),
                    }}
                  >
                    {section.name}
                  </MDTypography>
                  {section.lines.map((line) => (
                    <ItemToBuyCard
                      key={line.id}
                      id={line.id}
                      name={line.product.name}
                      image={line.product.main_img}
                      installation={line.installation}
                      shipping={line.shipping}
                      model={line.product.model}
                      quantity={line.quantity}
                      subtotal={money_fmt(line.unit_price * line.quantity)}
                      unitCost={money_fmt(line.unit_price)}
                      deleteLine={deleteOrderLine} // TODO confirmation
                      onOrderUpdated={onOrderUpdated}
                      projectId={projectId}
                      order={order}
                    />
                  ))}
                  <MDBox sx={styles.subtotalContainer}>
                    <MDTypography sx={styles.subtotal}>Subtotal</MDTypography>
                    <MDTypography sx={styles.total}>{money_fmt(total)}</MDTypography>
                  </MDBox>
                </MDBox>
              );
            })}
          <MDBox style={{display: "none" }}>
            <MDBox ref={componentToPrintRef}>
              <OrderPdf order={order} />
            </MDBox>
          </MDBox>
        </Card>
      )}

      {tabVisible === 0 && <MiscellaneousCard order={order} onOrderUpdated={onOrderUpdated} />}
    </MDBox>
  );
};
export default observer(ItemsToBuyList);
const styles = {
  headerTitles: {
    display: "flex",
    justifyContent: "space-between",
    width: "30%",
    paddingLeft: pxToRem(30),
    gap: pxToRem(32),
  },
  titles: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    pt: pxToRem(20),
    pb: pxToRem(12),
  },
  titlesClickable: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    pt: pxToRem(20),
    pb: pxToRem(12),
    cursor: "pointer",
  },
  activeTitle: {
    borderBottom: "3px solid #81D61E",
  },
  activeVersion: {
    alignItems: "center",
    backgroundColor: "#D9F3BC",
    cursor: "pointer",
  },
  inactiveVersion: {
    alignItems: "center",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  cardContainer: {
    p: pxToRem(28),
  },
  headerOptions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  orderIdContainer: {
    display: "flex",
    alignItems: "center",
  },
  v2Card: {
    backgroundColor: "#0B7340",
    width: pxToRem(50),
    height: pxToRem(50),
    borderRadius: pxToRem(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  v2CardText: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    backgroundColor: "#0B7340",
    color: "#fff",
  },
  undoIcon: {
    width: pxToRem(20),
    color: "#0B7340",
    ml: pxToRem(5),
  },
  orderId: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
  },
  version2: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    color: "#0B7340",
    pl: pxToRem(4),
  },
  date: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(17),
    color: "#6A686D",
  },
  showChanges: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: pxToRem(14),
  },
  versionText: {
    fontSize: pxToRem(12),
    fontWeight: 600,
    lineHeight: pxToRem(14),
    color: "#fff",
  },
  visibility: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DBDBDB",
    width: pxToRem(35),
    height: pxToRem(35),
    borderRadius: pxToRem(8),
  },
  versionBadge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#9D9A9A",
    width: pxToRem(40),
    height: pxToRem(40),
    maxHeight: pxToRem(40),
    minWidth: pxToRem(40),
    borderRadius: pxToRem(8),
    cursor: "pointer",
  },
  versionBadgeActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0B7340",
    width: pxToRem(40),
    height: pxToRem(40),
    maxHeight: pxToRem(40),
    minWidth: pxToRem(40),
    borderRadius: pxToRem(8),
    cursor: "pointer",
  },
  visibilityIcon: {
    color: "#9D9A9A",
  },
  titlesContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#ECECEC",
    borderRadius: pxToRem(8),
    p: pxToRem(10),
    mt: pxToRem(36),
    mb: pxToRem(20),
  },
  title: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(17),
  },
  subtotalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    pb: pxToRem(22),
    gap: pxToRem(12),
  },
  subtotal: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(17),
    textAlign: "end",
  },
  total: {
    fontSize: pxToRem(18),
    fontWeight: 600,
    lineHeight: pxToRem(21),
  },
};
styles.activeTitle = { ...styles.titlesClickable, ...styles.activeTitle };
