import { Box, Modal } from "@mui/material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import pxToRem from "../../assets/theme/functions/pxToRem";
import closeIcon from "../../assets/icons/closeIcon.svg";
import zIndex from "@mui/material/styles/zIndex";

export const GeneralModal = ({ open, handleClose, title, children, fullHeight = false }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox
        sx={[
          {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            borderRadius: "16px",
          },
          !fullHeight && { width: pxToRem(800), height: pxToRem(400) },
        ]}
      >
        <MDBox
          display={"flex"}
          justifyContent={"center"}
          sx={
            title
              ? { borderBottom: "1px solid #DBDBDB" }
              : { position: "absolute", right: 0, zIndex: 100 }
          }
          p={3}
        >
          <MDTypography variant={"h2"}>{title}</MDTypography>
          <Box
            component={"img"}
            src={closeIcon}
            alt={"close-icon"}
            onClick={handleClose}
            sx={{ cursor: "pointer", position: "absolute", right: 30 }}
          />
        </MDBox>
        {children}
      </MDBox>
    </Modal>
  );
};
