import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import {Accordion, AccordionDetails, AccordionSummary, Divider, Fab, Icon} from "@mui/material";
import {InitialBrandCategory, InitialProduct} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import FormikInput from "../../../../../../components/FormikInput";
import {FieldArray, getIn} from "formik";
import {resolve, useApi} from "../../../../../../services/helpers";
import {useEffect, useState} from "react";
import {AddButton, RemoveButton, SectionTitle, SectionTitleText, Title} from "../styles";

const debugBorders = {} //{border:"2px solid green"}

const BrandCategoryForm = ({categoriesProps, values, setFieldValue, categories, parentPath, index, category }) => {
  const mapPath = `${parentPath}.${index}`
  const categoryValue = getIn(values, `${parentPath}.${index}.category`)
  const manufacturerValue = getIn(values, `${parentPath}.${index}.manufacturer`)

  const api = useApi()

  const [manufacturers, setManufacturers] = useState([])
  const [products, setProducts] = useState([])

  const getManufacturers = (cat_id) => {
    api.getManufacturersByCategory(cat_id).then((result) => {
      if (result.kind === 'ok') {
        setManufacturers(result.data)
      }
    })
  }

  const getProducts = (manu_id) => {
    api.getProductsByManufacturer(manu_id).then((result) => {
      if (result.kind === 'ok') {
        setProducts(result.data)
      }
    })
  }

  useEffect(() => {
    if (categoryValue?.id) {
      getManufacturers(categoryValue.id)
    }

    if (manufacturerValue?.id) {
      getProducts(manufacturerValue.id)
    }
  },[])
  return (
    <MDBox display={"flex"} flexDirection={"column"} sx={{px: 3, ...debugBorders}}>
      <MDTypography variant={"h4"} component={"h4"}>Category #{index + 1}</MDTypography>

      <FormikInput
        type={"autocomplete"}
        value={category.category}
        fieldName={`${mapPath}.category`}
        label={"Category"}
        options={categories}
        accessKey={"name"}
        styleContainer={{mb: 2}}
        onInputChange={(value) => {
          // getCompanies({search:value})
        }}
        onChange={(value) => {
          setFieldValue(`${mapPath}.category`, value)
          setFieldValue(`${mapPath}.manufacturer`, null)
          setFieldValue(`${mapPath}.products`, [])
          setManufacturers([])
          setProducts([])
          if (value) {
            getManufacturers(value.id)
          }
        }}/>

      <FormikInput
        type={"autocomplete"}
        value={category.manufacturer}
        fieldName={`${mapPath}.manufacturer`}
        label={"Manufacturer"}
        options={manufacturers}
        accessKey={"name"}
        styleContainer={{mb: 2}}
        onInputChange={(value) => {
          // getCompanies({search:value})
        }}
        onChange={(value) => {
          setFieldValue(`${mapPath}.manufacturer`, value)
          setFieldValue(`${mapPath}.products`, [])
          setProducts([])

          if (value) {
            getProducts(value.id)
          }
        }}/>


      <FieldArray name={`${mapPath}.products`}>

        {(categoryProductsProps) => {
          const formPath = categoryProductsProps.name
          const formProducts = getIn(values, formPath)
          return (
            <MDBox
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'start'}
            >
              {formProducts.length < 1 &&
                <MDTypography variant={"caption"} component={"caption"} color={"error"} mt={2}>
                  At least one product need to be added
                </MDTypography>
              }
              {formProducts.length > 0 && <MDBox
                sx={{display: 'flex', flexDirection: "column", px: 3, width: "700px", ...debugBorders}}>
                {formProducts.map((productRow, index2) => {
                  // console.log('productRow ', productRow)
                  // console.log('index2 ', index2)
                  return (
                    <MDBox
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'start'}
                      alignItems={'center'}
                      sx={{width: "650px", ...debugBorders}}
                    >
                      {/*<MDTypography variant={"h4"} component={"h4"}>Product #{index2 + 1}</MDTypography>*/}

                      <MDBox sx={{width: "350px", mt: 2}}>
                        <FormikInput
                          type={"autocomplete"}
                          value={productRow}
                          fieldName={`${formPath}.${index2}`}
                          label={"Product"}
                          options={products}
                          accessKey={"name"}
                          onInputChange={(value) => {
                            // getCompanies({search:value})
                          }}
                          onChange={(value, fieldName) => {
                            setFieldValue(fieldName, value)
                          }}/>
                      </MDBox>
                      <MDBox sx={{
                        display: 'flex',
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        ml: 2
                      }}>
                        <Icon sx={RemoveButton} fontSize='medium'
                              onClick={() => categoryProductsProps.remove(index2)}>
                          remove_circle
                        </Icon>
                      </MDBox>

                    </MDBox>
                  )
                })}

              </MDBox>}

              <MDBox sx={{
                display: 'flex',
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                mx: 3
              }}>
                {(manufacturerValue && categoryValue) &&
                  <>
                    <MDTypography variant={"h4"} component={"h4"}>Add Product</MDTypography>
                    <Icon sx={AddButton} fontSize='medium'
                          onClick={() => categoryProductsProps.push({...InitialProduct})}>
                      add_circle
                    </Icon>
                  </>
                }
              </MDBox>
            </MDBox>
          )
        }}
      </FieldArray>


      <MDBox sx={{display: 'flex', alignItems: "center", flexDirection: "row", justifyContent: "start"}}>
        <MDTypography variant={"h4"} component={"h4"}>Delete Category #{index + 1}</MDTypography>
        <Icon sx={RemoveButton} fontSize='medium' onClick={() => categoriesProps.remove(index)}>
          remove_circle
        </Icon>
      </MDBox>

    </MDBox>
  )
}
export const BrandCategoriesForm = ({categoriesProps, values, setFieldValue, categories}) => {
  const [expanded, setExpanded] = useState(false);
  const parentPath = categoriesProps.name
  const valuesArray = getIn(values, parentPath)

  const handleChange = (openedFrom) => {
    if (openedFrom === 'accordion'){
      setExpanded(v => !v);
    } else {
      setExpanded(true)
    }
  };

  return (
    <MDBox
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'start'}
      sx={{width: "820px", ...debugBorders}}
    >
      {/*<MDTypography variant={"h2"} component={"h2"}>Categories and products</MDTypography>*/}
      <Accordion style={{width: "100%", marginBottom: '20px', border: 0}} expanded={expanded} onChange={() => handleChange('accordion')}>
        <AccordionSummary
          expandIcon={<Icon>expand_more</Icon>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography sx={SectionTitleText}>Categories and products</MDTypography>
          <Icon sx={AddButton} fontSize='medium' onClick={(event) => {
            categoriesProps.push({...InitialBrandCategory})
            handleChange('icon')
            event.stopPropagation();
          }}>
            add_circle
          </Icon>
        </AccordionSummary>
        <AccordionDetails>
          {valuesArray.length === 0 &&
            <MDBox sx={{display: 'flex', flexDirection: "column", width: "800px", px: 3, alignItems: 'center'}}>
              <MDTypography sx={SectionTitleText}>No categories in the brand</MDTypography>
            </MDBox>
          }

          {valuesArray.length > 0 && <MDBox sx={{display: 'flex', flexDirection: "column", width: "800px", px: 3}}>
            {valuesArray.map((category, index) => <BrandCategoryForm
              key={index}
              parentPath={parentPath}
              categoriesProps={categoriesProps}
              values={values}
              setFieldValue={setFieldValue}
              index={index}
              category={category}
              categories={categories}
              />
            )}
          </MDBox>}

        </AccordionDetails>
      </Accordion>

    </MDBox>
  )
}
