import MDBox from "../../../components/MDBox";
import {Box} from "@mui/material";
import imageUpload from "../../../assets/icons/ImageSquare.svg";
import fileSvg from "../../../assets/icons/file-attached.svg";

export const UploadImage = ({ setFile }) => {
  const handleDocumentUpload = (event) => {
    if (event.target.files && event.target.files.length) {
      setFile(event.target.files[0])
    } else {
      setFile('')
    }
  }
  return (
    <MDBox>
      <input
        type="file"
        id={'test'}
        name={'test'}
        onChange={(e) => handleDocumentUpload(e)}
        style={{width: 0}}
      />
      <label htmlFor={'test'}>
        <Box
          component={'img'}
          src={fileSvg}
          alt={''}
          ml={1}
          pt={1}
        />
      </label>
    </MDBox>
  )
}
