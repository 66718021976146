import * as Yup from "yup";

export const PRODUCT_PRICING = {
    part_price: 0,
    shipping_price: 0,
    includes_shipping: false,
    installation_price: 0,
    includes_installation: false,
    installation_is_optional: false,
}

export const CUSTOM_PRICING = {
    company: null,
    region: null,
    brand: null,
    ...PRODUCT_PRICING,
}


export const NEW_FILTER = {
    filter: '',
}

export const NEW_PRODUCT = {
    category: null,
    manufacturer: null,
    name: '',
    model: '',
    summary: '',
    details: '',
    features: '',
    specs: '',
    avatax_tax_code: '',
    cut_sheets: [],
    images: [],
    ada_compliant: false,
    filters: [],
    tags: [],
    company: null,
    default_pricing: {...PRODUCT_PRICING},
    third_party_default_pricing: {...PRODUCT_PRICING},
    custom_pricings: [],
    shipping_tax_code: '',
    installation_tax_code: '',
}


const REQUIRED_FIELD_MSG = "This field is required"

Yup.addMethod(Yup.mixed, 'foreignKey', function(args) {
    return this.test('test-object-id', args.message,(value) => value && value.id );
});

const productPricingValidationSchema = { // notice the no "Yup.object", see object usage bellow
    part_price: Yup.number(0).required(REQUIRED_FIELD_MSG),
    shipping_price:  Yup.number(0).required(REQUIRED_FIELD_MSG),
    installation_price: Yup.number(0).required(REQUIRED_FIELD_MSG),
    includes_installation: Yup.boolean(false).required(REQUIRED_FIELD_MSG),
    installation_is_optional: Yup.boolean(false).required(REQUIRED_FIELD_MSG),
}

export const productValidationSchema = Yup.object({
    category: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
    manufacturer: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
    name: Yup.string('').required(REQUIRED_FIELD_MSG),
    model: Yup.string('').required(REQUIRED_FIELD_MSG),
    summary: Yup.string('').required(REQUIRED_FIELD_MSG),
    details: Yup.string('').required(REQUIRED_FIELD_MSG),
    features: Yup.string('').required(REQUIRED_FIELD_MSG),
    specs: Yup.string('').required(REQUIRED_FIELD_MSG),
    avatax_tax_code: Yup.string('').max(24).required(REQUIRED_FIELD_MSG),
    cut_sheets: Yup.array().of( Yup.object({
        cut_sheet_file: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable()
    })),
    images: Yup.array().of( Yup.object({
        image: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable()
    })).min(1),
    ada_compliant: Yup.boolean(false).required(REQUIRED_FIELD_MSG),
    filters: Yup.array().of( Yup.object({
        filter: Yup.string('').required(REQUIRED_FIELD_MSG),
    })),
    tags: Yup.array().of( Yup.object({
        name: Yup.string('').required(REQUIRED_FIELD_MSG),
    })).nullable(),
    company: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
    default_pricing: Yup.object(productPricingValidationSchema),
    third_party_default_pricing: Yup.object(productPricingValidationSchema),
    custom_pricings: Yup.array().of( Yup.object({
        company: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
        region: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
        // brand: Yup.mixed().foreignKey(REQUIRED_FIELD_MSG),
        ...productPricingValidationSchema
    })),
});
