import AddBaseModal from "components/add-base-modal/AddBaseModal";
import MDBox from "components/MDBox";
import { FormikProvider, useFormik } from "formik";
import FormikInput from "components/FormikInput";
import pxToRem from "assets/theme/functions/pxToRem";
import * as Yup from "yup";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "services/helpers";

const ShipmentForm = ({ trackingId, initialValues, openModal, handleClose, handleChangedData }) => {
  const params = useParams();
  const { id: projectId, ido: orderId } = params;
  const shipmentId = initialValues?.id;

  const api = useApi();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    carrier: Yup.string(),
    bill_of_lading: Yup.string(),
    pro: Yup.string(),
    trailer: Yup.string(),
    scheduled_ship_date: Yup.date().required("Required"),
    scheduled_arrival_date: Yup.date().required("Required")
      .when(
        "scheduled_ship_date",
        (shipDate, schema) =>
          shipDate && schema.min(shipDate, "Arrival date cannot be before Ship date")
      ),
    revised_note: Yup.string().when(
        ["scheduled_ship_date", "scheduled_arrival_date"],
        {
          is: () => initialValues && (formik.values.scheduled_ship_date !== initialValues?.scheduled_ship_date ||
            formik.values.scheduled_arrival_date !== initialValues?.scheduled_arrival_date),
          then: Yup.string().required("Required"),
          otherwise: Yup.string().notRequired(),
        }
      ),
  });


  // revised_note: Yup.string().when(
  //     ["scheduled_ship_date", "scheduled_arrival_date"], // Escuchando cambios en ambos campos
  //     {
  //       is: (shipDate, arrivalDate, context) =>
  //         shipDate !== context.options.context.initialValues.scheduled_ship_date ||
  //         arrivalDate !== context.options.context.initialValues.scheduled_arrival_date,
  //       then: Yup.string().required("Required"),
  //       otherwise: Yup.string().notRequired(),
  //     }
  //   ),

  const onSubmit = (values) => {
    const newValues = { ...values };

    if (!initialValues) {
      delete newValues.revised_note;
    }

    setLoading(true);
    initialValues
      ? api.updateShipment(projectId, orderId, trackingId, shipmentId, newValues).handle({
          onSuccess: (result) => {
            handleChangedData();
            handleClose();
          },
          errorMessage: "Error updating shipment",
          onFinally: () => setLoading(false),
        })
      : api.createShipment(projectId, orderId, trackingId, newValues).handle({
          onSuccess: (result) => {
            handleChangedData();
            handleClose();
          },
          errorMessage: "Error saving shipment",
          onFinally: () => setLoading(false),
        });
  };

  const formik = useFormik({
    initialValues: initialValues
      ? { ...initialValues, revised_note: "" }
      : {
          carrier: "",
          bill_of_lading: "",
          pro: "",
          trailer: "",
          scheduled_ship_date: "",
          scheduled_arrival_date: "",
          revised_note: "",
        },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <AddBaseModal
      title={initialValues ? `Load ${initialValues.index} Details` : "Add Shipment"}
      buttonText="Save"
      open={openModal}
      loading={loading}
      handleClose={handleClose}
      onSubmit={formik.handleSubmit}
      disabled={!formik.isValid || !formik.dirty}
    >
      <FormikProvider value={formik}>
        <MDBox
          display="flex"
          flexDirection="column"
          gap={pxToRem(22)}
          pt={pxToRem(32)}
          px={pxToRem(45)}
        >
          <FormikInput name="carrier" label="Carrier" />
          <FormikInput name="bill_of_lading" label="Bill of Lading Shipment Number" />
          <FormikInput name="pro" label="Pro Number" />
          <FormikInput name="trailer" label="Trailer Number" />
          <FormikInput
            name="scheduled_ship_date"
            label="Scheduled Ship Date"
            type="date"
            setFieldValue={formik.setFieldValue}
            setTouched={formik.setTouched}
            extraParams={{
              minDate: moment(new Date().getDate(), "DD/MM/YYYY"),
              clearable: true,
              setErrors: formik.setErrors,
            }}
          />
          <FormikInput
            name="scheduled_arrival_date"
            label="Scheduled Arrival Date"
            type="date"
            setFieldValue={formik.setFieldValue}
            setTouched={formik.setTouched}
            extraParams={{
              minDate: moment(new Date().getDate(), "DD/MM/YYYY"),
              clearable: true,
              setErrors: formik.setErrors,
            }}
          />
          {initialValues && (
            <FormikInput
              type="textarea"
              rows={4}
              name="revised_note"
              label="Note for Revision"
              disabled={
              formik.values.scheduled_ship_date === initialValues.scheduled_ship_date &&
                formik.values.scheduled_arrival_date === initialValues.scheduled_arrival_date
            } />
          )}
        </MDBox>
      </FormikProvider>
    </AddBaseModal>
  );
};

export default ShipmentForm;
