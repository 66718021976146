import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import moment from "moment";
import { useStores } from "models";

const DeliveryDetails = ({ values, loading, setShowForm, readOnly = false}) => {
  const rootStore = useStores();
  const { loginStore } = rootStore;

  const showButton =
    loginStore?.id === values?.buyer?.id ||
    loginStore.isSupplyManager ||
    (values?.buyer?.is_projectmanager && loginStore.isProjectManager);

  const {
    po_code,
    from_address,
    to_address,
    supplier_name,
    supplier_phone,
    supplier_email,
    receiver_name,
    receiver_phone,
    receiver_email,
    delivery_instructions,
    modified,
    manufacturer_name,
    updated_by,
  } = values;

  return (
    <>
      <Grid item xs={2} lg={2}>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alingItems: "center",
            gap: 4,
          }}
        >
          <MDBox
            sx={{
              alignSelf: "center",
              borderRadius: pxToRem(6),
              marginBottom: pxToRem(18),
              paddingY: 1.5,
              flex: 1,
              display: "flex",
              gap: 2,
              justifyContent: "space-around",
              backgroundColor: colors.lightGrey,
            }}
          >
            <MDTypography sx={{ fontSize: pxToRem(12) }}>
              <strong>Last Update - Date:</strong>
              {"  "} {moment(modified).format("MM/DD/YYYY")}
            </MDTypography>

            <MDTypography sx={{ fontSize: pxToRem(12), textTransform: "capitalize" }}>
              <strong>By:</strong>
              {"  "} {updated_by?.name}
            </MDTypography>

            <MDTypography sx={{ fontSize: pxToRem(12) }}>
              <strong>Supplier:</strong>
              {"  "} {manufacturer_name}
            </MDTypography>
          </MDBox>

          <MDTypography sx={{ fontSize: pxToRem(12) }}>
            <strong>PO#</strong>
            <br /> {po_code || "-"}
          </MDTypography>
        </MDBox>
      </Grid>

      <Grid item xs={2} lg={1}>
        <MDTypography sx={{ fontSize: pxToRem(12),  wordBreak: "break-word" }}>
          <strong>Ship From Address</strong>
          <br /> {from_address || "-"}
        </MDTypography>
      </Grid>

      <Grid item xs={2} lg={1}>
        <MDTypography sx={{ fontSize: pxToRem(12),  wordBreak: "break-word" }}>
          <strong>Ship To Address</strong>
          <br /> {to_address || "-"}
        </MDTypography>
      </Grid>

      <Grid item xs={2} lg={1}>
        <MDTypography sx={{ fontSize: pxToRem(14), fontWeight: "bold", marginTop: 2 }}>
          Supplier's Logistics Contact Information
        </MDTypography>
        <MDTypography sx={{ fontSize: pxToRem(12), textTransform: "capitalize",  wordBreak: "break-word"}}>
          Name
          <br />
          <strong>{supplier_name || "-"}</strong>
        </MDTypography>

        <MDTypography sx={{ fontSize: pxToRem(12) }}>
          Phone
          <br />
          <strong>{supplier_phone || "-"}</strong>
        </MDTypography>

        <MDTypography sx={{ fontSize: pxToRem(12) }}>
          Email
          <br />
          <strong>{supplier_email || "-"}</strong>
        </MDTypography>
      </Grid>

      <Grid item xs={2} lg={1}>
        <MDTypography sx={{ fontSize: pxToRem(14), fontWeight: "bold", marginTop: 2 }}>
          Receiver's Contact Info (site contact)
        </MDTypography>
        <MDTypography sx={{ fontSize: pxToRem(12), textTransform: "capitalize",  wordBreak: "break-word"}}>
          Name
          <br />
          <strong>{receiver_name || "-"}</strong>
        </MDTypography>
        <MDTypography sx={{ fontSize: pxToRem(12) }}>
          Phone
          <br />
          <strong>{receiver_phone || "-"}</strong>
        </MDTypography>

        <MDTypography sx={{ fontSize: pxToRem(12) }}>
          Email
          <br />
          <strong>{receiver_email || "-"}</strong>
        </MDTypography>

        <MDTypography sx={{ fontSize: pxToRem(12) }}>
          Delivery Instructions
          <br />
          <strong>{delivery_instructions || "-"}</strong>
        </MDTypography>
      </Grid>

      <Grid
        item
        xs={2}
        lg={2}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingBottom: 2,
          borderBottom: "1px solid #DBDBDB",
          marginLeft: "32px",
        }}
      >
        {showButton && (
          <MDButton
            variant={"outlined"}
            color="primary"
            loading={loading}
            disabled={loading || readOnly}
            type="submit"
            onClick={setShowForm}
          >
            Edit
          </MDButton>
        )}
      </Grid>
    </>
  );
};

export default DeliveryDetails;
