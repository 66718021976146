import {observer} from "mobx-react";
import MDBox from "../../../../components/MDBox";
import {Card, Grid, useMediaQuery} from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../services/constants";
import {useParams} from "react-router-dom";
import MDButton from "../../../../components/MDButton";
import {money_fmt} from "../../../../services/helpers";

export const OrderSummaryCard = observer((props) => {
  const {
    id, order,
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const type = order?.is_order ? 'Order': 'Cart'
  const handleViewOrder = (id) => {
    navigate(ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(params.id, id))
  }

  return (
    <Card sx={{p: 2}}>
      <MDBox display={'flex'} justifyContent={'space-between'} pr={1}>
        <MDTypography variant={'h6'} color={'primary'} fontSize={'14px'}>{type} {`#${order.id}`}</MDTypography>
      </MDBox>
      <Grid container>
        <Grid container item xs={6} md={12} lg={12} borderBottom={{xs: '', lg: '1px solid #DBDBDB'}}>
          <Grid item xs={12} md={3} lg={2}>
            <MDTypography variant={'h5'} fontSize={{xs: '14px', lg:'20px'}}>
              {order?.manufacturer?.category_name}
              {/*{`${type} #${order.id}`}*/}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <MDTypography variant={'subtitle2'} fontSize={'14px'}>Manufacturer</MDTypography>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <MDTypography variant={'subtitle2'} fontSize={'14px'}>Buyer</MDTypography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <MDTypography variant={'subtitle2'} fontSize={'14px'}>Status</MDTypography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <MDTypography variant={'subtitle2'} fontSize={'14px'}>Total Price</MDTypography>
          </Grid>
        </Grid>
        <Grid container item xs={6} md={12} lg={12} pt={2}>
          <Grid item xs={12} md={3} lg={2}>
            <MDTypography variant={'h3'} color={'secondary'}>{order?.friendly_status}</MDTypography>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <MDTypography variant={'subtitle2'} fontWeight={'medium'}>{order.manufacturer?.name}</MDTypography>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <MDTypography variant={'subtitle2'} fontWeight={'medium'}>{order?.buyer_name}</MDTypography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <MDTypography variant={'subtitle2'} fontWeight={'medium'}>{order.status}</MDTypography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <MDTypography variant={'subtitle2'} fontWeight={'medium'}>{money_fmt(order.total)}</MDTypography>
          </Grid>
          <Grid display={{xs: 'none', lg: 'flex'}} item xs={12} md={2} lg={2}>
            <MDButton variant={'contained'} color={'secondary'} onClick={() => handleViewOrder(id)}>
              {`View ${type}`}
            </MDButton>
          </Grid>
        </Grid>

        <Grid display={{xs: 'flex', lg: 'none'}} item xs={12} justifyContent={{xs: 'center', md: 'flex-end'}}>
          <MDButton variant={'contained'} color={'secondary'} onClick={() => handleViewOrder(id)}>
            {`View ${type}`}
          </MDButton>
        </Grid>

      </Grid>
    </Card>
  )
})
