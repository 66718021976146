/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import {setupRootStore} from "./models";
import icon from './assets/icons/favicon.png';
import {ROUTES} from "./services/constants";

// Pages
import SignIn from "./pages/base/sign-in";
import NotFound from "./pages/base/not-found";
import ForgotPassword from "./pages/base/forgot-password";
import SetNewPassword from "./pages/base/set-new-password";

import ProjectsPage from "./pages/project-manager/projects";
import AccountSettingsPage from "./pages/project-manager/account-settings";
import OrderManagementDetail from "./pages/admin/order-management/orderMainPage";
import ProductDetailPage from "./pages/admin/manage-products/product-detail";
import ProjectManagerAccount from "pages/project-manager/account";
import TermsConditions from "pages/project-manager/terms&condition";
import ChangePassword from "./pages/project-manager/change-password";
import Messages from "./pages/project-manager/messages";
import ProjectManagerDashboardHomeScreen from "./pages/project-manager/home";
import ProjectSelected from "./pages/project-manager/projectSelected";
import ContactList from "./pages/project-manager/contact-list";
import ManageBrandPage from "./pages/admin/manage-brands/manage-brand/brand-form";
import ManageProductPage from "./pages/admin/manage-products/manage_product";
import ProductsPage from "./pages/project-manager/products";
import PMProductDetailPage from "./pages/project-manager/products/product-detail";
import {OrdersAndQuotes} from "./pages/project-manager/orders-and-quotes";
import {
  OrderManagementPage
} from "./pages/project-manager/orders-and-quotes/products-card-and-details/OrderManagementPage";
import Notifications from "./pages/project-manager/notifications";
import {PMPackageDetailPage} from "./pages/project-manager/packages/package-detail";
import {PackagesPage} from "./pages/project-manager/packages";
import ProductDetailOrderPage from "./pages/admin/manage-products/product-order-detail";
import ProductOrderListPage from "./pages/admin/manage-products/product-order-list";
import {ProgramDocumentList} from "./pages/project-manager/program-documents";
import ProductsSelected from "./pages/project-manager/products-supply-manager";
import SupplyManagerProductsPage from "./pages/project-manager/products-supply-manager/products";
import SMProductDetailPage from "./pages/project-manager/products-supply-manager/products/product-detail";
import {SMPackageDetailPage} from "./pages/project-manager/products-supply-manager/packages/package-detail";
import {PrivacyPolicy} from "./pages/project-manager/privacy-policy";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const { pathname } = useLocation();


  const [rootStore, setRootStore] = useState(undefined)



  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', icon);
  }, []);

  useEffect(() => {
    (async () => {
      setupRootStore().then((rootStore)=>{
        setRootStore(rootStore)
      })
    })()
  }, [])


  useEffect(() => {
    if(rootStore){
      rootStore.loginStore.setUp()
    }
  },[rootStore])


  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName=""
            routes={routes}
          />
      )}
      <Routes>
        {getRoutes(routes)}
        {/*<Route path={ROUTES.ROOT} element={<SignIn />} />*/}
        <Route path={ROUTES.LOGIN} element={<SignIn />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword />} />
        {/*Project Manager User Type*/}
        <Route path={ROUTES.USER_PROJECT_SELECTOR} element={<ProjectsPage />} />
        <Route path={ROUTES.USER_MY_ACCOUNT} element={<ProjectManagerAccount/>} />
        <Route path={ROUTES.USER_ACCOUNT_SETTINGS} element={<AccountSettingsPage />} />
        <Route path={ROUTES.USER_CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={ROUTES.USER_TERMS_AND_CONDITIONS} element={<TermsConditions />} />
        <Route path={ROUTES.USER_PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.DIRECT_MESSAGES(':id')} element={<Messages />} />
        <Route path={ROUTES.USER_PROJECT_SELECTED(':id')} element={<ProjectSelected />} />
        <Route path={ROUTES.CONTACT_LIST_PROJECT_MANAGER(':id', ':categoryId')} element={<ContactList />} />
        <Route path={ROUTES.PROGRAM_DOCUMENTS(':manufacturerId')} element={<ProgramDocumentList/>} />
        <Route path={ROUTES.PM_PROGRAM_DOCUMENTS(':id', ':categoryId')} element={<ProgramDocumentList/>} />
        <Route path={ROUTES.USER_SUPPLY_MANAGER_PRODUCTS_SELECTED(':manufacturerId')} element={<ProductsSelected/>} />
        {/*<Route path={ROUTES.CATEGORY_SCREEN_PROJECT_MANAGER} element={<CategoryScreen />} />*/}
        <Route path={ROUTES.USER_ORDERS_AND_QUOTES(':id')} element={<OrdersAndQuotes />} />
        <Route path={ROUTES.USER_PRODUCTS(':id')} element={<ProductsPage />} />
        <Route path={ROUTES.PROJECT_PRODUCTS_BY_CATEGORY(':id', ':categoryId')} element={<ProductsPage />} />
        <Route path={ROUTES.SUPPLY_MANAGER_PRODUCTS_BY_CATEGORY(':manufacturerId', ':categoryId')} element={<SupplyManagerProductsPage />} />
        <Route path={ROUTES.PACKAGE_DETAIL(':id', ':idp')} element={<PMPackageDetailPage />} />
        <Route path={ROUTES.SUPPLY_MANAGER_PACKAGE_DETAIL(':manufacturerId', ':idp')} element={<SMPackageDetailPage />} />
        <Route path={ROUTES.USER_PRODUCT_DETAIL(':id',':idp')} element={<PMProductDetailPage />} />
        <Route path={ROUTES.SUPPLY_MANAGER_PRODUCT_DETAIL(':manufacturerId',':idp')} element={<SMProductDetailPage />} />
        <Route path={ROUTES.USER_PROJECT_PACKAGES(':id')} element={<PackagesPage />} />
        <Route path={ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(':id',':ido')} element={<OrderManagementPage />} />
        <Route path={ROUTES.USER_HOME} element={<ProjectManagerDashboardHomeScreen />} />
        <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />

        {/*Admin*/}
        <Route path={ROUTES.ADMIN_ORDER_MANAGEMENT_DETAIL(':id')} element={<OrderManagementDetail />} />
        <Route path={ROUTES.ADMIN_PRODUCT_DETAIL(':id')} element={<ProductDetailPage />} />
        <Route path={ROUTES.ADMIN_PRODUCT_ORDER_LIST(':idp', ':ido')} element={<ProductOrderListPage />} />
        <Route path={ROUTES.ADMIN_PRODUCT_ORDER_DETAIL(':id', ':idp', ':ido')} element={<ProductDetailOrderPage />} />
        <Route path={ROUTES.ADMIN_CREATE_NEW_PRODUCT} element={<ManageProductPage />} />
        <Route path={ROUTES.ADMIN_UPDATE_PRODUCT(':id')} element={<ManageProductPage />} />

        <Route path={ROUTES.ADMIN_CREATE_NEW_BRAND} element={<ManageBrandPage />} />
        <Route path={ROUTES.ADMIN_UPDATE_BRAND(':id')} element={<ManageBrandPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  )
}
