import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";
import {withRootStore} from "../extensions/with-root-store";

export const ProjectModel = types
    .model("Project")
    .props({
      id: types.number,
      name: types.string,
      address: types.string,
      //project_managers: types.optional(types.array(types.number), []),
      project_managers_names: types.optional(types.string, '-'),
      location: types.optional(types.string, ''),
    })

type ProjectType = Instance<typeof ProjectModel>
/**
 * Model description here for TypeScript hints.
 */
export const ProjectStoreModel = types
  .model("ProjectStore")
  .extend(withRootStore)
  .extend(withEnvironment)
  .props({
    // id: types.maybeNull(types.number),
    // name: types.maybeNull(types.string),
    cached_projects: types.map(ProjectModel),
  })
  .actions(self => ({
    // setData(project: any) {
    //   // self.id = project.id
    //   // self.name = project.name
    // },
    reset() {
      self.cached_projects.clear()
      // self.id = null
      // self.name = null
    },
    setProject(project: ProjectType){
        self.cached_projects.set(`${project.id}`,project)
    },
    setProjects(projects: ProjectType[]){
      projects.forEach(p => self.cached_projects.set(`${p.id}`, p))
    },
    projectName(id: string | number){
      return self.cached_projects.get(`${id}`)?.name || '-'
    },
    project(id: string | number){
        return self.cached_projects.get(`${id}`) || null
    },
    hasProject(id: string | number){
      return self.cached_projects.has(`${id}`)
    }
  })).views(self => ({

    }))

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type ProjectStoreType = Instance<typeof ProjectStoreModel>

export interface ProjectStore extends ProjectStoreType {
}

type ProjectStoreSnapshotType = SnapshotOut<typeof ProjectStoreModel>

export interface ProjectStoreSnapshot extends ProjectStoreSnapshotType {
}
