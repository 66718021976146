import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import FormikInput from "../../../../../components/FormikInput";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import { GeneralModal } from "../../../../../components/general-modal";
import * as React from "react";
import { observer } from "mobx-react";
import MDTypography from "../../../../../components/MDTypography";

const ApproveOrderModal = ({ handleClose, open, loading, handleApproveOrder }) => {
  return (
    <GeneralModal title={"Approve Order"} open={open} handleClose={handleClose}>
      <Grid>
        <Formik
          initialValues={{
            message: "",
          }}
          onSubmit={(values) => console.log("values: ", values)}
        >
          {(formik) => (
            <Form>
              <Grid container pl={2} pr={2}>
                <Grid item xs={12} lg={12} pt={2}>
                  <MDTypography variant={"h4"}>
                    Everything look good? You can approve the order, and then move to the next stage
                    of contracts.
                  </MDTypography>
                  <FormikInput
                    name={"message"}
                    type={"textarea"}
                    label={"Message (Optional)"}
                    rows={8}
                  />
                </Grid>
              </Grid>
              <MDBox
                borderTop={"1px solid #DBDBDB"}
                pr={2}
                pt={2}
                gap={2}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <MDButton
                  variant="outlined"
                  color="secondary"
                  disabled={loading || !formik.isValid}
                  onClick={() => handleClose(formik)}
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  loading={loading}
                  onClick={handleApproveOrder}
                >
                  Approve Order
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>
      </Grid>
    </GeneralModal>
  );
};
export default observer(ApproveOrderModal);
