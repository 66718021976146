import {observer} from "mobx-react";
import {Box, Card, CircularProgress, Grid} from "@mui/material";

// Components
import DashboardProjectManagerLayout from "components/DashboardProjectManager";
import MDTypography from "components/MDTypography";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useApi} from "../../../services/helpers";
import {ROUTES} from "../../../services/constants";
import {useStores} from "../../../models";

function ProductsSelected() {
  const api = useApi()
  const {manufacturerId} = useParams()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const navigate = useNavigate()
  const [category, setCategory] = useState(null)
  const [packages, setPackages] = useState(null)
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [loadingPackages, setLoadingPackages] = useState(true)

  const getCategoryByManufacturer = (manufacturerId) => {
    setLoadingCategories(true)
    api.getCategories( {manufacturers: manufacturerId} ).handle({
      onSuccess: (result) => {
        setCategory(result.data.results[0])
      },
      onFinally: () => setLoadingCategories(false),
      errorMessage: 'Error getting manufacturers',
    })
  }

  const getPackagesByManufacturer = (manufacturerId) => {
    setLoadingPackages(true)
    api.getManufacturerPackages( manufacturerId ).handle({
      onSuccess: (result) => {
        setPackages(result.data)
      },
      onFinally: () => setLoadingPackages(false),
      errorMessage: 'Error getting manufacturers',
    })
  }

  useEffect(() => {
    if (!loginStore.isSupplyManager) {
      navigate(ROUTES.USER_PROJECT_SELECTOR)
    }
    getCategoryByManufacturer(manufacturerId)
    getPackagesByManufacturer(manufacturerId)
  }, [manufacturerId])

  return (
    <DashboardProjectManagerLayout title={'Order By Category'}>
      <Grid container spacing={2} pt={3}>
        {loadingCategories &&
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="inherit" size={35} />
          </Grid>
        }
        {category?
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Card
              onClick={() => navigate(ROUTES.SUPPLY_MANAGER_PRODUCTS_BY_CATEGORY(manufacturerId, category.id))}
              sx={{display: 'flex', flexDirection: { xs: 'column', lg: 'row'},alignItems: 'center', gap: pxToRem(21), p: pxToRem(21), borderRadius: '8px', cursor: 'pointer'}}
            >
              <Box component={'img'} src={category.logo} alt={'image'} width={pxToRem(85)} height={pxToRem(85)} sx={{objectFit:"contain"}}/>
              <MDTypography variant={'h5'} color={'tertiary'} fontSize={{ xs: '14px', mmd: '1.4vw', xl: '14px' }} sx={{ display:'flex', flex: "1", wordBreak: "break-word", height:'97px', alignItems:'center' }}>{category.name}</MDTypography>
            </Card>
          </Grid>
        :
          !loadingCategories &&
            <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <MDTypography variant={'h5'} fontSize={'22px'}>No Category Found</MDTypography>
            </Grid>

        }
      </Grid>
      <MDTypography variant={'h2'} pt={5} pb={3}>Order By Package</MDTypography>
      <Grid container spacing={2}>
        {loadingPackages &&
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="inherit" size={35} />
          </Grid>
        }
        {packages?.length > 0 && packages.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={`key-${index}`}>
            <Card onClick={() => navigate(ROUTES.SUPPLY_MANAGER_PACKAGE_DETAIL(manufacturerId, item.id))} sx={{display: 'flex', flexDirection: { xs: 'column', lg: 'row'}, alignItems: 'center', gap: pxToRem(21), p: pxToRem(21), borderRadius: '8px', cursor: 'pointer'}}>
              <Box component={'img'} src={item.logo} alt={'image'} width={pxToRem(85)} height={pxToRem(85)} sx={{objectFit:"contain"}}/>
              <MDTypography variant={'h5'} color={'tertiary'} fontSize={{ xs: '14px', mmd: '1.4vw', xl: '14px' }} sx={{ display:'flex', flex: "1", wordBreak: "break-word", height:'97px', alignItems:'center' }}>{item.name}</MDTypography>
            </Card>
          </Grid>
        ))}
        {packages?.length === 0 && !loadingPackages && (
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <MDTypography variant={'h5'} fontSize={'22px'}>No Packages Found</MDTypography>
          </Grid>
        )}
      </Grid>
    </DashboardProjectManagerLayout>
  );
}

export default observer(ProductsSelected);
