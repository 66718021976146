import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import {Accordion, AccordionDetails, AccordionSummary, Divider, Fab, Icon} from "@mui/material";
import {InitialDrawing, InitialPackage, InitialPackageCategory, InitialPackageCategoryProduct} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import FormikInput from "../../../../../../components/FormikInput";
import {FieldArray, getIn} from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { PackageCategoriesForm } from "./PackageCategoriesForm";
import {AddButton, RemoveButton, SectionTitle, SectionTitleText} from "../styles";
import {useState} from "react";
import InputDragAndDrop from "../../../../../../components/InputDragAndDrop";
import {
  ImagecardContainer, ImagecardDeleteIcon,
  ImageCardImage,
  ImagecardWrapContainer
} from "../../../../manage-products/manage_product/styles";
import {openInNewTab} from "../../../../../../services/helpers";

const debugBorders = {} //{border:"2px solid black"}

export const PackagesForm = ({packagesProps, values, setFieldValue, categories, errors}) => {
  const [expanded, setExpanded] = useState(false);
  const parentPath = packagesProps.name
  const valuesArray = getIn(values, parentPath)
  const handleChange = (openedFrom) => {
    if (openedFrom === 'accordion'){
      setExpanded(v => !v);
    } else {
      setExpanded(true)
    }
  };
  return (
    <MDBox
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'start'}
      sx={{width:"820px", ...debugBorders}}
    >
      <Accordion style={{width: "100%", marginBottom: '20px'}} expanded={expanded} onChange={() => handleChange('accordion')}>
        <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
          <MDTypography sx={SectionTitleText}>Packages</MDTypography>
          <Icon sx={AddButton} fontSize='medium' onClick={(event)=>{
            packagesProps.push({...InitialPackage})
            handleChange('icon')
            event.stopPropagation();
          }}>
            add_circle
          </Icon>
        </AccordionSummary>
        <AccordionDetails>

          {valuesArray.length === 0 &&
              <MDBox sx={{display: 'flex', flexDirection:"column", width:"800px", px:3, alignItems:'center'}}>
                <MDTypography sx={SectionTitleText}>No packages in the brand</MDTypography>
              </MDBox>
          }

      {valuesArray.length > 0 && <MDBox sx={{display: 'flex', flexDirection:"column", width:"800px", px:3}}>
        {valuesArray.map((pkg, index)=> {
          return (
            <MDBox display={"flex"} flexDirection={"column"} sx={{px:3, ...debugBorders}}>

              <MDTypography variant={"h4"} component={"h4"}>Package #{index + 1}</MDTypography>
              <FormikInput  name={`${parentPath}.${index}.name`} label="Name" />

              <MDBox
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  setFieldValue(`${parentPath}.${index}.logo`, e.dataTransfer.files?.[0])
                }}
              >
                <input
                  type='file'
                  accept='image/*'
                  name={`${parentPath}.${index}.logo`}
                  id={`${parentPath}.${index}.logo`}
                  style={{display: 'none'}}
                  onInput={(event) => {
                    setFieldValue(`${parentPath}.${index}.logo`, event.target.files[0]);
                  }}
                  onClick={(event) => {
                    event.target.value = null
                  }}
                />
                <InputDragAndDrop htmlFor={`${parentPath}.${index}.logo`} label={'Package logo'} errors={errors} name={`${parentPath}.${index}.logo`}/>
              </MDBox>

              <MDBox sx={ImagecardWrapContainer}>
                {valuesArray.length > 0 &&  valuesArray[index].logo !== '' && (
                  <MDBox sx={ImagecardContainer}>
                    <img
                      style={ImageCardImage}
                      src={valuesArray[index].logo instanceof File ? URL.createObjectURL(valuesArray[index].logo) : valuesArray[index].logo}
                      onClick={() => {
                        openInNewTab(valuesArray[index].logo instanceof File ? URL.createObjectURL(valuesArray[index].logo) : valuesArray[index].logo)
                      }}
                    />
                    <Icon
                      sx={ImagecardDeleteIcon}
                      fontSize='medium'
                      onClick={() => {
                        setFieldValue(`${parentPath}.${index}.logo`, null)
                      }}>
                      remove_circle
                    </Icon>
                  </MDBox>
                )}
              </MDBox>


              <FieldArray name={`${parentPath}.${index}.categories`}>

                {(packageCategoryProps) => (
                  <PackageCategoriesForm packageCategoryProps={packageCategoryProps}
                                         values={values}
                                         setFieldValue={setFieldValue}
                                         categories={categories}
                                         debugBorders={debugBorders}
                  />
                )}
              </FieldArray>

              <MDBox sx={{display: 'flex', alignItems:"center", flexDirection:"row", justifyContent:"start"}}>
                <MDTypography variant={"h4"} component={"h4"}>Delete Package  #{index+ 1}</MDTypography>
                <Icon sx={RemoveButton} fontSize='medium' onClick={()=>packagesProps.remove(index)}>
                  remove_circle
                </Icon>
              </MDBox>


            </MDBox>
          )
        })}
      </MDBox>}


        </AccordionDetails>
      </Accordion>

    </MDBox>
  )
}
