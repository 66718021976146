import {Box} from "@mui/material";
import {observer} from "mobx-react";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {money_fmt} from "../../../../services/helpers";

export const CartConfirmProduct = ({ image, item, model, installation, shipping, quantity, subtotal }) => {
  return (
      <MDBox display={'flex'} justifyContent={'space-between'} pb={2.5}>
        <MDBox display={"flex"}>
          <Box component={"img"} src={image} alt={"item-to-buy"} mr={1.7} style={{maxHeight: 64, maxWidth: 64}}/>
          <MDBox pr={3} sx={{width: 300}}>
            <MDTypography variant={'h6'} fontSize={'14px'} sx={{width: '100%', wordWrap: 'break-word'}}>{item}</MDTypography>
            <MDTypography variant={'subtitle2'} fontSize={'12px'}>Model {model}</MDTypography>
            <MDTypography variant={'subtitle2'} fontSize={'12px'}>{installation ? `✓ Installation added`: ''}</MDTypography>
            <MDTypography variant={'subtitle2'} fontSize={'12px'}>{shipping ? '✓ Shipping included': ''}</MDTypography>
            <MDBox display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <MDTypography variant={'subtitle2'} fontSize={'12px'}>Quantity</MDTypography>
              <MDTypography variant={'subtitle2'} fontWeight={'regular'}>{quantity}</MDTypography>
            </MDBox>
            <MDBox display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <MDTypography variant={'subtitle2'} fontSize={'12px'}>Subtotal</MDTypography>
              <MDTypography variant={'h6'} fontSize={'14px'}>{money_fmt(subtotal)}</MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
  )
}

export const CartConfirmPackage = ({ image, item, quantity, subtotal }) => {
  return (
      <MDBox display={'flex'} justifyContent={'space-between'} pb={2.5}>
        <MDBox display={"flex"}>
          <Box component={"img"} src={image} alt={"item-to-buy"} mr={1.7} style={{maxHeight: 64, maxWidth: 64}}/>
          <MDBox pr={3} sx={{width: 300}}>
            <MDTypography variant={'h6'} fontSize={'14px'} sx={{width: '100%', wordWrap: 'break-word'}}>{item}</MDTypography>
            <MDBox display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <MDTypography variant={'subtitle2'} fontSize={'12px'}>Quantity</MDTypography>
              <MDTypography variant={'subtitle2'} fontWeight={'regular'}>{quantity}</MDTypography>
            </MDBox>
            <MDBox display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <MDTypography variant={'subtitle2'} fontSize={'12px'}>Subtotal</MDTypography>
              <MDTypography variant={'h6'} fontSize={'14px'}>{money_fmt(subtotal)}</MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
  )
}
// export default observer(CartConfirmProduct);
